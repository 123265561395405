import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    trackBodyGrid: {
        display: "flex",
        backgroundColor: "white",
        zIndex: 1,
        flexShrink: 0,
        height: "100vh",
        overflow: "auto",
    },
    placeholder: {
        color: "#aaa",
    },
    dropzone: {
        width: "37vw",
        marginTop: "10px",
        border: `2px dashed  #E3E3E3 `,
        borderRadius: "10px !important",
        padding: theme.spacing(2),
        textAlign: "center",
        cursor: "pointer",
        color: "black",
        "&:hover": {
            backgroundColor: theme.palette.grey[200],
        },
    },
    treeClass: {
        display: "flex",
        flexDirection: "column",
        alignSelf: "stretch",
        gap: "16px",
        overflowY: "auto",
        "& .MuiTreeItem-content": {
            borderBottom: "1px solid var(--color-border)",
            "& .MuiTreeItem-iconContainer": {
                marginRight: 0,
                width: 0
            },
            "& .MuiTreeItem-label": {
                paddingLeft: 0,
                // "& .MuiTypography-root": {
                //   fontWeight: `400 !important`,
                // },
            },
        },
        "& .MuiCollapse-root": {
            marginLeft: "0px",
            "& .MuiBox-root": {
                paddingLeft: "17px"
            }
        },
        "& .MuiCollapse-wrapperInner": {
            "& .MuiTreeItem-content": {
                borderBottom: "none",
                // marginBottom: "16px",
                "& .MuiTreeItem-label": {
                    "& .MuiTypography-root": {
                        fontWeight: `400 !important`,
                    },
                },
            },
        },
    },
    treeClassV2: {
        display: "flex",
        flexDirection: "column",
        alignSelf: "stretch",
        gap: "16px",
        overflowY: "auto",
        "& .MuiTreeItem-content": {
            borderBottom: "1px solid var(--color-border)",
            "& .MuiTreeItem-iconContainer": {
                marginRight: 0,
                width: 0
            },
            "& .MuiTreeItem-label": {
                paddingLeft: "28px",
                "& .MuiTypography-root": {
                  fontWeight: `400 !important`,
                },
            },
        },
        "& .MuiCollapse-root": {
            marginLeft: "0px",
            "& .MuiBox-root": {
                paddingLeft: "40px",
            }
        },
        "& .MuiCollapse-wrapperInner": {
            "& .MuiTreeItem-content": {
                borderBottom: "none",
                // marginBottom: "16px",
                "& .MuiTreeItem-label": {
                paddingLeft: "0px",

                    "& .MuiTypography-root": {
                        fontWeight: `400 !important`,
                        "& .MuiBox-root": {
                            
                        }
                    },
                },
            },
        },
    },
    countryCodeBox: {
        padding: "0px",
        borderRadius: "12px",
        border: "1px solid #EDEDED",
        backgroundColor: "#FFFFFF",
        minWidth: "100%",
        outline: "none",
        marginBottom: `0px !important`,
        "& .MuiTextField-root": {
            marginBottom: `0px !important`,
            borderRadius: "12px",
            "& .MuiOutlinedInput-root": {
                // padding: '0px'

                borderRadius: "12px !important",
            },
            "& .MuiOutlinedInput-input": {
                padding: "11px 13px !important",
                fontSize: "16px !important",
                fontWeight: `500 !important`,
                // padding: '7px 13px 0px!important'
            },
        },

        "& .MuiAutocomplete-option": {
            fontSize: "12px",
        },
    },
    tabBox: {
        padding: "4px",
        borderRadius: "20px",
        background: "#EEEEEE",
        width: "max-content",
        display: "flex",
        alignItems: "center"
    },
    tabClasses: {
        padding: "6px 12px",
        borderRadius: "20px",
        display: "flex",
        gap: "8px",
        alignItems: "center",
        cursor: "pointer"
    },
}));

export default useStyles;