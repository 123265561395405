import { IIntegrationAction } from "../../interfaces/integrations";
import {
  IUserAction,
} from "../../interfaces/outcomeModal";

export const setConnectionName = (userType: string): IIntegrationAction => ({
  type: "SET_CONNECTINO_NAME",
  payload: userType,
});

export const setIsConnecting = (isConnecting: boolean): IIntegrationAction => ({
  type: "SET_IS_CONNECTING",
  payload: isConnecting,
});

export const setShowModal = (showModal: boolean): IIntegrationAction => ({
  type: "SET_SHOW_SUCCESS_MODAL",
  payload: showModal,
});

export const setIntegrationEditMode = (editMode: boolean): IIntegrationAction => ({
  type: "SET_EDIT_MODE",
  payload: editMode,
});

