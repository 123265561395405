/*eslint @typescript-eslint/no-unused-vars: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint eqeqeq: "off"*/
import React, { useState, useRef, useContext, useEffect } from "react";
import SnackBarComp from "../../components/snackBar";
import { UserTypeContext } from "../../context/userStatus";
import { trackingService } from "../../services/tracking";
import palette from "../../theme/palette";
import FullpageLoader from "../../components/fullPageLoader";
import AddHoursModal from "./addHoursModal";
import AddTrackingEventInfluenceModal from "./addTrackingEventInfluenceModal";
import AddYesNoModal from "./addYesNoModal";
import clsx from "clsx";
import AddTimeOfDay from "./addTimeOfDayModel";
import { Tooltip, Typography } from "@mui/material";
import DoubleClickHandler from "./DoubleClickHandler";
import moment from "moment";
import { addCategoryJourneyAction, addJourneyAction, addNotesJourneyAction } from "../../store/actions/userTypeAction";
import HTMLTooltip from "../../components/HTMLTooltip";
import typography, { pxToRem } from "../../theme/typography";
import { MeasurementUnit } from "../../utils/staticDataJson";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  icon: {
    position: "relative",
    borderBottom: "1px solid #F3F3F3",
    padding: "14px 0",
    height: "65px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  iconInner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexBasis: "100%",
    height: "35px",
    maxHeight: "222px",
    "&:after": {
      content: "''",
      position: "absolute",
      display: "flex",
      left: "28px",
      border: "1px dashed #E7E7E7",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  iconInnerWithoutAfter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexBasis: "100%",
    maxHeight: "222px",
  },
  iconLast: {
    position: "relative",
    padding: "14px 0",
    height: "65px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",

  },
  iconWithoutAfter: {
    position: "relative",
    borderBottom: "0",
    padding: "14px 0",
    height: "65px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    // flexDirection: "row-reverse"
  },
  absoluteBorders: {
    height: '66px',
    display: 'flex',
    padding: '32px 0',
    position: 'absolute',
    alignItems: 'center',
    borderBottom: '1px solid #F3F3F3',
    justifyContent: 'center',
    width: '30px',
    left: '24px',
  },
  absoluteExpand: {
    width: '172px',

  },
  iconExpand: {
    height: "auto",
    "&:after": {
      width: "100%",
    },
  },
  addOutlineIcon: {
    zIndex: 1,
    "& span": {
      color: "rgb(227, 227, 227)",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "28px",
      fontFamily: typography.fontFamily,
      display: "block",
      textAlign: "center",
      width: "28.5px",
      height: "28.5px",
      border: "1px solid rgb(227, 227, 227)",
      borderRadius: "50%",
      backgroundColor: palette.common.white,
    },
  },
  addOutlineIconInput: {
    border: "1px solid rgb(227, 227, 227)",
    borderRadius: "50%",
    backgroundColor: palette.common.white,
    textAlign: "center",
    width: "28.5px",
    height: "28.5px",
    "& input": {
      width: "18px",
      height: "19px",
      fontFamily: typography.fontFamily,
    },
  },
  borderOutline: {
    width: "34px!important",
    height: "34px!important",
  },
  notesItem: {
    margin: 0,
    listStyleType: "none",
    paddingLeft: "0",
    "& li": {
      color: palette.primary.light,
      fontSize: "12px",
      lineHeight: "15px",
      "&:before": {
        content: "''",
        backgroundColor: palette.primary.main,
        width: "4px",
        height: "4px",
        display: "inline-block",
        marginRight: "5px",
        borderRadius: "50%",
      },
    },
  },
  clock: {
    position: "absolute",
    margin: "auto",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    height: "20px",
    width: "20px",
  },
  hand: {
    position: "absolute",
    width: "3px",
    margin: "auto",
    left: "0",
    bottom: "0",
    right: "0",
    backgroundColor: "#fff",
    transformOrigin: "bottom",
    borderRadius: "4px",
  },
  minute: {
    height: "11px",
    top: "-45%",
  },
  hour: {
    height: "8px",
    top: "-30%",
  },
}));

interface IconPersonalInfluenceModal {
  setInfluenceId: (influenceId: string) => void;
  influenceId: string;
  journeyData: any;
  headerDate: any;
  callservice: any;
  betweenDate: any;
  categoryId: string
  month: moment.Moment[];
}

const IconPersonalInfluence = (props: IconPersonalInfluenceModal) => {
  const [showInput, setShowInput] = useState(false);
  const [openTrackingEventModal, setOpenTrackingEventModal] = useState(false);
  const [openHoursModal, setOpenHoursModal] = useState(false);
  const [openYesNoModal, setOpenYesNoModal] = useState(false);
  const [openTimeOfDayModal, setOpenTimeOfDayModal] = useState(false);
  const [showLoaderPage, setShowLoaderPage] = useState(false);
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [item, setItem] = useState<any>();
  const [isEdit, setIsEdit] = useState(false);
  const [displayBorder, setDisplayBorder] = useState(false);
  const [optionIndex, setOptionIndex] = useState<any>("");
  const [selectedOption, setSelectedOption] = useState<any>();
  const [inputValue, setInputValue] = useState<any>();
  const [bgColor, setBgColor] = useState("");
  const [displayInput, setDisplayInput] = useState(false);
  const [influenceIndex, setInfluenceIndex] = useState(0);
  const classes = useStyles();
  const ref = useRef(null);
  const { state: outcomeState, dispatch: UserTypeDispatch } = useContext(UserTypeContext);

  const clickCondition = moment(props.betweenDate).isBetween(
    moment().subtract(8, "year").format("yyyy-MM-DD"),
    moment().add(1, "day").format("yyyy-MM-DD")
  );
  const getBackgroudColor = (number: number) => {
    setBgColor(item.options[number].colorCode);
  };

  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };
  const handleBlurInput = (type, e: any, mouseOut, categoryId, id) => {
    const renderToNextInput = () => {
      const foundCategory = outcomeState?.journeyCategoryList.find(it => it.categoryId === categoryId)
      if (foundCategory && foundCategory.alls && foundCategory.alls.length) {
        const findIndexOfEl = foundCategory.alls.findIndex(it => it._id === id);
        if (findIndexOfEl > -1) {
          // // down arrow handle
          if (e.keyCode == "40" && foundCategory.alls[findIndexOfEl + 1] && foundCategory.alls[findIndexOfEl + 1]._id) {
            const date = moment(props.headerDate, "mm-DD-yyyy").format("mm-DD-yyyy")
            const foundElement = document.getElementById(date + "_" + foundCategory.alls[findIndexOfEl + 1]._id)
            if (foundElement) {
              foundElement.click()

            }
          }
          // // up arrow key
          if (e.keyCode == "38" && foundCategory.alls[findIndexOfEl - 1] && foundCategory.alls[findIndexOfEl - 1]._id) {
            const date = moment(props.headerDate, "mm-DD-yyyy").format("mm-DD-yyyy")
            const foundElement = document.getElementById(date + "_" + foundCategory.alls[findIndexOfEl - 1]._id)
            if (foundElement) {
              foundElement.click()

            }
          }

          //right side arrow keys
          if (e.keyCode == "39") {
            const date = moment(props.headerDate, "mm-DD-yyyy").add(1, 'day').format("mm-DD-yyyy")
            const foundElement = document.getElementById(date + "_" + id)
            if (foundElement) {
              foundElement.click()
            }

          }
          //left side arrow keys
          if (e.keyCode == "37") {
            const date = moment(props.headerDate, "mm-DD-yyyy").subtract(1, 'day').format("mm-DD-yyyy")
            const foundElement = document.getElementById(date + "_" + id)
            if (foundElement) {
              foundElement.click()
            }
          }
        }
      }

    }
    if (mouseOut) {
      setShowInput(false);
    }
    if (
      e.key === "Enter" ||
      e.keyCode == "37" ||
      e.keyCode == "38" ||
      e.keyCode == "39" ||
      e.keyCode == "40" ||
      mouseOut
    ) {
      if (type === "TYPE2") {
        if (parseInt(inputValue) >= 0 && parseInt(inputValue) <= 5) {
          setShowInput(false);
          setDisplayInput(true);
          outcomeCreate();
        } else {
          setShowSnakbar(true);
          setMsgType("error");
          setSnakbarMsg("Please enter value between 1 to 5");
        }
      } else if ((type === "TYPE3") ||
        (type === "TYPE1" &&
          item?.flag === "yes")) {
        if (['y', 'n'].includes(inputValue)) {
          setShowInput(false);
          setDisplayInput(true);
          yesnocreate();
        } else {
          setShowSnakbar(true);
          setMsgType("error");
          setSnakbarMsg("Please enter value as y or n");
        }
      } else {
        setShowInput(false);
        setDisplayInput(true);
        unitCreate();
      }
      renderToNextInput()
    }
  };
  const yesnocreate = () => {
    let level = null
    switch (inputValue.toLowerCase()) {
      case 'y':
        level = "Yes"
        break;
      case 'n':
        level = "No"
        break;
      default:
        break;
    }
    const outcomeCreateData = {
      date: props.headerDate,
      influenceId: item?.influenceId,
      levelReach: level,
      note: [],
      flag: item?.flag,
      type: item?.type,
      inverse: item?.inverse,
      colorCode: (item?.type === "TYPE3" &&
        level === "No") ||
        level === "No"
        ? item?.inverse === "true"
          ? palette.primary.main
          : "#F17F79"
        : item?.inverse === "true"
          ? "#F17F79"
          : palette.primary.main
    };

    let oldData: any = {}
    if (!outcomeState?.isPersonal) {
      const foundOutcome = (outcomeState?.influenceList || []).find(it => it.influenceId === outcomeCreateData.influenceId);
      if (foundOutcome) {
        const journyList = { ...(outcomeState?.journeyList || {}) } as any
        const foundDateItemIcon = journyList.influence_data.findIndex(it => it.influenceId === outcomeCreateData.influenceId && it?.date === outcomeCreateData.date)
        if (foundDateItemIcon > -1) {
          oldData = { ...journyList.influence_data[foundDateItemIcon] }
        }
      }
    } else {
      const journeyCategoryList = [...(outcomeState?.journeyCategoryList || [])]
      const foundOutcome = (journeyCategoryList
        || []).findIndex(it => it.influenceIds && it.influenceIds.includes(outcomeCreateData.influenceId));
      if (foundOutcome > -1) {
        if (journeyCategoryList[foundOutcome].alls && journeyCategoryList[foundOutcome].alls.length) {
          const foundIndex = journeyCategoryList[foundOutcome].alls.findIndex(it => it.influenceId === outcomeCreateData.influenceId)
          if (foundIndex > -1) {
            if (journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses && journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.length) {
              const foundDateValue = journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.findIndex(it => it.date === outcomeCreateData.date)
              if (foundDateValue > -1) {
                oldData = { ...journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses[foundDateValue] }
              }
            }
          }
        }
      } else {
        const foundOther = (journeyCategoryList || []).findIndex(it => it.name === "Other")
        if (foundOther > -1) {
          const outcomeFound = journeyCategoryList[foundOther].influences.findIndex(it => it.influenceId === outcomeCreateData.influenceId);
          if (outcomeFound > -1) {
            if (journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses && journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.length) {
              const foundDateValue = journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.findIndex(it => it.date === outcomeCreateData.date)
              if (foundDateValue > -1) {
                oldData = { ...journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses[foundDateValue] }
              }
            }
          }
        }
      }
    }
    if (oldData?.note && oldData?.note.length) {
      outcomeCreateData.note = oldData?.note || []
    }
    trackingService
      .influenceCreate(outcomeState.token, outcomeCreateData)
      .then((data) => {
        if (!outcomeState?.isPersonal) {
          const foundOutcome = (outcomeState?.influenceList || []).find(it => it.influenceId === outcomeCreateData.influenceId);
          if (foundOutcome) {
            const journyList = { ...(outcomeState?.journeyList || {}) } as any
            const foundDateItemIcon = journyList.influence_data.findIndex(it => it.influenceId === outcomeCreateData.influenceId && it?.date === outcomeCreateData.date)
            if (foundDateItemIcon > -1) {
              journyList.influence_data[foundDateItemIcon] = {
                ...foundOutcome,
                ...outcomeCreateData,
              }
            } else {
              journyList.influence_data.push({
                ...foundOutcome,
                ...outcomeCreateData,
              } as any)
            }
            UserTypeDispatch(addJourneyAction(journyList as any))
          }
        } else {
          const journeyCategoryList = [...(outcomeState?.journeyCategoryList || [])]
          const foundOutcome = (journeyCategoryList
            || []).findIndex(it => it.influenceIds && it.influenceIds.includes(outcomeCreateData.influenceId));

          if (foundOutcome > -1) {
            if (journeyCategoryList[foundOutcome].alls && journeyCategoryList[foundOutcome].alls.length) {
              const foundIndex = journeyCategoryList[foundOutcome].alls.findIndex(it => it.influenceId === outcomeCreateData.influenceId)
              if (foundIndex > -1) {
                if (journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses && journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.length) {
                  const foundDateValue = journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.findIndex(it => it.date === outcomeCreateData.date)
                  if (foundDateValue > -1) {
                    journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses[foundDateValue] = {
                      ...outcomeCreateData
                    }
                  } else {
                    journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.push(outcomeCreateData)
                  }
                } else {
                  journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses = [outcomeCreateData]
                }

                UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
              }
            }
          } else {
            const foundOther = (journeyCategoryList || []).findIndex(it => it.name === "Other")
            if (foundOther > -1) {
              const outcomeFound = journeyCategoryList[foundOther].influences.findIndex(it => it.influenceId === outcomeCreateData.influenceId);
              if (outcomeFound > -1) {
                if (journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses && journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.length) {
                  const foundDateValue = journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.findIndex(it => it.date === outcomeCreateData.date)
                  if (foundDateValue > -1) {
                    journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses[foundDateValue] = {
                      ...outcomeCreateData
                    }
                  } else {
                    journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.push(outcomeCreateData)
                  }
                } else {
                  journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses = [outcomeCreateData]
                }
                UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
              }
            }
          }
        }
        setShowSnakbar(true);
        setSnakbarMsg(data?.message);
        setOpenYesNoModal(false);
        // props?.callService();
        setMsgType("success");
      })
      .catch((error) => {
        setShowSnakbar(true);
        setSnakbarMsg(
          error?.response?.data?.description
            ? error?.response?.data?.description
            : error?.message
        );
        setMsgType("error");
      });
  }
  const outcomeCreate = () => {
    setShowLoaderPage(true);
    const outcomeCreateData = {
      date: props.headerDate,
      influenceId: item?.influenceId,
      answerId: parseInt(inputValue) === 0 ? null : item?.options[parseInt(inputValue) - 1].answerId,
      score: parseInt(inputValue) === 0 ? 0 : item?.options[parseInt(inputValue) - 1].score,
      index: parseInt(inputValue),
      note: [],
      type: "TYPE2",
    };
    let oldData: any = {}
    if (!outcomeState?.isPersonal) {
      const foundOutcome = (outcomeState?.influenceList || []).find(it => it.influenceId === outcomeCreateData.influenceId);
      if (foundOutcome) {
        const journyList = { ...(outcomeState?.journeyList || {}) } as any
        const foundDateItemIcon = journyList.influence_data.findIndex(it => it.influenceId === outcomeCreateData.influenceId && it?.date === outcomeCreateData.date)
        if (foundDateItemIcon > -1) {
          oldData = { ...journyList.influence_data[foundDateItemIcon] }
        }
      }
    } else {
      const journeyCategoryList = [...(outcomeState?.journeyCategoryList || [])]
      const foundOutcome = (journeyCategoryList
        || []).findIndex(it => it.influenceIds && it.influenceIds.includes(outcomeCreateData.influenceId));
      if (foundOutcome > -1) {
        if (journeyCategoryList[foundOutcome].alls && journeyCategoryList[foundOutcome].alls.length) {
          const foundIndex = journeyCategoryList[foundOutcome].alls.findIndex(it => it.influenceId === outcomeCreateData.influenceId)
          if (foundIndex > -1) {
            if (journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses && journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.length) {
              const foundDateValue = journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.findIndex(it => it.date === outcomeCreateData.date)
              if (foundDateValue > -1) {
                oldData = { ...journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses[foundDateValue] }
              }
            }
          }
        }
      } else {
        const foundOther = (journeyCategoryList || []).findIndex(it => it.name === "Other")
        if (foundOther > -1) {
          const outcomeFound = journeyCategoryList[foundOther].influences.findIndex(it => it.influenceId === outcomeCreateData.influenceId);
          if (outcomeFound > -1) {
            if (journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses && journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.length) {
              const foundDateValue = journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.findIndex(it => it.date === outcomeCreateData.date)
              if (foundDateValue > -1) {
                oldData = { ...journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses[foundDateValue] }
              }
            }
          }
        }
      }
    }
    if (oldData?.note && oldData?.note.length) {
      outcomeCreateData.note = oldData?.note || []
    }
    trackingService
      .influenceCreate(outcomeState.token, outcomeCreateData)
      .then((data) => {
        const journeyCategoryList = [...(outcomeState?.journeyCategoryList || [])]
        const foundOutcome = (journeyCategoryList
          || []).findIndex(it => it.influenceIds.includes(outcomeCreateData.influenceId));
        if (foundOutcome > -1) {
          if (journeyCategoryList[foundOutcome].alls && journeyCategoryList[foundOutcome].alls.length) {
            const foundIndex = journeyCategoryList[foundOutcome].alls.findIndex(it => it.influenceId === outcomeCreateData.influenceId)
            if (foundIndex > -1) {
              if (journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses && journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.length) {
                const foundDateValue = journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.findIndex(it => it.date === outcomeCreateData.date)
                if (foundDateValue > -1) {
                  journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses[foundDateValue] = {
                    ...outcomeCreateData
                  }
                } else {
                  journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.push(outcomeCreateData)
                }
              } else {
                journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses = [outcomeCreateData]
              }
            }
          }
          UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
        }
        // setShowSnakbar(true);
        // setSnakbarMsg(data?.message);
        // props?.callservice();
        setShowLoaderPage(false);
        // setMsgType("success");
        setInputValue("");
      })
      .catch((error) => {
        setShowSnakbar(true);
        setSnakbarMsg(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message
        );
        setMsgType("error");
        setShowLoaderPage(false);
      });
  };

  const unitCreate = () => {
    if (inputValue !== '' && inputValue > -1) {
      // if (item?.options.filter((opt) => opt.value == inputValue).length > 0) {
      setShowLoaderPage(true);
      const outcomeCreateData = {
        influenceId: item?.influenceId,
        score: inputValue,
        note: [],
        answerId: item?.options.filter((opt) => opt.value == inputValue)[0]?.answerId,
        date: props?.headerDate,
        type: "TYPE12",
      };
      let oldData: any = {}
      if (!outcomeState?.isPersonal) {
        const foundOutcome = (outcomeState?.influenceList || []).find(it => it.influenceId === outcomeCreateData.influenceId);
        if (foundOutcome) {
          const journyList = { ...(outcomeState?.journeyList || {}) } as any
          const foundDateItemIcon = journyList.influence_data.findIndex(it => it.influenceId === outcomeCreateData.influenceId && it?.date === outcomeCreateData.date)
          if (foundDateItemIcon > -1) {
            oldData = { ...journyList.influence_data[foundDateItemIcon] }
          }
        }
      } else {
        const journeyCategoryList = [...(outcomeState?.journeyCategoryList || [])]
        const foundOutcome = (journeyCategoryList
          || []).findIndex(it => it.influenceIds && it.influenceIds.includes(outcomeCreateData.influenceId));
        if (foundOutcome > -1) {
          if (journeyCategoryList[foundOutcome].alls && journeyCategoryList[foundOutcome].alls.length) {
            const foundIndex = journeyCategoryList[foundOutcome].alls.findIndex(it => it.influenceId === outcomeCreateData.influenceId)
            if (foundIndex > -1) {
              if (journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses && journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.length) {
                const foundDateValue = journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.findIndex(it => it.date === outcomeCreateData.date)
                if (foundDateValue > -1) {
                  oldData = { ...journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses[foundDateValue] }
                }
              }
            }
          }
        } else {
          const foundOther = (journeyCategoryList || []).findIndex(it => it.name === "Other")
          if (foundOther > -1) {
            const outcomeFound = journeyCategoryList[foundOther].influences.findIndex(it => it.influenceId === outcomeCreateData.influenceId);
            if (outcomeFound > -1) {
              if (journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses && journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.length) {
                const foundDateValue = journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.findIndex(it => it.date === outcomeCreateData.date)
                if (foundDateValue > -1) {
                  oldData = { ...journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses[foundDateValue] }
                }
              }
            }
          }
        }
      }

      if (oldData?.note && oldData?.note.length) {
        outcomeCreateData.note = oldData?.note || []
      }
      trackingService
        .influenceCreate(outcomeState.token, outcomeCreateData)
        .then((data) => {
          const journeyCategoryList = [...(outcomeState?.journeyCategoryList || [])]
          const foundOutcome = (journeyCategoryList
            || []).findIndex(it => it.influenceIds && it.influenceIds.includes(outcomeCreateData.influenceId));

          if (foundOutcome > -1) {
            if (journeyCategoryList[foundOutcome].alls && journeyCategoryList[foundOutcome].alls.length) {
              const foundIndex = journeyCategoryList[foundOutcome].alls.findIndex(it => it.influenceId === outcomeCreateData.influenceId)
              if (foundIndex > -1) {
                if (journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses && journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.length) {
                  const foundDateValue = journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.findIndex(it => it.date === outcomeCreateData.date)
                  if (foundDateValue > -1) {
                    journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses[foundDateValue] = {
                      ...outcomeCreateData
                    }
                  } else {
                    journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.push(outcomeCreateData)
                  }
                } else {
                  journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses = [outcomeCreateData]
                }

                UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
              }
            }
          } else {
            const foundOther = (journeyCategoryList || []).findIndex(it => it.name === "Other")
            if (foundOther > -1) {
              const outcomeFound = journeyCategoryList[foundOther].influences.findIndex(it => it.influenceId === outcomeCreateData.influenceId);
              if (outcomeFound > -1) {
                if (journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses && journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.length) {
                  const foundDateValue = journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.findIndex(it => it.date === outcomeCreateData.date)
                  if (foundDateValue > -1) {
                    journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses[foundDateValue] = {
                      ...outcomeCreateData
                    }
                  } else {
                    journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.push(outcomeCreateData)
                  }
                } else {
                  journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses = [outcomeCreateData]
                }
                UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
              }
            }
          }
          setShowSnakbar(true);
          setSnakbarMsg(data?.message);
          // props?.callservice();
          setShowLoaderPage(false);
          setMsgType("success");
          setInputValue("");
        })
        .catch((error) => {
          setShowSnakbar(true);
          setSnakbarMsg(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.message
          );
          setMsgType("error");
          setShowLoaderPage(false);
        });
      // } else {
      //   setShowSnakbar(true);
      //   setMsgType("error");
      //   setSnakbarMsg("Please enter value available on scale");
      // }
    }
  };
  const closest = (needle, haystack = []) => {
    if (haystack && haystack.length) {
      return haystack.reduce((a, b) => {
        let aDiff = Math.abs(a - needle);
        let bDiff = Math.abs(b - needle);

        if (aDiff == bDiff) {
          return a > b ? a : b;
        } else {
          return bDiff < aDiff ? b : a;
        }
      });
    }
    return null

  }
  const nearestValue = (val, influenceItem) => {
    let arr = [];
    if (influenceItem.type === "TYPE2" && influenceItem.options.length && influenceItem.options[val - 1]) {
      return influenceItem.options[val - 1].colorCode
    }
    if (influenceItem?.type === "TYPE12") {
      const foundIndexOfValue = influenceItem?.options.findIndex(it => it.value === val)
      if (foundIndexOfValue > -1) {
        return influenceItem?.options[foundIndexOfValue]?.colorCode
      }
    }
    if (influenceItem?.options && influenceItem?.options.length) {
      arr = [...influenceItem?.options].map(it => it.value)
    }
    const returnVal = closest(val, arr)
    if (influenceItem?.options && influenceItem?.options.length && returnVal) {
      const foundIndexOfValue = influenceItem?.options.findIndex(it => it.value === returnVal)
      if (foundIndexOfValue > -1) {
        return influenceItem?.options[foundIndexOfValue]?.colorCode
      }
    }
    return false
  }

  return (
    <>
      {showLoaderPage && <FullpageLoader />}
      <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={handleCloseSnakbar}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : "success"}
      />
      <Box>
        {props.journeyData?.map((item: any, index: number) => {
          let noData = true;
          let iconObject: any = {}
          const foundMeasurement = MeasurementUnit.find(it => it.id === item.unitValue)
          return (
            <div
              className={clsx(
                /* moment(props.month[props.month.length - 1]).format("MM-DD-yyyy") === props.headerDate ? classes.iconWithoutAfter : */(outcomeState?.influenceList.length - 1) === index ? classes.iconLast : classes.icon,
                outcomeState?.isExpand ? classes.iconExpand : ""
              )}
              key={item._id}
            >
              <HTMLTooltip customIcon={
                <div className={moment(props.month[props.month.length - 1]).format("MM-DD-yyyy") === props.headerDate ? classes.iconInnerWithoutAfter : classes.iconInner} style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: outcomeState?.isExpand ? "flex-start" : "center",
                  flexBasis: "100%"
                }}>
                  {showInput &&
                    item?._id === props?.influenceId &&
                    (item.type === "TYPE2" ||
                      item.type === "TYPE1" || item.type === "TYPE3" ||
                      item.type === "TYPE12") ? (
                    <span
                      className={classes.addOutlineIconInput}
                      onClick={() => {
                        // setTimeout(() => {
                        if (item?.type === "TYPE2") {
                          setShowInput(false);
                          setOpenTrackingEventModal(true);
                          setDisplayBorder(true);
                          props.setInfluenceId(item._id);
                          setItem(item);
                          setSelectedOption(parseInt(optionIndex + 1));
                        } else if (
                          item?.type === "TYPE12" &&
                          item?.flag === "no"
                        ) {
                          setShowInput(false);
                          setOpenHoursModal(true);
                          props.setInfluenceId(item._id);
                          setItem(item);
                        }
                        let i = outcomeState.influenceList.findIndex(
                          (influence) => influence._id === props?.influenceId
                        );
                        setInfluenceIndex(i);
                        // }, 200);
                      }}
                    >
                      <input
                        type="text"
                        autoFocus
                        onBlur={() => {
                          handleBlurInput(
                            item?.type,
                            { key: "kdksjdks", keyCode: "hdj" },
                            true,
                            props.categoryId,
                            item._id
                          );
                        }}
                        onChange={(e) => {
                          setInputValue(e.target.value);
                        }}
                        onKeyDown={(e) => handleBlurInput(item?.type, e, false,
                          props.categoryId,
                          item._id)}
                      />
                    </span>
                  ) : (
                    <span
                      className={classes.addOutlineIcon}
                      onDoubleClick={() => {
                        if (item?.type === "TYPE2") {
                          let i = outcomeState.influenceList.findIndex(
                            (influence) => influence._id === props?.influenceId
                          );
                          setInfluenceIndex(i);
                          setShowInput(false);
                          setOpenTrackingEventModal(true);
                          setDisplayBorder(true);
                          props.setInfluenceId(item._id);
                          setItem(item);
                          setSelectedOption(parseInt(optionIndex + 1));
                        } else if (
                          (item?.type === "TYPE3") ||
                          (item?.type === "TYPE1" &&
                            item?.flag === "yes")
                        ) {
                          setShowInput(false);
                          setOpenYesNoModal(true);
                          props.setInfluenceId(item._id);
                          setItem(item);
                        } else if (
                          item?.type === "TYPE12" &&
                          item?.flag === "no"
                        ) {
                          setShowInput(false);
                          setOpenHoursModal(true);
                          props.setInfluenceId(item._id);
                          setItem(item);
                        }
                      }}
                    >
                      {item.influenceResponses?.map(
                        (journey: any, index: number) => {
                          if (
                            journey?.influenceId === item?.influenceId &&
                            journey?.date === props.headerDate
                          ) {
                            iconObject = journey
                            let nearValue = nearestValue(
                              journey?.type === "TYPE1" ||
                                journey?.type === "TYPE12"
                                ? journey?.score
                                : journey?.index, item)
                            if (journey?.type === "TYPE2") {
                              if (item.type === "TYPE2" && item.options.length && item.options[journey?.index - 1]) {
                                nearValue = item.options[journey?.index - 1].colorCode
                              }
                            }
                            if (journey?.type === "TYPE3") {
                              nearValue = null
                            }
                            if(Number(journey?.score) === 0 && journey?.type === "TYPE2") {
                              nearValue = 'grey'
                            }
                            noData = false;
                            return (
                              <DoubleClickHandler
                                key={index}
                                id={props.headerDate + "_" + item._id}
                                onDoubleClick={() => {
                                  setDisplayBorder(true);
                                  props.setInfluenceId(item._id);
                                  setItem(item);
                                  if (
                                    (item?.type === "TYPE12") &&
                                    (item?.unitValue === "timeofday" && item?.metric_type === "NEUTRAL")
                                  ) {
                                    setItem(item);
                                    setOpenTimeOfDayModal(true);
                                  } else if (
                                    item?.type === "TYPE12" &&
                                    item?.flag === "no"
                                  ) {
                                    setShowInput(true);
                                    props.setInfluenceId(item._id);
                                    setItem(item);
                                  } else if (
                                    (item?.type === "TYPE3") ||
                                    (item?.type === "TYPE1" &&
                                      item?.flag === "yes")
                                  ) {
                                    setItem(item);
                                    setOpenYesNoModal(true);
                                  }
                                }}
                                onClick={() => {
                                  setShowInput(true);
                                  setDisplayBorder(true);
                                  props.setInfluenceId(item._id);
                                  setItem(item);
                                  if (
                                    item?.type === "TYPE12" &&
                                    item?.unitValue === "timeofday" &&
                                    item?.metric_type === "NEUTRAL"
                                  ) {
                                    setOpenTimeOfDayModal(true);
                                  } else if (
                                    item?.type === "TYPE3" ||
                                    (item?.type === "TYPE1" &&
                                      item?.flag === "yes")
                                  ) {
                                    // setOpenYesNoModal(true);
                                    setShowInput(true);

                                  }
                                }}

                              >
                                <span
                                  className={
                                    journey?.note?.length > 0
                                      ? classes.borderOutline
                                      : ""
                                  }
                                  style={
                                    journey?.note?.length > 0
                                      ? {
                                        padding: "2px",
                                        border: `1px solid ${nearValue ? nearValue : journey.colorCode
                                          ? journey.colorCode
                                          : (journey?.type === "TYPE3" &&
                                            journey?.levelReach === "No") ||
                                            journey?.levelReach === "No"
                                            ? journey?.inverse === "true"
                                              ? palette.primary.main
                                              : "#F17F79"
                                            : journey?.inverse === "true"
                                              ? "#F17F79"
                                              : palette.primary.main
                                          }`,
                                        borderRadius: "50%",
                                      }
                                      : {
                                        border: `1px solid ${nearValue ? nearValue : journey.colorCode
                                          ? journey.colorCode
                                          : (journey?.type === "TYPE3" &&
                                            journey?.levelReach === "No") ||
                                            journey?.levelReach === "No"
                                            ? journey?.inverse === "true"
                                              ? palette.primary.main
                                              : "#F17F79"
                                            : journey?.inverse === "true"
                                              ? "#F17F79"
                                              : palette.primary.main
                                          }`,
                                        backgroundColor: nearValue ? nearValue : journey.colorCode
                                          ? journey.colorCode
                                          : (journey?.type === "TYPE3" &&
                                            journey?.levelReach === "No") ||
                                            journey?.levelReach === "No"
                                            ? journey?.inverse === "true"
                                              ? palette.primary.main
                                              : "#F17F79"
                                            : journey?.inverse === "true"
                                              ? "#F17F79"
                                              : palette.primary.main,

                                      }
                                  }
                                >
                                  <span
                                    style={{
                                      backgroundColor: nearValue ? nearValue : journey.colorCode
                                        ? journey.colorCode
                                        : (journey?.type === "TYPE3" &&
                                          journey?.levelReach === "No") ||
                                          journey?.levelReach === "No"
                                          ? journey?.inverse === "true"
                                            ? palette.primary.main
                                            : "#F17F79"
                                          : journey?.inverse === "true"
                                            ? "#F17F79"
                                            : palette.primary.main,
                                      borderRadius: "50%",
                                      color: palette.common.white,
                                      border: `1px solid ${nearValue ? nearValue : journey.colorCode
                                        ? journey.colorCode
                                        : (journey?.type === "TYPE3" &&
                                          journey?.levelReach === "No") ||
                                          journey?.levelReach === "No"
                                          ? journey?.inverse === "true"
                                            ? palette.primary.main
                                            : "#F17F79"
                                          : journey?.inverse === "true"
                                            ? "#F17F79"
                                            : palette.primary.main
                                        }`,
                                      position: "relative"

                                    }}
                                  >
                                    {journey?.timeOfDay ? (
                                      <Tooltip
                                        title={
                                          <Typography
                                            sx={{
                                              fontSize: "14px !important",
                                              lineHeight: "1.5",
                                            }}
                                          >
                                            {journey?.timeOfDay}
                                          </Typography>
                                        }
                                      >
                                        <div className={classes.clock}>
                                          <div
                                            className={clsx(
                                              classes.hand,
                                              classes.hour
                                            )}
                                            style={{
                                              transform: `rotate(${journey?.timeOfDay.split(":")[0] *
                                                30 +
                                                journey?.timeOfDay.split(":")[1] *
                                                (360 / 720)
                                                }deg)`,
                                            }}
                                          ></div>
                                          <div
                                            className={clsx(
                                              classes.hand,
                                              classes.minute
                                            )}
                                            style={{
                                              transform: `rotate(${journey?.timeOfDay.split(":")[1] * 6
                                                }deg)`,
                                            }}
                                          ></div>
                                        </div>
                                      </Tooltip>
                                    ) : journey?.type === "TYPE1" ||
                                      journey?.type === "TYPE12" ? (
                                      journey?.score
                                    ) : (
                                      journey?.index
                                    )}
                                  </span>
                                </span>
                              </DoubleClickHandler>
                            );
                          }
                        }
                      )}
                      {noData && (
                        <span
                          key={index}
                          id={props.headerDate + "_" + item._id}
                          onClick={() => {
                            if (clickCondition) {
                              setTimeout(() => {
                                setShowInput(true);
                                setDisplayBorder(true);
                                props.setInfluenceId(item._id);
                                setItem(item);
                                if (
                                  item?.type === "TYPE3" ||
                                  (item.type === "TYPE1" && item.flag === "yes")
                                ) {
                                  // setOpenYesNoModal(true);
                                  setShowInput(true);

                                } else if (
                                  item?.type === "TYPE12" &&
                                  item?.unitValue === "timeofday" &&
                                  item?.metric_type === "NEUTRAL"
                                ) {
                                  setOpenTimeOfDayModal(true);
                                }
                              }, 200);
                            }
                          }}
                          onDoubleClick={() => {
                            if (clickCondition) {
                              // setShowInput(true);
                              setDisplayBorder(true);
                              props.setInfluenceId(item._id);
                              setItem(item);
                              if (
                                item?.type === "TYPE3" ||
                                (item.type === "TYPE1" && item.flag === "yes")
                              ) {
                                setOpenYesNoModal(true);
                              } else if (
                                item?.type === "TYPE12" &&
                                item?.unitValue === "timeofday" &&
                                item?.metric_type === "NEUTRAL"
                              ) {
                                setOpenTimeOfDayModal(true);
                              }
                            }
                          }}
                        >
                          +
                        </span>
                      )}
                    </span>
                  )}
                </div>} title={item.type === "TYPE12" && item.unitValue !== "timeofday" ? `Enter ${foundMeasurement ? foundMeasurement.value : ""}` : null} fontProps={{
                  fontSize: `${pxToRem(12)} !important`
                }} sx={{
                  '& .MuiTooltip-tooltip': {
                    maxWidth: "300px"
                  }
                }} />

              {/* <div className={clsx(
                (((outcomeState?.reflectiveList.length - 1) === index) || moment(props.month[props.month.length - 1]).format("MM-DD-yyyy") === props.headerDate) ? null : classes.absoluteBorders,
                outcomeState?.isExpand ? classes.absoluteExpand : ""
              )}></div> */}
              {outcomeState?.isExpand ? (
                <div style={{
                  display: 'flex',
                  width: '176px',
                  height: "144px",
                  padding: '12px',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '12px',
                  alignSelf: 'stretch',
                  borderRadius: '12px',
                  border: '1px solid #F3F3F3',
                  background: '#FFF',
                  flexBasis: "100%",
                  margin: "13px 8px 0px 0",
                  overflowY: "auto",
                  wordWrap: 'break-word',
                }} onClick={() => {
                  // if(noData) {
                  //   if (clickCondition) {
                  //     setShowInput(true);
                  //     setDisplayBorder(true);
                  //     props.setOutcomeId(item._id);
                  //     setItem(item);
                  //   }
                  // } else {
                  if (clickCondition) {
                    if (item?.type === "TYPE2") {
                      setShowInput(false);
                      setOpenTrackingEventModal(true);
                      setDisplayBorder(true);
                      props.setInfluenceId(item._id);
                      setItem(item);
                      setSelectedOption(parseInt(optionIndex + 1));
                    } else if (
                      (item?.type === "TYPE3") ||
                      (item?.type === "TYPE1" &&
                        item?.flag === "yes")
                    ) {
                      setShowInput(false);
                      setOpenYesNoModal(true);
                      props.setInfluenceId(item._id);
                      setItem(item);
                    } else if (
                      item?.type === "TYPE12" &&
                      item?.flag === "no" && item?.unitValue !== "timeofday"
                    ) {
                      setShowInput(false);
                      setOpenHoursModal(true);
                      props.setInfluenceId(item._id);
                      setItem(item);
                    }
                  }
                  // }
                }}> {!noData && iconObject?.note && iconObject?.note[0] && <Typography variant="h5" sx={{ color: palette.common.black }} component="p" title={iconObject?.note}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: iconObject?.note[0].replace(/\n/g, "<br />") || ""
                    }}
                  ></span>
                </Typography>}</div>
              ) : null}

              {/* SCALE BASE MODA */}
              {openTrackingEventModal && item._id === props.influenceId && (
                <AddTrackingEventInfluenceModal
                  setOpenTrackingEventModal={setOpenTrackingEventModal}
                  index={influenceIndex}
                  setOutcomeId={props.setInfluenceId}
                  getBackgroudColor={getBackgroudColor}
                  setSelectedOption={setSelectedOption}
                  selectedOption={selectedOption}
                  setShowInput={setShowInput}
                  influenceId={props.influenceId}
                  item={item}
                  setShowSnakbar={setShowSnakbar}
                  setSnakbarMsg={setSnakbarMsg}
                  setMsgType={setMsgType}
                  setOptionIndex={setOptionIndex}
                  optionIndex={optionIndex}
                  setDisplayBorder={setDisplayBorder}
                  displayBorder={displayBorder}
                  callService={props?.callservice}
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  journeyData={props?.journeyData}
                  headerDate={props?.headerDate}
                />
              )}
              {/* UNIT LEVEL MODAL */}
              {openHoursModal &&
                item._id === props.influenceId &&
                item?.type === "TYPE12" &&
                item?.flag === "no" && (
                  <AddHoursModal
                    setOpenHoursModal={setOpenHoursModal}
                    item={item}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    journeyData={props?.journeyData}
                    headerDate={props?.headerDate}
                    setShowSnakbar={setShowSnakbar}
                    setSnakbarMsg={setSnakbarMsg}
                    setMsgType={setMsgType}
                    callService={props?.callservice}
                    setInfluenceId={props.setInfluenceId}
                  />
                )}

              {openTimeOfDayModal &&
                item._id === props.influenceId &&
                (item?.type === "TYPE12" ||
                  (item?.unitValue === "timeofday" &&
                    item?.metric_type === "NEUTRAL")) && (
                  <AddTimeOfDay
                    setOpenTimeOfDayModal={setOpenTimeOfDayModal}
                    item={item}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    journeyData={props?.journeyData}
                    headerDate={props?.headerDate}
                    setShowSnakbar={setShowSnakbar}
                    setSnakbarMsg={setSnakbarMsg}
                    setMsgType={setMsgType}
                    callService={props?.callservice}
                    setInfluenceId={props.setInfluenceId}
                  />
                )}

              {/* YES/NO MODAL */}
              {openYesNoModal &&
                item._id === props.influenceId &&
                (item?.type === "TYPE3" ||
                  (item?.type === "TYPE1" && item?.flag === "yes")) && (
                  <AddYesNoModal
                    setOpenYesNoModal={setOpenYesNoModal}
                    item={item}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    journeyData={props?.journeyData}
                    headerDate={props?.headerDate}
                    setShowSnakbar={setShowSnakbar}
                    setSnakbarMsg={setSnakbarMsg}
                    setMsgType={setMsgType}
                    callService={props?.callservice}
                    setInfluenceId={props.setInfluenceId}
                  />
                )}
            </div>
          );
        })}
      </Box>
    </>
  );
};

export default IconPersonalInfluence;
