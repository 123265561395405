import { Box, IconButton, InputAdornment, TextField, Typography } from "@mui/material"
import React, { useState } from "react"
import { pxToRem } from "../../../theme/typography"

import { ArrowUpNew } from "../../../assets/images/icon/ArrowDownGreenNew"
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Calendar from "../../../assets/images/icon/Calendar";

dayjs.extend(customParseFormat);
const Step2 = ({
    startDate, setStartDate, endDate, setEndDate
}) => {
    return <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"} padding={"0px 20px 0px 0px"} gap={"60px"} width={"670px"}>
        <Typography sx={{
            color: "var(--black-color)",
            // fontSize: `${pxToRem(10)} !important`,
            fontSize: `${pxToRem(26)} !important`,
            lineHeight: `${pxToRem(31.2)} !important`,
            // textAlign: "center !important",
            fontWeight: `700 !important`,
            "&.MuiTypography-root": {
                "@media  (max-width: 600px) and (min-width: 100px)": {
                    fontSize: `${pxToRem(26)} !important`,
                    lineHeight: `${pxToRem(31.2)} !important`
                },
            }
        }} >
            Select the date range for the metrics you want to import:
        </Typography>
        <Box display={"flex"} flexDirection={"row"} gap={"20px"} alignItems={"flex-start"} minHeight={"352px"}>
            <DatePick name={"Start Date"} selectedDate={startDate} setSelectedDate={setStartDate} />
            <DatePick name={"End Date"} selectedDate={endDate} setSelectedDate={setEndDate} />
        </Box>

    </Box>
}

export default Step2

const DatePick = ({
    name, selectedDate, setSelectedDate
}) => {
    const [popupvisible, setpopupvisible] = useState(false)
    const commonCSS = {
        "& .MuiPickersFadeTransitionGroup-root": {
            color: "#00000099",
            // fontFamily: "Poppins",
            // fontSize: `${pxToRem(10)} !important`,
            fontSize: `${pxToRem(16)} !important`,
            lineHeight: `${pxToRem(31.2)} !important`,
            // textAlign: "center !important",
            fontWeight: `400 !important`,
            "&.MuiTypography-root": {
                "@media  (max-width: 600px) and (min-width: 100px)": {
                    fontSize: `${pxToRem(16)} !important`,
                    lineHeight: `${pxToRem(31.2)} !important`
                },
            }
        },
        "& .MuiSvgIcon-root": {
            "path": {
                fill: "var(--color-new-main)"
            }
        },
        "& .MuiPickersArrowSwitcher-spacer": {
            width: "6px"
        },
        "& .MuiDayPicker-header": {
            "span": {
                color: "#00000099"
            }
        },
        "& .MuiDayPicker-monthContainer": {
            "& .MuiButtonBase-root": {
                color: "#767476DE",
                "&.Mui-selected": {
                    backgroundColor: "var(--color-new-main)",
                    color: "#124743"
                }
            }
        },
        "& .MuiPickersDay-today": {
            border: "1px solid var(--color-new-main)"
        }
    }
    return <Box display={"flex"} flexDirection={"column"} gap={"20px"} alignItems={"flex-start"} minHeight={"352px"}>
        <Typography sx={{
            color: "var(--black-color)",
            // fontSize: `${pxToRem(10)} !important`,
            fontSize: `${pxToRem(16)} !important`,
            lineHeight: `${pxToRem(24)} !important`,
            // textAlign: "center !important",
            fontWeight: `400 !important`,
            "&.MuiTypography-root": {
                "@media  (max-width: 600px) and (min-width: 100px)": {
                    fontSize: `${pxToRem(16)} !important`,
                    lineHeight: `${pxToRem(24)} !important`
                },
            }
        }} >
            {name}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs} >
            <div>
                <DatePicker
                    value={selectedDate}
                    onChange={(newValue) => {
                        console.log("Selected date:", newValue);
                        setSelectedDate(newValue);
                    }}
                    PopperProps={{
                        sx: {
                            ...commonCSS
                        },
                    }}
                    open={popupvisible}
                    onOpen={() => setpopupvisible(!popupvisible)}
                    onClose={() => setpopupvisible(!popupvisible)}

                    components={{
                        SwitchViewButton: (buttonProps) => (
                            <IconButton {...buttonProps}>
                                <ArrowUpNew /> {/* Custom icon */}
                            </IconButton>
                        ),
                    }}

                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => <TextField {...params} sx={{
                        maxWidth: '300px',

                        "&.MuiTextField-root": {
                            marginBottom: "0px !important",
                        },
                        "& .MuiOutlinedInput-root": {
                            alignItems: "center",
                            color: "#A0A0A1",
                            gap: "8px",
                            borderRadius: "12px",
                            border: "1px solid #DCE5E0",
                            background: "#F7F9FA",
                           
                            "& .MuiOutlinedInput-input": {
                                padding: "16px 12px !important",
                                color: "#A0A0A1!important",
                                '&::placeholder': {
                                    textTransform: "uppercase"
                                },
                            },
                        },
                    }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton edge="end" onClick={() => setpopupvisible(!popupvisible)}>
                                        <Calendar color="#A0A0A1" /> {/* Custom icon for the end adornment */}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />}
                />
            </div>
        </LocalizationProvider>
    </Box>
}