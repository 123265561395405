import { Box, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core";
import typography, { pxToRem } from "../../theme/typography";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


const RootStyle = styled("div")(({ theme }) => ({
  // paddingTop: "7.0vh",
  // paddingLeft: "5.3vw",
  // paddingRight: "2vw",
  // height: `calc(100% - 40px)`,
  height: `100%`,
  // overflow: 'hidden'
}));

const useStyles = makeStyles((theme) => ({
  registrationTitle: {
    maxWidth: "570px",
    color: theme.palette.common.white,
  },
  archiveTitle: {
    color: `#000 !important`,
    fontFamily: typography.fontFamily,
    fontSize: `${pxToRem(40)}`,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '120%', /* 55.2px */
  },
  mainTitle: {
    color: `#11734A !important`,
    fontFamily: typography.fontFamily,
    fontSize: `${pxToRem(26)} !important`,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '120%', /* 55.2px */

  },
  registrationSubTitle: {
    maxWidth: "505px",
  },
  frame1Box: {
    width: '80%',
    height: '100%',
    padding: '20px 0 0 40px',
    borderRadius: '50px',
    background: 'linear-gradient(307deg, #D6F5C3 20.84%, rgba(214, 245, 195, 0.40) 165.43%)',
    overflow: 'hidden'
  },
  frame2Box: {
    width: '100%',
    height: '100%',
    padding: '20px 0 0 40px',
    borderRadius: '50px',
    background: 'linear-gradient(136deg, rgba(255, 210, 206, 0.00) -129.27%, #F8B5B1 107.37%)',
    overflow: 'hidden'
  },
  carasoulClass: {
    height: '100%',
    padding: '40px 10px',
    '& .slider-wrapper axis-horizontal': {
      height: '100%',
    },
    '& .carousel-slider': {
      height: '100%',
      '& .slider-wrapper': {
        height: '100%',
        '& .slider': {
          height: '100%',

        }
      },
      '& .control-dots': {
        '& .dot': {
          height: '19px',
          width: '19px',
          background: '#D6F5C3',
          '&.selected': {
            background: '#30A35E'
          }
        }
      }
    }
  },
  rectangle: {
    width: '100%',
    height: '80%',
    transform: 'rotate(-2.436deg)',
    flexShrink: 0,
    borderRadius: '30px',
    background: 'linear-gradient(221deg, #E7FFD8 23.26%, #EBFFDE 98.73%)',
    padding: '10px',
    marginLeft: '40px'

  },
  rectangle2: {
    width: '100%',
    height: '80%',
    flexShrink: 0,
    borderRadius: '30px',
    background: '#FFEFEF',
    padding: '10px',
    marginLeft: '40px'

  }
}));

const LeftPartLogin = () => {
  const classes = useStyles();
  return (
    <div className="left-wrapper-new">
      <RootStyle>
        <Box display={"flex"} gap={'27px'} alignItems={"center"} justifyContent={"center"} width={"65%"}>
          <img src={"192.png"} alt="logo" height={50} width={50} />
          <Typography
            variant="h1"
            component="h1"
            className={classes.mainTitle}
          >
            Tenacious Tracker
          </Typography>
        </Box>
        <Caras />
        {/* <Typography
          variant="h2"
          component="h2"
          className={classes.registrationTitle}
        >
          Start your self actualisation journey with Tenacious Tracking
        </Typography>
        <Typography
          variant="h6"
          component="h6"
          className={classes.registrationSubTitle}
        >
          Set your goals and track your progress on your path to becoming your
          best self
        </Typography> */}
      </RootStyle>
    </div>
  );
};
export default LeftPartLogin;

const Caras = () => {
  const classes = useStyles();

  return <Carousel infiniteLoop={true} showThumbs={false} showArrows={false} showStatus={false} autoPlay={true} className={classes.carasoulClass}>
    <div style={{
      height: '100%'
    }}>
      {/* <Box display={"flex"} justifyContent={"center"} height={"478px"}>
        <Frame1 />

      </Box> */}
      <div style={{
        position: 'relative',
        height: '100%'
      }}>
        <div style={{
          position: "absolute",
          display: 'flex',
          justifyContent: 'center',
          alignSelf: 'stretch',
          width: '100%',
          left: 30,
          top: 0
        }}>
          <div
            style={{
              position: "absolute",
              display: 'flex',
              justifyContent: 'center',
              alignSelf: 'stretch',
              width: '100%',
              // left: 160,
              top: 28,

            }}
          >
            <Typer
              heading={'Things I want to type:'}
              dataText={["your way", "everyday"]}
            />
          </div>
        </div>


        <img src="fram1new1.svg" alt="frame1" style={{
          borderRadius: '30px',
          height: '90%'
        }} />
      </div>

      {/*public/Sign_Up_screen 09 5/10/Fram1.svg <Login1 /> */}
    </div>
    <div style={{
      height: '100%'
    }}>
      <img src="frame21.svg" alt="frame1" style={{
        borderRadius: '30px',
        height: '90%'
      }} />
      {/* <Login1 /> */}
    </div>
    <div style={{
      height: '100%'
    }}>
      <img src="frame31.svg" alt="frame1" style={{
        borderRadius: '30px',
        height: '90%'
      }} />
      {/* <Login1 /> */}
    </div>
    {/* <div style={{
      height: '100%'
    }}>
      <img src={'login2.svg'} alt="login-1" style={{
        height: '80%'
      }} />
    </div>
    <div style={{
      height: '100%'
    }}>
      <img src={'login3.svg'} alt="login-1" style={{
        height: '80%'
      }} />
    </div>
    <div style={{
      height: '100%'
    }}>
      <img src={'login4.svg'} alt="login-1" style={{
        height: '80%'
      }} />
    </div>
    <div style={{
      height: '100%'
    }}>
      <img src={'login5.svg'} alt="login-1" style={{
        height: '80%'
      }} />
    </div> */}
  </Carousel>
}


// const Frame1 = () => {
//   const classes = useStyles();
//   return <Box className={classes.frame1Box}>
//     <Box display={"flex"} gap={"10px"} alignItems={"center"}>
//       <Typography
//         variant="h1"
//         component="h1"
//         className={classes.archiveTitle}
//       >
//         Achieve goals
//       </Typography>
//       {/* <span className="type">
//         <span  >
//           <Typography
//             variant="h1"
//             component="span"
//             className={classes.archiveTitle}
//           >
//             your way
//           </Typography>
//         </span>
//         <span >
//           <Typography
//             variant="h1"
//             component="span"
//             className={classes.archiveTitle}
//           >
//             everyday
//           </Typography>
//         </span>
//       </span> */}
//       <Typer
//         heading={'Things I want to type:'}
//         dataText={["your way", "everyday"]}
//       />
//     </Box>
//     <Box className={classes.rectangle}>
//       <img src="frame1.svg" alt="frame1" style={{
//         borderRadius: '30px'
//       }} />
//     </Box>
//   </Box>
// }

class Typer extends React.Component {

  static defaultProps = {
    heading: '',
    dataText: []
  }


  state = {
    text: '',
    isDeleting: false,
    loopNum: 0,
    typingSpeed: 150
  }

  componentDidMount() {
    this.handleType();
  }

  handleType = () => {
    const { dataText } = this.props as any;
    const { isDeleting, loopNum, text, typingSpeed } = this.state;
    const i = loopNum % dataText.length;
    const fullText = dataText[i];

    this.setState({
      text: isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1),
      typingSpeed: isDeleting ? 40 : 120
    });

    if (!isDeleting && text === fullText) {

      setTimeout(() => this.setState({ isDeleting: true }), 500);

    } else if (isDeleting && text === '') {

      this.setState({
        isDeleting: false,
        loopNum: loopNum + 1
      });

    }

    setTimeout(this.handleType, typingSpeed);
  };

  render() {
    return (
      <h1 style={{
        width: "65%"
      }}>   <Box display={"flex"} gap={"10px"} alignItems={"center"}>
        <Typography
          variant="h1"
          component="h1"
          className={"archiveTitle"}
        >
          Achieve goals
        </Typography>
        <span> <Typography
          variant="h1"
          component="span"
          className={"typeText"}
        >
          {this.state.text}
        </Typography></span>
        <span id="cursor" />
      </Box>
      </h1>
    );

  }
}
