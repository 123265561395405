/*eslint @typescript-eslint/no-unused-vars: "off"*/
import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextareaAutosize,
} from "@mui/material";
import palette from "../../theme/palette";
import { makeStyles } from "@material-ui/core";
import ProgressStepper from "./progressStepper";
import ModalCloseIcon from "../../assets/images/icon/modalCloseIconBlack";
import { trackingService } from "../../services/tracking";
import { UserTypeContext } from "../../context/userStatus";
import { ButtonLoader } from "../../components/buttonLoader";
import ProgressStepperInfluence from "./progressStepperInfluence";
import InfluenceclosePopup from "./InfluenceclosePopup";
import DeleteIcon from "../../assets/images/icon/DeleteIcon";
import { addCategoryJourneyAction, addJourneyAction } from "../../store/actions/userTypeAction";
import typography, { pxToRem } from "../../theme/typography";
import ProgressStepperInfluenceNew from "./progressStepperInfluenceNew";
import { TrackingContext } from "../../context/userTracking";
import { setDayCompleteMessage } from "../../store/actions/trackerAction";
import moment from "moment";
import NewButton from "../../components/NewButton";

const useStyles = makeStyles((theme) => ({
  registrationHeading: {
    color: `${palette.primary.light}!important`,
    marginBottom: "20px!important",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px!important",
    },
  },
  pageTitle: {
    marginBottom: "16px!important",
  },
  needSomeIdeas: {
    fontSize: "16px",
    lineHeight: "16px",
    color: palette.primary.main,
    marginLeft: "8px",
    [theme.breakpoints.down("lg")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "14px",
    },
  },
  bottomButton: {
    display: "flex",
    padding: "32px",
    gap: "12px",
    alignSelf: 'stretch',
    alignItems: "center",
    borderTop: '1px solid #F3F3F3',
    justifyContent: "flex-end",
    "& button:nth-child(2)": {
      // marginLeft: "24px",
      marginBottom: '0px !important'
    },
  },
  textarea: {
    width: "100%",
    padding: "20px",
    fontFamily: typography.fontFamily,
    fontSize: "16px",
    fontWeight: 400,
    resize: "vertical",
    "&:focus-visible": {
      outline: "red"
    }
  }
}));

interface AddTrackingEvent {
  setOpenTrackingEventModal: (openTrackingEventModal: boolean) => void;
  index: number;
  setOutcomeId: (outcomeId: string) => void;
  getBackgroudColor: any;
  setSelectedOption: (selectedOption: any) => void;
  selectedOption: any;
  setShowInput: (showInput: boolean) => void;
  influenceId: string;
  item: any;
  setShowSnakbar: (showSnakbar: boolean) => void;
  setSnakbarMsg: (snakbarMsg: string) => void;
  setMsgType: (msgType: string) => void;
  setOptionIndex: (optionIndex: string) => void;
  optionIndex: string;
  setDisplayBorder: (displayBorder: boolean) => void;
  displayBorder: boolean;
  callService: any;
  isEdit: boolean;
  journeyData: any;
  setIsEdit: any;
  headerDate: any;
  callToGetData?: boolean
  isMobile?: boolean
  renderToNextInput?: () => void
}

const AddTrackingInfluenceEventModal = (props: AddTrackingEvent) => {
  const classes = useStyles();
  const { dispatch: trackingDispatch } = useContext(TrackingContext);
  const { state: outcomeState, dispatch: UserTypeDispatch } = useContext(UserTypeContext);
  const [openStartModal, setStartModal] = useState(true);
  const [showLoadingBtn, setShowLoadingBtn] = useState(false);
  const [outcomeNotes, setOutcomeNotes] = useState("");
  const [item, setItem] = useState<any>();
  const [editData, setEditData] = useState<any>();
  const [openInfluenceclosePopup, setopenInfluenceclosePopup] = useState(false);
  const [openInfluenceDeletePopup, setopenInfluenceDeletePopup] = useState(false);
  const [textAreaFocused, setTextAreaFocused] = useState<boolean>(false);
  const { isMobile = false } = props
  const [isBackButtonClicked, setBackbuttonPress] = useState(false)
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.optionIndex, outcomeNotes, isBackButtonClicked]);
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!isBackButtonClicked) {
      setBackbuttonPress(true)
      if(editData?.influenceId && editData?.index === props.optionIndex && (outcomeNotes === editData?.note[0] || (!outcomeNotes && !editData?.note[0])) ){
        handleCloseStartModal()
      } else if(props.optionIndex !== "" || outcomeNotes !== "") {
        setopenInfluenceclosePopup(true)
      } else {
        handleCloseStartModal()
      }
    }
  }
  const handleCloseStartModal = () => {
    setStartModal(false);
    props.setOpenTrackingEventModal(false);
    props.setOutcomeId("");
    props.setShowInput(false);
  };

  const handleDeleteEntry = () => {
    let payload = {
      influenceId: editData?.influenceId,
      date: props?.headerDate,
    };
    trackingService
      .deleteInfluenceRes(outcomeState.token, payload)
      .then((data) => {
        if (!outcomeState?.isPersonal) {
          const foundOutcome = (outcomeState?.influenceList || []).find(it => it.influenceId === payload.influenceId);
          if (foundOutcome) {
            const journyList = { ...(outcomeState?.journeyList || {}) } as any
            const foundDateItemIcon = journyList.influence_data.findIndex(it => it.influenceId === payload.influenceId && it?.date === payload.date)
            if (foundDateItemIcon > -1) {
              journyList.influence_data.splice(foundDateItemIcon, 1)
            }
            UserTypeDispatch(addJourneyAction(journyList as any))
            // if (props.callToGetData) {
            //   props?.callService(false);
            // }
          }
        } else {
          if (isMobile) {
            const foundOutcome = (outcomeState?.influenceList || []).find(it => it.influenceId === payload.influenceId);
            if (foundOutcome) {
              const journyList = { ...(outcomeState?.journeyList || {}) } as any
              const foundDateItemIcon = journyList.influence_data.findIndex(it => it.influenceId === payload.influenceId && it?.date === payload.date)
              if (foundDateItemIcon > -1) {
                journyList.influence_data.splice(foundDateItemIcon, 1)
              }
              UserTypeDispatch(addJourneyAction(journyList as any))
              // if (props.callToGetData) {
              //   props?.callService(false);
              // }
            }
          }
          const journeyCategoryList = [...(outcomeState?.journeyCategoryList || [])]
          const foundOutcome = (journeyCategoryList
            || []).findIndex(it => it.influenceIds && it.influenceIds.includes(payload.influenceId));
          if (foundOutcome > -1) {
            if (journeyCategoryList[foundOutcome].alls && journeyCategoryList[foundOutcome].alls.length) {
              const foundIndex = journeyCategoryList[foundOutcome].alls.findIndex(it => it.influenceId === payload.influenceId)
              if (foundIndex > -1) {
                if (journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses && journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.length) {
                  const foundDateValue = journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.findIndex(it => it.date === payload.date)
                  if (foundDateValue > -1) {
                    journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.splice(foundDateValue, 1)
                  }
                }
                UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
                // if (props.callToGetData) {
                //   props?.callService(false);
                // }
              }
            }
          } else {
            const foundOther = (journeyCategoryList || []).findIndex(it => it.name === "Other")
            if (foundOther > -1) {
              const outcomeFound = journeyCategoryList[foundOther].influences.findIndex(it => it.influenceId === payload.influenceId);
              if (outcomeFound > -1) {
                if (journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses && journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.length) {
                  const foundDateValue = journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.findIndex(it => it.date === payload.date)
                  if (foundDateValue > -1) {
                    journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.splice(foundDateValue, 1)
                  }
                }
                UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
                // if (props.callToGetData) {
                //   props?.callService(false);
                // }
              }
            }
          }
        }
        setShowLoadingBtn(false);
        // props.setShowSnakbar(true);
        props.setSnakbarMsg(data?.message);
        props.setOpenTrackingEventModal(false);
        // props?.callService(false);
        props.setMsgType("success");
      })
      .catch((error) => {
        setShowLoadingBtn(false);
        props.setShowSnakbar(true);
        props.setSnakbarMsg(
          error?.response?.data?.description
            ? error?.response?.data?.description
            : error?.message
        );
        props.setMsgType("error");
      });
  };
  const onKeyDownHandler = (e) => {
    let keyPress = e.ctrlKey || e.altKey
    if (e.keyCode === 13  || (keyPress && ([65, 85].includes(e.keyCode)))) {
      if(!textAreaFocused) {
        trackingOutcomeCreate()
      }
    }
  }
  const trackingOutcomeCreate = () => {
    if (item?.score !== "" && Number(item?.score) >= 0 ) {
      setShowLoadingBtn(true);
    let outcomeCreateData;
    if (props?.isEdit) {
      outcomeCreateData = {
        date: props?.headerDate,
        influenceId: editData?.influenceId,
        answerId: item?.answerId,
        score: item?.score,
        index: props.optionIndex,
        note:
          outcomeNotes === undefined || outcomeNotes === ""
            ? []
            : [outcomeNotes],
        Type: "TYPE2",
      };
    } else {
      outcomeCreateData = {
        date: props?.headerDate,
        influenceId: props.item?.influenceId,
        answerId: item?.answerId,
        score: item?.score,
        index: parseInt(props.optionIndex),
        note:
          outcomeNotes === undefined || outcomeNotes === ""
            ? []
            : [outcomeNotes],
        Type: "TYPE2",
      };
    }
    trackingService
      .influenceCreate(outcomeState.token, outcomeCreateData)
      .then((data) => {
        if (!outcomeState?.isPersonal) {
          const foundOutcome = (outcomeState?.influenceList || []).find(it => it.influenceId === outcomeCreateData.influenceId);
          if (foundOutcome) {
            const journyList = { ...(outcomeState?.journeyList || {}) } as any
            const foundDateItemIcon = journyList.influence_data.findIndex(it => it.influenceId === outcomeCreateData.influenceId && it?.date === outcomeCreateData.date)
            if (foundDateItemIcon > -1) {
              journyList.influence_data[foundDateItemIcon] = {
                ...foundOutcome,
                ...outcomeCreateData,
              }
            } else {
              journyList.influence_data.push({
                ...foundOutcome,
                ...outcomeCreateData,
              } as any)
            }
            UserTypeDispatch(addJourneyAction(journyList as any))
            if (props.callToGetData) {
              props?.callService(false);
            }
          }
        } else {
          const journeyCategoryList = [...(outcomeState?.journeyCategoryList || [])]
          const foundOutcome = (journeyCategoryList
            || []).findIndex(it => it.influenceIds && it.influenceIds.includes(outcomeCreateData.influenceId));
          if (foundOutcome > -1) {
            if (journeyCategoryList[foundOutcome].alls && journeyCategoryList[foundOutcome].alls.length) {
              const foundIndex = journeyCategoryList[foundOutcome].alls.findIndex(it => it.influenceId === outcomeCreateData.influenceId)
              if (foundIndex > -1) {
                if (journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses && journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.length) {
                  const foundDateValue = journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.findIndex(it => it.date === outcomeCreateData.date)
                  if (foundDateValue > -1) {
                    journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses[foundDateValue] = {
                      ...outcomeCreateData
                    }
                  } else {
                    journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.push(outcomeCreateData)
                  }
                } else {
                  journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses = [outcomeCreateData]
                }
                UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
                if (props.callToGetData) {
                  props?.callService(false);
                }
              }
            }
          } else {
            const foundOther = (journeyCategoryList || []).findIndex(it => it.name === "Other")
            if (foundOther > -1) {
              const outcomeFound = journeyCategoryList[foundOther].influences.findIndex(it => it.influenceId === outcomeCreateData.influenceId);
              if (outcomeFound > -1) {
                if (journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses && journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.length) {
                  const foundDateValue = journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.findIndex(it => it.date === outcomeCreateData.date)
                  if (foundDateValue > -1) {
                    journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses[foundDateValue] = {
                      ...outcomeCreateData
                    }
                  } else {
                    journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.push(outcomeCreateData)
                  }
                } else {
                  journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses = [outcomeCreateData]
                }
                UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
                if (props.callToGetData) {
                  props?.callService(false);
                }
              }
            }
          }
        }
        props.renderToNextInput && !isMobile && props.renderToNextInput()
        if(data.showMessage && props?.headerDate === moment().format("MM-DD-YYYY")) {
          trackingDispatch(setDayCompleteMessage({
            open: data.showMessage,
            message: data.message
          }))
        }
        setShowLoadingBtn(false);
        props.setShowSnakbar(false);
        props.setSnakbarMsg(data?.message);
        props.setOpenTrackingEventModal(false);
        // props?.callService(false);
        props.setMsgType("success");
      })
      .catch((error) => {
        setShowLoadingBtn(false);
        props.setShowSnakbar(true);
        props.setSnakbarMsg(
          error?.response?.data?.description
            ? error?.response?.data?.description
            : error?.message
        );
        props.setMsgType("error");
      });
    }
    
  };
  useEffect(() => {
    const outcomeGetData = {
      influenceId: props.item?.influenceId,
      date: props?.headerDate,
    };
    trackingService
      .getInfluenceRes(outcomeState.token, outcomeGetData)
      .then((data) => {
        setEditData(data?.data);
        if (data?.data == null) {
          props?.setIsEdit(false);
        } else {
          props?.setIsEdit(true);
        }
        setOutcomeNotes(data?.data?.note[0] ?? "");
        props?.setOptionIndex(data?.data?.index ?? "");
        setItem(data?.data);
      })
      .catch((error) => {
        console.log("outcome response get error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid>
      <form onKeyDown={onKeyDownHandler}>
        <AddUpdateEventModal
          onClose={
            editData?.influenceId && editData?.index === props.optionIndex && (outcomeNotes === editData?.note[0] || (!outcomeNotes && !editData?.note[0])) ? handleCloseStartModal :
              props.optionIndex !== "" || outcomeNotes !== ""
                ? () => {
                  setopenInfluenceclosePopup(true);
                }
                : handleCloseStartModal}
          onSave={trackingOutcomeCreate}
          isMobile={props.isMobile}
          showTitle={!props.isMobile}
          paperProps={isMobile ? {
            height: "calc(100vh - 40%)"
          } : {}}
          title={<>

            {!isMobile && props?.isEdit ? (props.item?.type === "TYPE2" ? "Update Tracking Event" : "Update Tracking Event") : props.item?.type === "TYPE2" ? "Add Tracking Event" : "Add Tracking Event"}
            {editData?.influenceId &&
              <IconButton
                onClick={() => {
                  setopenInfluenceDeletePopup(true);
                }}
              >
                <DeleteIcon />
              </IconButton>}
          </>}
          showLoadingBtn={showLoadingBtn}
          visible={openStartModal}
          buttonText={props?.isEdit ? <span><span style={{ textDecoration: "underline"}}>U</span>pdate</span> : <span><span style={{ textDecoration: "underline"}}>A</span>dd</span>}
          saveDisabled={!(props.optionIndex !== "" && Number(props.optionIndex) >= 0)}
        >
          {isMobile && <div style={{
            position: "absolute",
            right: 0,
            top: 0
          }}>
            {editData?.influenceId && <IconButton
              onClick={() => {
                setopenInfluenceDeletePopup(true);
              }}
            >
              <DeleteIcon />
            </IconButton>}
          </div>}
          <div
            style={{
              display: 'flex',
              padding: props.isMobile ? "0px" : '24px 32px 0px 32px',
              flexDirection: 'column',
              alignItems: props.isMobile ? "flex-start" : 'center',
              gap: isMobile ? '24px' : '16px',
              justifyContent: props.isMobile ? "flex-start" : "center",
              width: "100%",
              alignSelf: 'stretch',

            }}>
            <Typography
              variant="body1"
              component="div"
              sx={isMobile ? {
                color: palette.primary.light,
                fontSize: `${pxToRem(20)} !important`,
                lineHeight: "125% !important",
                // textAlign: "center !important",
                fontWeight: `500 !important`,
                "&.MuiTypography-root": {
                  "@media  (max-width: 600px) and (min-width: 100px)": {
                    fontSize: `${pxToRem(20)} !important`,
                    lineHeight: "125% !important"
                  },
                }
              } : { fontWeight: 600, fontSize: `${pxToRem(24)} !important`, lineHeight: "135%  !important" }}
              className={classes.registrationHeading}
            >
              {props?.item?.name || "Name"}
            </Typography>
          </div>
          <div style={{
            display: 'flex',
            padding: isMobile ? '24px 0px 0px 0px' : '32px 32px 0',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '32px',
            alignSelf: 'stretch',


          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '16px',
              alignSelf: 'stretch',


            }}>
              {!isMobile && <Typography
                variant="caption"
                component="p"
                className={classes.pageTitle}
              >
                Allocate the rating that best describes how you feel about this
                influence today
              </Typography>}
              <ProgressStepperInfluenceNew
                index={props.index}
                getBackgroudColor={props.getBackgroudColor}
                setSelectedOption={props.setSelectedOption}
                selectedOption={props.selectedOption}
                setOptionIndex={props.setOptionIndex}
                optionIndex={props.optionIndex}
                setItem={setItem}
                setDisplayBorder={props.setDisplayBorder}
                displayBorder={props.displayBorder}
                editData={editData}
                absenceToggle={props?.item?.absenceToggle}
                journeyData={props?.journeyData}
                isMobile={isMobile}
              />
              <div style={{ width: "100%", marginTop: isMobile ? "24px" : "32px" }}>
                <Typography
                  variant="caption"
                  component="p"
                  className={classes.pageTitle}
                  sx={isMobile ? {
                    color: palette.primary.light,
                    fontSize: `${pxToRem(16)} !important`,
                    lineHeight: "100% !important",
                    // textAlign: "center !important",
                    fontWeight: `500 !important`,
                    "&.MuiTypography-root": {
                      "@media  (max-width: 600px) and (min-width: 100px)": {
                        fontSize: `${pxToRem(16)} !important`,
                        lineHeight: "100% !important"
                      },
                    }
                  } : {}}
                >
                  {isMobile ? "Note for today’s entry" : "Add a note for today's entry"}
                </Typography>
                <TextareaAutosize
                    // variant="outlined"
                    className={"textarea "+ classes.textarea}
                    // sx={{
                    //   backgroundColor: `#fff`,
                    //   border: `1px solid #e3e3e3 !important`,
                    //   borderRadius: `10px`,
                    //   marginBottom: "40px !important"
                    // }}
                    onFocus={() => {
                      setTextAreaFocused(true)
                    }}
                    onBlur={() => {
                      setTextAreaFocused(false)
                    }}
                    // fullWidth
                    placeholder={/* isMobile ? "What’s worth remembering today?": */"Add a note..."}
                    // multiline
                    minRows={1}
                    onChange={(e) => setOutcomeNotes(e.target.value)}
                    value={outcomeNotes}
                  />
              </div>
            </div>

          </div>
          {/* <DialogContent> */}
          <InfluenceclosePopup
            open={openInfluenceDeletePopup}
            isDelete={openInfluenceDeletePopup}
            setopenInfluenceclosePopup={setopenInfluenceDeletePopup}
            handleCloseStartModal={handleDeleteEntry}
            setBackbuttonPress={setBackbuttonPress}
          />
          <InfluenceclosePopup
            open={openInfluenceclosePopup}
            setopenInfluenceclosePopup={setopenInfluenceclosePopup}
            handleCloseStartModal={handleCloseStartModal}
            setBackbuttonPress={setBackbuttonPress}
          />
        </AddUpdateEventModal>
      </form>




    </Grid>
  );
};
export default AddTrackingInfluenceEventModal;

export const AddUpdateEventModal = ({ visible, onClose, title, showLoadingBtn, saveDisabled = false, onSave, buttonText = <span><span style={{ textDecoration: "underline"}}>A</span>dd</span>, ...props }) => {
  const classes = useStyles();
  const { showTitle = true } = props
  const { paperProps = {} } = props
  return <Dialog
    onClose={onClose}
    open={visible}
    sx={{
      "& .MuiPaper-root": {
        padding: "0",
        borderRadius: "24px",
        background: "#FFF",
        ...paperProps
      }
    }}
    className="dialogWrapper dialogWrapper-tracking dialogWrapper-mobileScreen"
    fullWidth
  >
    {showTitle && <div style={{
      display: 'flex',
      padding: '20px 24px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      alignSelf: 'stretch',
      borderBottom: '1px solid #F3F3F3',
      background: '#FFF',
      position: "relative",
      borderTopLeftRadius: "24px",
      borderTopRightRadius: "24px",
    }}>
      {title}
      <IconButton
        sx={{ position: "absolute", top: 10, right: 10 }}
        // onClick={handleCloseStartModal}
        onClick={
          onClose
        }
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M15 15L10 10M10 10L5 5M10 10L15 5M10 10L5 15" stroke="black" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </IconButton>
    </div>}
    <DialogContent className="hide-scrollbar" sx={props?.isMobile ? {
      position: "relative",
    } : {}}>
      {props.children}

      <Box className={classes.bottomButton} sx={props?.isMobile ? {
        padding: "0px 0px 0px !important",
        borderTop: "none"
      } : {

      }}>
        <NewButton buttonText="Cancel" variant="text"
                  textColor="var(--button-active-text)" onClick={onClose}/>
        <NewButton buttonText={buttonText} isDisabled={saveDisabled} variant="contained" onClick={onSave} isLoadong={showLoadingBtn}/>
      </Box>
    </DialogContent>

  </Dialog>
}