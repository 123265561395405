import { Box, TextField } from "@mui/material"
import React, { useState } from "react"
import CommonTypo from "../../components/CommonTypo"
import { Input } from "../../components/inputGroup";
import NewButton from "../../components/NewButton";
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updatePassword } from "firebase/auth";
import typography from "../../theme/typography";
const PasswordChange = ({
    setShowSnakbar,
    setSnakbarMsg,
    setMsgType
}) => {
    const [CurrentPassword, setCurrentPassword] = useState("");
    const [NewPassword, setNewPassword] = useState("");
    const handleSubmitData = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        const credential = EmailAuthProvider.credential(
            user.email,
            CurrentPassword
        );
        await reauthenticateWithCredential(auth.currentUser, credential)
            .then(() => {
                updatePassword(user, NewPassword)
                    .then((res) => {
                        // setLoading(false);
                        // Update successful.
                        setShowSnakbar(true);
                        setSnakbarMsg("Password changed successfully");
                        setMsgType("success");
                        setCurrentPassword("")
                        setNewPassword("")
                    })
                    .catch((error) => {
                        console.log("error = ", error.message);
                        // An error ocurred
                        // ...
                    });
            })
            .catch((err) => {
                console.log("err", err, err.message);
                if (err.message.includes("auth/wrong-password")) {
                    setShowSnakbar(true);
                    setSnakbarMsg("Current password did not match");
                    setMsgType("error");
                }
                if (err.message.includes("temporarily disabled")) {
                    setShowSnakbar(true);
                    setSnakbarMsg(
                        "Your account has been temporary disabled due to too much incorrect password. Please try after sometime"
                    );
                    setMsgType("error");
                }
            });
    };
    return <Box display={"flex"} flexDirection={"column"} gap={"16px"} >
        <Box display={"flex"} alignContent={"center"} width={"100%"} flex={1} alignSelf={"stretch"} gap={"8px"}>
            <Box display={"flex"} flexDirection={"column"} gap={"8px"} flex={"calc(100% / 2)"}>
                <CommonTypo size={16} fontWeight={600} color="var(--color-text-primary)">Current password</CommonTypo>
                <Input placeholder="Current password" type="password" height={"50px"} extraSx={{
                    border: "1px solid var(--color-border)",
                    alignSelf: "stretch",
                    borderRadius: "12px",
                    height: "50px",
                    marginBottom: "0px !important",
                    "& .MuiInputBase-root": {
                        borderRadius: "12px !important",
                        height: "50px",
                        "& .MuiInputBase-input": {
                            borderRadius: "12px",
                            height: "50px",
                            padding: "16px !important",
                            "&::placeholder": {
                                color: "var(--color-text-primary)",
                                opacity: 1,
                                fontSize: "16px",
                                fontFamily: typography.fontFamily
                            }
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            display: "none"
                        }
                    }
                }} value={CurrentPassword}
                    onChange={(value) => setCurrentPassword(value)} />
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={"8px"} flex={"calc(100% / 2)"}>
                <CommonTypo size={16} fontWeight={600} color="var(--color-text-primary)">New password</CommonTypo>
                <Input placeholder="New password" type="password" height={"50px"} extraSx={{
                    border: "1px solid var(--color-border)",
                    alignSelf: "stretch",
                    borderRadius: "12px",
                    height: "50px",
                    marginBottom: "0px !important",
                    "& .MuiInputBase-root": {
                        borderRadius: "12px !important",
                        height: "50px",
                        "& .MuiInputBase-input": {
                            borderRadius: "12px",
                            height: "50px",
                            padding: "16px !important",
                            "&::placeholder": {
                                color: "var(--color-text-primary)",
                                opacity: 1,
                                fontSize: "16px",
                                fontFamily: typography.fontFamily
                            }
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            display: "none"
                        }
                    }
                }} value={NewPassword}
                    onChange={(value) => setNewPassword(value)} />
            </Box>

        </Box>

        <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
            <NewButton
                buttonText={"Save"}
                variant="contained"
                onClick={handleSubmitData}
                boxSx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "40px",
                    padding: "16px"
                }}
                typographySx={{
                    fontSize: "16px !important",
                    color: "var(--color-text-primary)",
                    fontWeight: `600 !important`
                }}
            />
        </Box>
    </Box>

}

export default PasswordChange