import React, { useState, useEffect, useContext, useCallback } from "react";
import {
    Button,
    Grid,
    TextField,
    Typography,
    SvgIcon,
    Dialog,
    Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SnackBarComp from "../../components/snackBar";
import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
import shape from "../../theme/shape";
import BottomStepper from "./BottomStepper";
import useWidth from "../../utils/useWidth";
import { pxToRem } from "../../theme/typography";
interface LeftSideOutComeModal {
    outComeValue: any;
    setOutcomeValue: (outComeValue: string) => void;
    outcomeIdeas: any;
    setShowOutcome: (showOutcome: boolean) => void;
    showOutcome: boolean;
    setAddOutcomeTracking: (addOutcomeTracking: boolean) => void;
    setTextInput: any;
    textInput: any;
    ispersonalMode?: boolean;
    handleCloseStartModal?: any;
    getOutcomeList?: any;
    categoryId?: any;
    setTransformToInfluenceTracking?: any;
    width?: any;
    setShowSnakbar?: any;
    setSnakbarMsg?: any;
    setMsgType?: any;
    startDate?: any
    endDate?: any
    onboarding?: boolean
    listOfImprovements?: any[]
    selectedPlans?: any[]
    setSelectedPlans?: any
    handleClickStep?: any
    curretStep?: number
    secondselectedPlans?: any[]
    setSecondSelectedPlans?: any
    setSelectedPlanOutcomes?: any
    setSelectedPlanInfluences?: any
    setSelectedPlanReflectives?: any
}
const RootStyle = styled("div")(({ theme }) => ({
    padding: "40px 80px",
    display: 'flex',
    alignItems: "center",
    flexDirection: 'column',
    gap: '52px',
    [theme.breakpoints.up("xl")]: {
        padding: "60px",
    },
    [theme.breakpoints.down("lg")]: {
        padding: "40px",
    },
    [theme.breakpoints.down("md")]: {
        padding: "30px",
    },
    [theme.breakpoints.down("sm")]: {
        padding: "20px",
    },
}));
const useStyles = makeStyles((theme) => ({
    registrationHeading: {
        color: palette.primary.newLight,
        fontSize: `${pxToRem(32)} !important`,
        fontWeight: 800,
        lineHeight: `${pxToRem(46)} !important`,
        textAlign: "center"
    },
    registrationHeadingInner: {
        color: `#30A35E !important`,
        fontSize: `${pxToRem(32)} !important`,
        fontWeight: 800,
        lineHeight: `${pxToRem(46)} !important`,
    },
    registrationSubHeading: {
        color: `${palette.primary.newLight} !important`,
        fontSize: `${pxToRem(16)} !important`,
        fontWeight: 800,
        lineHeight: `${pxToRem(23)} !important`,
    },
    improvementTitle: {
        color: `${palette.primary.light} !important`,
        fontSize: '20px !important',
        fontWeight: 700,
        lineHeight: '100%',
    },
    improvementSelectedTitle: {
        color: `${palette.primary.contrastText} !important`,
        fontSize: '20px !important',
        fontWeight: 700,
        lineHeight: '100%',
    },
    improvementTitleNew: {
        color: `${palette.primary.newLight} !important`,
        fontSize: '16px !important',
        fontWeight: 400,
        lineHeight: '100%',
    },
    improvementSelectedTitleNew: {
        color: `${palette.primary.light} !important`,
        fontSize: '16px !important',
        fontWeight: 400,
        lineHeight: '100%',
    },
    pageTitle: {
        marginTop: "40px!important",
        marginBottom: "48px!important",
        [theme.breakpoints.down("lg")]: {
            marginTop: "30px!important",
            marginBottom: "38px!important",
        },
        [theme.breakpoints.down("md")]: {
            marginTop: "20px!important",
            marginBottom: "28px!important",
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: "15px!important",
            marginBottom: "20px!important",
        },
    },
    needSomeIdeas: {
        fontSize: "16px",
        lineHeight: "16px",
        color: palette.primary.main,
        marginLeft: "8px",
        [theme.breakpoints.down("lg")]: {
            marginTop: "10px",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
            lineHeight: "14px",
        },
    },
    colorBlock: {
        cursor: "pointer",
        backgroundColor: palette.secondary.light,
        border: "1px solid #DCE6E0",
        borderRadius: shape.borderRadius,
        padding: "11px 16px",
        display: "flex",
        alignItems: "center",
        marginLeft: "16px",
        [theme.breakpoints.down("lg")]: {
            marginLeft: 0,
        },
    },
    spaceBetween: {
        [theme.breakpoints.down("lg")]: {
            marginTop: "10px",
            marginBottom: "10px",
        },
    },
    colorBlockText: {
        margin: 0,
        color: palette.primary.light,
        fontSize: "16px",
        lineHeight: "16px",
        fontWeight: 500,
    },
    colorCode: {
        width: "24px",
        height: "24px",
        marginLeft: "10px",
        borderRadius: shape.borderRadiusSm,
    },
    outcomeTitle: {
        marginTop: "48px!important",
        marginBottom: "20px!important",
        [theme.breakpoints.down("lg")]: {
            marginTop: "40px!important",
        },
        [theme.breakpoints.down("md")]: {
            marginTop: "30px!important",
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: "20px!important",
        },
    },
    ideasListItem: {
        backgroundColor: palette.common.white,
        borderRadius: shape.borderRadius,
        padding: "24px",
        fontSize: "16px",
        lineHeight: "16px",
        color: palette.primary.light,
        fontWeight: 400,
        marginBottom: "12px",
        cursor: "pointer",
    },
}));
const LeftSideSelectedImprovement = (props: LeftSideOutComeModal) => {
    const classes = useStyles();
    const [showSnakbar, setShowSnakbar] = useState(false);
    const [snakbarMsg, setSnakbarMsg] = useState("");
    const [msgType, setMsgType] = useState("");
    const handleCloseSnakbar = () => {
        setShowSnakbar(false);
    };
    const { listOfImprovements } = props
    const width = useWidth()
    const isMobile = width < 768
    const bottomStepper = <BottomStepper
        isOutcome={true}
        isInfluence={false}
        isRefQues={false}
        isTracking={false}
        handleClickStep={props?.handleClickStep}
        curretStep={props?.curretStep}
        nextDisabled={props.secondselectedPlans.length <= 0}
        prevDisabled={false}
        isMobile={isMobile}
    />
    return <RootStyle
        // sx={!props.showOutcome ? { height: "100vh", overflowY: 'auto', paddingBottom: '100px !important' } : { height: "100vh", overflowY: 'auto', }}
        className={`login-right-part ${isMobile ? "right-side-improvement-bg-mobile height100" : "right-side-improvement-bg"}`}
        sx={{
            background: `rgba(255, 255, 255, 0.3) !important`,
        }}
    >
        <SnackBarComp
            showSnakbar={showSnakbar}
            handleCloseSnakbar={handleCloseSnakbar}
            snakbarMsg={snakbarMsg}
            type={msgType ? msgType : "info"}
        />
        {isMobile && bottomStepper}
        <Typography
            variant="h1"
            component="h1"
            className={classes.registrationHeading}
            sx={isMobile ? {
                fontSize: `${pxToRem(22)} !important`,
                lineHeight: "120% !important",
                textAlign: "center !important",
                // fontWeight: `400 !important`,
                fontWeight: `700 !important`,
                "&.MuiTypography-root": {
                    "@media  (max-width: 600px) and (min-width: 100px)": {
                        fontSize: `${pxToRem(22)} !important`,
                        lineHeight: "120% !important"
                    },
                }
            } : {
                width: "90%"
            }}
        >
            What is the <Typography
                component="span"
                variant="h1"
                className={classes.registrationHeadingInner}
                sx={isMobile ? {
                    fontSize: `${pxToRem(20)} !important`,
                    lineHeight: "120% !important",
                    textAlign: "center !important",
                    // fontWeight: `400 !important`,
                    fontWeight: `700 !important`,
                    "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                            fontSize: `${pxToRem(20)} !important`,
                            lineHeight: "120% !important"
                        },
                    }
                } : {
                    
                }}
            >most important</Typography> to work on at the moment?
        </Typography>
        <Typography
            component="h1"
            className={classes.registrationSubHeading}
            sx={isMobile ? {
                fontSize: `${pxToRem(16)} !important`,
                lineHeight: "120% !important",
                textAlign: "center !important",
                // fontWeight: `400 !important`,
                fontWeight: `500 !important`,
                "&.MuiTypography-root": {
                    "@media  (max-width: 600px) and (min-width: 100px)": {
                        fontSize: `${pxToRem(16)} !important`,
                        lineHeight: "120% !important"
                    },
                }
            } : {
                fontWeight: `400 !important`,
                color: `${palette.primary.newLight} !important`,
            }}
        >
            Select <strong>one</strong> goal
        </Typography>
       { isMobile ?<Grid
            container
            columns={16}
            rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}
            sx={isMobile ? {
                maxHeight: '100%',
                overflowY: 'auto',
                height: "calc(100vh - 30%)"
            } : {
               
            }}
        >
            {props.selectedPlans.map(it => {
                let selectedPlans = [...(props.secondselectedPlans || [])]
                const found = selectedPlans.find(ite => ite.categoryId === it.categoryId)
                return <Grid item xs={16} >
                    <Box sx={{
                        display: 'flex',
                        padding: '18px 21px 17px 20px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        flexShrink: 0,
                        borderRadius: '5px',
                        // border: '2px solid #BADACA',
                        border: found ? "2px solid "+ "#D6F5C3" : '2px solid #BADACA',
                        background: found ? "#D6F5C3" : 'rgba(252, 255, 250, 0.50)',
                        // background: found ? '#11734A' : 'rgba(252, 255, 250, 0.50)',
                        cursor: "pointer"
                    }} onClick={() => {
                        if (props.secondselectedPlans[0] && props.secondselectedPlans[0].categoryId !== it.categoryId) {
                            props?.setSelectedPlanOutcomes([])
                            props?.setSelectedPlanInfluences([])
                            props?.setSelectedPlanReflectives([])
                        }
                        props?.setSecondSelectedPlans([it])

                    }}>
                        <Typography
                            component="span"
                            className={found ? classes.improvementSelectedTitleNew : classes.improvementTitleNew}
                            sx={isMobile ? {
                                fontSize: `${pxToRem(20)} !important`,
                                lineHeight: "normal !important",
                                textAlign: "center !important",
                                // fontWeight: `400 !important`,
                                fontWeight: /* found ? `700 !important` : */ `400 !important`,
                                "&.MuiTypography-root": {
                                    "@media  (max-width: 600px) and (min-width: 100px)": {
                                        fontSize: `${pxToRem(20)} !important`,
                                        lineHeight: "normal !important"
                                    },
                                }
                            } : {
                                fontWeight: found ? `700 !important` : `400 !important`
                            }}
                        >
                            {it.name}
                        </Typography>

                    </Box>
                </Grid>
            })}
        </Grid> : <Grid
            container
            columns={16}
            rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}
        >
            {props.selectedPlans.map(it => {
                let selectedPlans = [...(props.secondselectedPlans || [])]
                console.log("selectedPlans", selectedPlans)
                const found = selectedPlans.find(ite => ite.categoryId === it.categoryId)
                return <Grid item xs={16} >
                    <Box sx={{
                        display: 'flex',
                        // padding: '18px 21px 17px 20px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        flexShrink: 0,
                        borderRadius: '50px',
                        border: found ? "1px solid "+ "#D6F5C3" : '1px solid #FCFFFC',
                        background: found ? "#D6F5C3" : '#FCFFFC',
                        cursor: "pointer",
                        padding: "15px 16px 15px 16px !important",
                        "&:hover": {
                            border: "1px solid "+ "#BADACA"
                        }
                    }} onClick={() => {
                        if (props.secondselectedPlans[0] && props.secondselectedPlans[0].categoryId !== it.categoryId) {
                            props?.setSelectedPlanOutcomes([])
                            props?.setSelectedPlanInfluences([])
                            props?.setSelectedPlanReflectives([])
                        }
                        props?.setSecondSelectedPlans([it])
                        localStorage.setItem("planName", it.name)

                    }}>
                        <Typography
                            component="span"
                            className={found ? classes.improvementSelectedTitleNew : classes.improvementTitleNew}
                            sx={isMobile ? {
                                fontSize: `${pxToRem(20)} !important`,
                                lineHeight: "normal !important",
                                textAlign: "center !important",
                                // fontWeight: `400 !important`,
                                fontWeight: found ? `700 !important` : `400 !important`,
                                "&.MuiTypography-root": {
                                    "@media  (max-width: 600px) and (min-width: 100px)": {
                                        fontSize: `${pxToRem(20)} !important`,
                                        lineHeight: "normal !important"
                                    },
                                }
                            } : {
                                fontSize: `${pxToRem(16)} !important`,
                                lineHeight: "normal !important",
                                textAlign: "center !important",
                                // fontWeight: `400 !important`,
                                fontWeight: `400 !important`,
                                "&.MuiTypography-root": {
                                    "@media  (max-width: 600px) and (min-width: 100px)": {
                                        fontSize: `${pxToRem(16)} !important`,
                                        lineHeight: "normal !important"
                                    },
                                },
                                color: `${palette.primary.newLight} !important`
                            }}
                        >
                            {it.name}
                        </Typography>

                    </Box>
                </Grid>
            })}
        </Grid>}
        {!isMobile && bottomStepper}
        {isMobile && <Button
            variant="contained"
            className="new-button"
            fullWidth
            sx={{ mb: `0px !important`, height: "60px" }}
            disabled={props.secondselectedPlans.length <= 0}
            onClick={() => {
                props?.handleClickStep(props.curretStep + 1)
            }}
        >
            <Typography
                variant="h1"
                component="span"
                color={props.secondselectedPlans.length <= 0 ? "#A0A0A1" : "#124743"}
                sx={{
                    fontSize: `${pxToRem(16)} !important`,
                    lineHeight: "normal !important",
                    textAlign: "center !important",
                    fontWeight: `400 !important`,
                    "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                            fontSize: `${pxToRem(16)} !important`,
                            lineHeight: "normal !important"
                        },
                    }
                }}
            >
                Continue

            </Typography>
        </Button>}
    </RootStyle>
}

export default LeftSideSelectedImprovement