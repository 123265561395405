import React, { useReducer } from "react";
import { ITrackerAction, ITrackingTypeState, TrackingContextModal } from "../interfaces/trakingModel";
import { OutcomeStatus } from "../utils/staticDataJson";
import moment from "moment";
import { IIntegrationAction, IIntegrationTypeState, IntegrationContextModal } from "../interfaces/integrations";

const defaultState: IIntegrationTypeState = {
    connectionName: null,
    isConnecting: false,
    showSuccessModal: false,
    isEditMode: false
};

const reducer = (
    state: IIntegrationTypeState,
    action: IIntegrationAction
): IIntegrationTypeState => {
    switch (action.type) {
        case "SET_CONNECTINO_NAME":
            return {
                ...state,
                connectionName: action.payload,
            };
        case "SET_IS_CONNECTING":
            return {
                ...state,
                isConnecting: action.payload,
            };
        case "SET_SHOW_SUCCESS_MODAL":
            return {
                ...state,
                showSuccessModal: action.payload,
            };
        case "SET_EDIT_MODE":
            return {
                ...state,
                isEditMode: action.payload,
            };
        default:
            return state;
    }
};

export const IntegrationContext = React.createContext({} as IntegrationContextModal);
type childernType = {
    children: React.ReactNode;
};
export const Provider: React.FC = (props: childernType) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    return (
        <IntegrationContext.Provider value={{ state, dispatch }}>
            {props.children}
        </IntegrationContext.Provider>
    );
};
