import {
  Box,
  styled,
  TextField,
  Typography,
  Button as Buttons,
} from "@mui/material";
import { Button, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import BackIcon from "../../assets/images/icon/BackIcon";
import palette from "../../theme/palette";
import { useNavigate } from "react-router-dom";
import ArrowNextIconWhite from "../../assets/images/icon/arrow_next_white";
import Search from "../../assets/images/icon/Search";
import Mail from "../../assets/images/icon/Mail";
import ArchiveBox from "../../assets/images/icon/ArchiveBox";
import Plan from "../../assets/images/icon/Plan";
import Holiday from "../../assets/images/icon/Holiday";
import { UserTypeContext } from "../../context/userStatus";
import moment from "moment";
import WaitincClockIcon from "../../assets/images/icon/WaitincClockIcon";
import { ReportsTypeContext } from "../../context/userIReports";
import { setWeekReport } from "../../store/actions/reportTypeAction";
import typography, { pxToRem } from "../../theme/typography";
import HTMLTooltip from "../../components/HTMLTooltip";
const SearchFram = require("../../assets/images/Frame.png");
// const Mail = require("../../assets/images/mail.png");
const Matrics = require("../../assets/images/matrics.png");
const Weekly = require("../../assets/images/weekly.png");
const FlightMode = require("../../assets/images/flightmode.png");

const RootStyle = styled("div")(({ theme }) => ({
  padding: "0!important",
  display: "flex",
  alignItems: 'flex-start',
  flexDirection: 'column',
  background: '#FFF',
  borderRadius: "20px 20px 0 0",
  position: "relative",
  // top: 0,
}));
const RootStyle3 = styled("div")(({ theme }) => ({
  padding: "26px 26px 0px 26px!important",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: 'row',
  flexWrap: 'nowrap',

}));
const RootStyle2 = styled("div")(({ theme }) => ({
  padding: "24px!important",
  display: "flex",
  alignItems: 'flex-start',
  flexDirection: 'column',
  borderBottom: '1px solid #F3F3F3',
  background: '#FFF',
  borderRadius: "20px 20px 0 0",
  position: "relative",
  top: 0,
  width: '100%'
}));

// const SaveGoalBtn = styled(Button)(({ theme }) => ({
//   [theme.breakpoints.down("xl")]: {
//     marginBottom: "0px!important",
//   },
//   [theme.breakpoints.up("xl")]: {
//     marginBottom: "0px!important",
//   },
// }));

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    color: palette.primary.light,
    // paddingBottom: "12px",
  },
  pageSubtitle: {
    color: `${palette.secondary.contrast}!important`,
    fontSize: "20px!important",
    lineHeight: "30px!important",
    overflow: 'hidden',
    display: '-webkit-box',
    lineClamp: 2,
    boxOrient: 'vertical',
  },
  try: {
    color: palette.primary.light,
    lineHeight: "43.2px!important",
  },
  subHeading: {
    color: `${palette.secondary.contrast}!important`,
    fontSize: "16px !important",
    fontWeight: 300,
  },
  journyContainer: {
    display: 'flex',
    padding: '8px 12px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '6px',
    left: '24px',
    top: '-14px',
    borderRadius: '8px',
    background: 'var(--color-new-main)',
    width: "max-content",
    zIndex: 199

  },
  iconClass: {
    position: 'absolute',
    height: '30px',
    left: '-13px',
    top: '-4.8px',
    transform: 'skew(15deg)'
  },
  textClass: {
    /* gap: 4px; */
    /* top: 13px; */
    /* left: 23px; */
    /* width: 188px; */
    height: '12px',
    display: 'flex',
    gap: "6px",
    /* padding: 0px; */
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'row',
    // transform: 'skew(15deg)'

  },
  boxClass: {
    position: 'absolute',
    height: '28px',
    left: '5px',
    top: '5px',

    background: 'linear-gradient(90deg, #3AB682 22.58%, #83E292 132.26%)',
    border: '1px solid #000000',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  timeTracker: {
    height: '28px',
    position: 'relative',
    transform: 'skew(-15deg)',
    background: 'linear-gradient(to right, #3AB682 , #83E292)',
    borderRadius: '5px',
    border: '2px solid black',
    fontSize: '14px',
    color: '#fff',
    alignContent: 'center',
    justifyContent: 'center',
    padding: '0px 24px',
    display: 'inline-grid',
  }
}));
const SaveGoalBtn = styled(Button)(({ theme }) => ({
  background: "rgba(78, 87, 83, 0.8)",
  borderRadius: "4px",
  padding: "8px !important",
  height: "auto !important",
  color: "#FFFFFF",
  fontWeight: "400",
  fontSize: "16px",
  textTransform: "inherit",
  lineHeight: "16px",
  boxShadow: "none",
  fontFamily: typography.fontFamily,
  letterSpacing: "0px",
  "&:hover": {
    background: "rgba(78, 87, 83, 0.8)",
    boxShadow: "none",
  },
  [theme.breakpoints.down("xl")]: {
    marginBottom: "0px!important",
  },
  [theme.breakpoints.up("xl")]: {
    marginBottom: "0px!important",
  },
}));

const TrackTitle = (props: any) => {
  const classes = useStyles();
  const { state: outcomeState } = useContext(UserTypeContext);
  const { state: IReportTypeContext, dispatch: reportTypeDispatch } =
    useContext(ReportsTypeContext);
  const [trackQuoteData, setTrackQuoteData] = useState('Motivation is what gets you started. Habit is what keeps you going.')
  const [days, setDays] = useState(0)
  const navigate = useNavigate();
  useEffect(() => {
    if (outcomeState?.journeyList && outcomeState?.journeyList?.trackQuoteData &&
      outcomeState?.journeyList?.trackQuoteData[0] && outcomeState?.journeyList?.trackQuoteData[0]?.title) {
      setTrackQuoteData(outcomeState?.journeyList?.trackQuoteData[0]?.title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outcomeState?.journeyList?.trackQuoteData]);
  useEffect(() => {
    if(props.createDate) {
      let days = 0
      const diff = moment().diff(moment(props.createDate), 'days')
      if (diff !== 0 && diff < 30) {
        days = 30 - diff
      } else if(diff === 0) {
        days = 30 - diff
      } else {
        setDays(0)
      }
      setDays(days)
    }

  }, [props.createDate])
  const gotoGoals = () => {
    navigate("/test-goals");
  };
  const gotoWooklyProgress = () => {
    reportTypeDispatch(setWeekReport(true))
    navigate("/progress/weekly");
  };
  return (
    <RootStyle >
       
        {days && days > 0 ?(
          <HTMLTooltip customIcon={<div className={classes.journyContainer}>
            <span className={classes.textClass}>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                  <path d="M6 3.5V6H8.5M10.5018 2.28558L8.96973 1M3.03209 1L1.5 2.28558M6 10C3.79086 10 2 8.20914 2 6C2 3.79086 3.79086 2 6 2C8.20914 2 10 3.79086 10 6C10 8.20914 8.20914 10 6 10Z" stroke="var(--color-black)" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              <Typography variant="h3" component="span" lineHeight="100% !important" sx={{ width: "100%", textAlign: "center", color: "var(--color-black)", fontSize: `${pxToRem(12)} !important`, fontWeight: 400 }}>
                {days} days before the growth phase

              </Typography>
            </span>
          </div>} title="Without this baseline, any insights would be based on too little data, making them unreliable. By collecting 30 data points, you're ensuring that the insights you receive are statistically valid." fontProps={{
            fontSize: `${pxToRem(12)} !important`,
            lineHeight: '145%'
          }} sx={{
            '& .MuiTooltip-tooltip': {
              maxWidth: "300px"
            }
          }} />


        ) : null}

    </RootStyle>
  );
};

export const TrackArchiveTitle = (props: any) => {
  const classes = useStyles();
  const navigate = useNavigate()
  return (
    <RootStyle3 >
      <div>
        <Typography variant="h6" component="h6" className={classes.subHeading}>
          <span
            style={{ color: "#3E4240", marginRight: "30px", cursor: "pointer" }}
            onClick={() => navigate('/tracking', {
              state: {
                direct: true
              }
            })}
          >
            <BackIcon /> Back
          </span>{" "}
          {/* Tracking / Metric Detail /{" "}
          <span style={{ color: "#3E4240" }}>Archived metrics</span> */}
        </Typography>
        <Typography variant="h1" component="h1" className={classes.try}>
          Archived metrics
        </Typography>
        {/* <div >
          <BackIcon />
        </div>
        <Typography variant="h1" component="h1" className={classes.pageTitle} sx={{ marginLeft: "20px", paddingBottom: '0px' }}>
          Archived metrics
        </Typography> */}
      </div>
    </RootStyle3>
  );
};

export default TrackTitle;
