import React, { useContext } from "react"
import SuccessDialogue from "./successDialogue"
import { IntegrationContext } from "../../../context/userIntegrations"
import useWidth from "../../../utils/useWidth"
import { setConnectionName, setIsConnecting, setShowModal } from "../../../store/actions/integrationActions"

const SuccessDialogueGlobal = () => {
    const { state, dispatch } = useContext(IntegrationContext)
    const width = useWidth();
    const onClose = () => {
        dispatch(setShowModal(false))
        dispatch(setIsConnecting(false))
        dispatch(setConnectionName(null))
    }

    const setModalType = () => {

    }
    return <SuccessDialogue
        openModal={state.showSuccessModal}
        modalType={state.connectionName}
        isEditMode={state.isEditMode}
        isMobile={width < 768}
        isDelete={false}
        onClose={onClose}
        setModalType={setModalType}
    />
}

export default SuccessDialogueGlobal