/*eslint eqeqeq: "off"*/
import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  SvgIcon,
  Dialog,
  Popover,
  List,
  ListItem,
  ListItemText,
  Drawer,
  InputAdornment,
  Divider,
  FormControlLabel,
  Checkbox,
  TextareaAutosize,
  Box,
  ClickAwayListener,
} from "@mui/material";
import * as icons from "@fortawesome/free-solid-svg-icons";
import { styled } from "@mui/material/styles";
import { IconButton, makeStyles, Menu, MenuItem } from "@material-ui/core";
import palette from "../../theme/palette";
import GetIdeasIcon from "../../assets/images/icon/getIdeas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressBarStepper from "./progressBarStepper";
import {
  fixOutcomeColorPalette,
  fixOutcomeIcon,
  ProUserStepper,
  UserStepper,
} from "../../utils/staticDataJson";
import IconPickerDialog from "./iconPickerDialog";
import {
  OutComeColorModal,
  OutcomeGetResponse,
  OutComeIconModal,
} from "../../interfaces/outcomeModal";
import ColorPickerDialog from "./colorPickerDialog";
import BottomStepper from "./BottomStepper";
import clsx from "clsx";
import IdeasDrawer from "./ideasDrawer";
import shape from "../../theme/shape";
import { outcomeService } from "../../services/outcome";
import SnackBarComp from "../../components/snackBar";
import { ButtonLoader } from "../../components/buttonLoader";
import { UserTypeContext } from "../../context/userStatus";
import {
  addCategoryJourneyAction,
  addJourneyAction,
  addNotesAction,
  addNotesJourneyAction,
  addOutcomeAction,
  setUserStatus,
} from "../../store/actions/userTypeAction";
import moment from "moment";
import { trackingService } from "../../services/tracking";
import Donts from "../../assets/images/icon/Dots";
import { userService } from "../../services/auth";
import { setTimeout } from "timers/promises";
import { useNavigate } from "react-router-dom";
import SwitchInfluenceModal from "../tracker/switchInfluenceModal";
import useWidth from "../../utils/useWidth";
import _debounce from "lodash/debounce";
import ViewNote from "../../assets/images/icon/ViewNote";
import Wellbeing from "./Wellbeing";
import StepperBackArrowDisable from "../../assets/images/icon/stepperBackArrowDisable";
import StepperNextArrow from "../../assets/images/icon/stepperNextArrow";
import StepperBackArrow from "../../assets/images/icon/stepperBackArrow";
import StepperNextArrowDisable from "../../assets/images/icon/stepperNextArrowDisable";
import ProgressBarStepperNew from "./progressBarStepperNew";
import RightSideOutcomeTrackingNew from "../tracker/rightSideOutcomeTrackingNew";
import ArchiveModal from "../tracker/archiveConfirmationModal";
import Search from "../../assets/images/icon/Search";
import { useFontAwesomeIconPack } from "./useFontAwesomeIconPack";
import typography, { pxToRem } from "../../theme/typography";
import HTMLTooltip from "../../components/HTMLTooltip";
import ArchiveIcon from "../../assets/images/icon/ArchiveIcon";
import NewButton from "../../components/NewButton";
import Info from "../../assets/images/icon/Info";
import InfoIcon from "../../assets/images/icon/infoIconYellow";
import Star from "../../assets/images/icon/Star";
const RootStyle = styled("div")(({ theme }) => ({
  padding: "32px",
  display: "flex",
  position: "relative",
  flexDirection: "column",
  alignItems: "flex-start",
}));

const useStyles = makeStyles((theme) => ({
  registrationHeading: {
    color: palette.primary.light,
  },
  pageTitle: {
    marginTop: "40px!important",
    marginBottom: "16px!important",
    [theme.breakpoints.down("lg")]: {
      marginTop: "30px!important",
      // marginBottom: "38px!important",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "20px!important",
      // marginBottom: "28px!important",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px!important",
      // marginBottom: "20px!important",
    },
  },
  needSomeIdeas: {
    fontSize: "16px",
    lineHeight: "16px",
    color: palette.primary.main,
    marginLeft: "8px",
    [theme.breakpoints.down("lg")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "14px",
    },
  },
  colorBlock: {
    flexBasis: "50%",
    cursor: "pointer",
    backgroundColor: "transparent",
    border: "1px solid #DCE6E0",
    borderRadius: shape.borderRadius,
    padding: "11px 16px",
    display: "flex",
    alignItems: "center",
    marginLeft: "16px",
    justifyContent: "space-between",
    [theme.breakpoints.down("lg")]: {
      marginLeft: 0,
    },
  },
  spaceBetween: {
    // [theme.breakpoints.down("lg")]: {
    //   marginTop: "10px",
    //   marginBottom: "10px",
    // },
  },
  widthFull: {
    width: "100%",
  },
  colorBlockText: {
    margin: 0,
    color: palette.common.black,
    fontSize: "16px",
    lineHeight: "16px",
    fontWeight: 400,
  },
  colorCode: {
    width: "24px",
    height: "24px",
    marginLeft: "10px",
    borderRadius: shape.borderRadiusSm,
  },
  outcomeTitle: {
    marginTop: "48px!important",
    marginBottom: "20px!important",
    [theme.breakpoints.down("lg")]: {
      marginTop: "40px!important",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "30px!important",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px!important",
    },
  },
  ideasListItem: {
    backgroundColor: palette.common.white,
    borderRadius: shape.borderRadius,
    padding: "24px",
    fontSize: "16px",
    lineHeight: "16px",
    color: palette.primary.light,
    fontWeight: 400,
    marginBottom: "12px",
    cursor: "pointer",
  },
  popoverTitleGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: "12px",
    alignSelf: "stretch",
    justifyContent: "space-between",
  },
  drawerTitle: {
    display: "flex",
    padding: "20px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    alignSelf: "stretch",
    borderBottom: "1px solid #F3F3F3",
    background: "#FFF",
  },
  drawerBody: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  headerTitle: {
    display: "flex",
    padding: "24px 32px",
    alignItems: "center",
    alignSelf: "stretch",
    borderBottom: "1px solid #F3F3F3",
    background: "#FFF",
    justifyContent: "space-between",
  },
  iconRound: {
    display: "flex",
    // width: '44px',
    // height: '44px',
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "100px",
    background: "#FFF",
    "& path": {
      stroke: "var(--color-new-main)",
      strokeWidth: 2,
      //      fill: theme.palette.secondary[50],
    },
  },
  textarea: {
    width: "100%",
    padding: "20px",
    fontFamily: typography.fontFamily,
    fontSize: "16px",
    fontWeight: 400,
    "&:focus-visible": {
      outline: "red",
    },
  },
}));

const ariaLabel = { "aria-label": "description" };

interface LeftSideOutComeModal {
  outComeValue: any;
  setOutcomeValue: (outComeValue: string) => void;
  outcomeIdeas: any;
  outcomeData?: any;
  setShowOutcome: (showOutcome: boolean) => void;
  showOutcome: boolean;
  setAddOutcomeTracking: (addOutcomeTracking: boolean) => void;
  setTextInput: any;
  textInput: any;
  ispersonalMode?: boolean;
  handleCloseStartModal?: any;
  getOutcomeList?: any;
  categoryId?: any;
  setTransformToInfluenceTracking?: any;
  width?: any;
  setShowSnakbar?: any;
  setSnakbarMsg?: any;
  setMsgType?: any;
  startDate?: any;
  endDate?: any;
  setNeedIdeasOpen: any;
  setLimitReachPopupVisible: any;
  setLimitReachPopuptype: any;
  isMobile?: boolean;
}

const LeftSideOutComeNew = (props: LeftSideOutComeModal) => {
  const classes = useStyles();
  const [openIcon, setOpenIcon] = useState(false);
  const [openWellbeing, setopenWellbeing] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState<any>(fixOutcomeIcon[0].path);
  const [openColor, setOpenColor] = useState(false);
  const [outcomeDesc, setOutcomeDesc] = useState("");
  const [selectedColor, setSelectedColor] = useState<any>(
    fixOutcomeColorPalette[0]
  );
  const [outcomeValueInput, setOutComeValueInput] = useState("");
  const [absenceToggle, setAbsenceToggle] = useState(false);
  const [skipMetric, setSkipMetric] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [editStatus, setEditStatus] = useState(null);
  const [openRightSideSuggestion, setOpenRightSideSuggestion] = useState(false);
  // const [showSnakbar, setShowSnakbar] = useState(false);
  // const [snakbarMsg, setSnakbarMsg] = useState("");
  // const [msgType, setMsgType] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showIdeasMobile, setShowIdeasMobile] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [openTooltip, setOpenTooltip] = useState(true)
  const [openSwitchInfluenceModal, setopenSwitchInfluenceModal] =
    useState(false);

  const [archiveConfirmationModal, setArchiveConfirmationModal] =
    useState(false);
  // const { state: IUserTypeState, } =
  //   useContext(UserTypeContext);
  const userLevels = JSON.parse(localStorage.getItem("userValues"));
  const [activeStep, setActiveStep] = React.useState(false ? 2 : false ? 1 : 0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };
  const userType = userLevels?.level == "HIGH" ? ProUserStepper : UserStepper;
  const {
    state: outcomeState,
    state: IUserTypeState,
    dispatch: userTypeDispatch,
  } = useContext(UserTypeContext);
  const compareDate = parseInt(moment().format("D"));
  const startDay = props.startDate
    ? moment(props.startDate)
    : moment().clone().subtract(10, "day");
  const endDay = props.endDate
    ? moment(props.endDate)
    : moment().clone().add(10, "day");
  const navigate = useNavigate();

  const [suggestions, setSuggestions] = useState([]);
  const [viewPopover, setVisiblePopover] = useState(false);
  const spanRef = React.useRef();
  const [anchorEl, setAnchorEl] = useState(spanRef.current);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(spanRef.current);
    setVisiblePopover(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setVisiblePopover(false);
  };

  const [viewIconPopoverMenu, setVisibleIconPopoverMenu] = useState(false);
  const spanRefMenu = React.useRef();
  const [anchorElMenu, setAnchorElMenu] = useState(spanRefMenu.current);
  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMenu(spanRefMenu.current);
    setVisibleIconPopoverMenu(true);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
    setVisibleIconPopoverMenu(false);
  };

  const [viewIconPopover, setVisibleIconPopover] = useState(false);
  const spanIconRef = React.useRef();
  const [anchorIconEl, setAnchorIconEl] = useState(spanIconRef.current);
  const handleIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorIconEl(spanIconRef.current);
    setVisibleIconPopover(true);
  };
  const handleIconClose = () => {
    setAnchorIconEl(null);
    setVisibleIconPopover(false);
  };
  useEffect(() => {
    return () => setOpenTooltip(false)
  }, [])
  const handleChangeWithLib = (value) => {
    let bodyData = {
      skip: 0,
      limit: 20,
      search: value,
    };
    trackingService
      .outcomeSearch(outcomeState.token, bodyData)
      .then((res) => setSuggestions(res.data));
  };

  const debounceFn = useCallback(_debounce(handleChangeWithLib, 500), []);

  const NativeOutcomeStatus = [
    { text: "very low" },
    { text: "low" },
    { text: "medium" },
    { text: "high" },
    { text: "very high" },
  ];

  const NativeOutcomeStatusAbsense = [
    { text: "Absence" },
    { text: "very low" },
    { text: "low" },
    { text: "medium" },
    { text: "high" },
    { text: "very high" },
  ];

  const handleCloseStartModal = () => {
    setShowIdeasMobile(false);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (userLevels?.level == "HIGH") {
      if (activeStep != userType.length - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    let nextStep = activeStep + 1;
    setSkipped(newSkipped);
    handleSliderNextValue(nextStep);
  };

  const handleSliderNextValue = (step: number) => {
    if (userLevels?.level == "HIGH") {
      if (step == 3) {
        userTypeDispatch(setUserStatus(true));
        navigate("/tracking", { replace: true });
      }
      if (step == 2) {
        navigate("/reflective-questions", { replace: true });
      } else if (step == 1) {
        navigate("/influence", { replace: true });
      }
    } else {
      if (step == 1) {
        navigate("/influence", { replace: true });
      }
      if (step == 2) {
        userTypeDispatch(setUserStatus(true));
        navigate("/tracking", { replace: true });
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    const backStep = activeStep - 1;
    handleSliderValue(backStep);
  };

  const handleSliderValue = (step: number) => {
    if (userLevels?.level == "HIGH") {
      if (step == 1) {
        navigate("/influence", { replace: true });
      } else if (step == 0) {
        navigate("/outcome", { replace: true });
      }
    } else {
      if (step === 0) {
        navigate("/outcome", { replace: true });
      }
    }
  };
  const [iconPack, setIconPack] = useState([]);
  useEffect(() => {
    const copyIcon = [];
    Object.keys(icons).map((icon) => {
      copyIcon.push(icons[icon]);
      return icon;
    });
    setIconPack(copyIcon);
    setOpenDrawer(false);
    setOutComeValueInput(props.outComeValue?.name);
    props?.setTextInput(
      props.outComeValue?.options?.length > 0
        ? props.outComeValue?.options
        : NativeOutcomeStatus
    );
    setAbsenceToggle(props.outComeValue?.absenceToggle);
    setSkipMetric(props.outComeValue?.skipMetric);
    setToogleSwitchAction(props.outComeValue?.absenceToggle);
    // if(props.outComeValue?.absenceToggle) {
    //   props?.setTextInput(
    //     props.outComeValue?.options?.length > 0
    //       ? props.outComeValue?.options?.length > 5 ? [...props.outComeValue?.options] : [{
    //         text: "Absence"
    //       }, ...props.outComeValue?.options]
    //       : NativeOutcomeStatusAbsense
    //   );
    // } else {
    //   props?.setTextInput(
    //     props.outComeValue?.options?.length > 0
    //       ? [...props.outComeValue?.options]
    //       : NativeOutcomeStatus
    //   );
    // }
    setSelectedColor(
      props.outComeValue?.options
        ? props.outComeValue?.options
        : fixOutcomeColorPalette[0]
    );
    setOutcomeDesc(props?.outComeValue?.description);
    setSelectedIcon(
      props.outComeValue?.icon
        ? props.outComeValue?.icon
        : fixOutcomeIcon[0].path
    );
    return () => setIconPack([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.outComeValue]);

  const handleClickOpenIcon = () => {
    setOpenIcon(true);
  };
  const callService = async () => {
    try {
      const bodyData = {
        from: moment(startDay).format("MM-DD-YYYY"),
        to: moment(endDay).format("MM-DD-YYYY"),
      };
      if (!props?.ispersonalMode) {
        let data = await trackingService.getJourneyList(
          outcomeState.token,
          bodyData
        );
        userTypeDispatch(addJourneyAction(data));
        userTypeDispatch(addNotesAction(data));
      } else {
        let data = await trackingService.getPersonalJourneyList(
          outcomeState.token,
          bodyData
        );
        userTypeDispatch(addCategoryJourneyAction(data?.data));
        userTypeDispatch(addNotesJourneyAction(data?.data));
      }
    } catch (error) {
      console.log("jourey list error");
    }
  };
  const handleCloseIcon = (value) => {
    console.log("value", value)
    setOpenIcon(false);
    if (value.id === -1) {
      return;
    }
    if (value.path) {
      setSelectedIcon(value.path);
    } else {
      setSelectedIcon(value.iconName);
    }
    handleIconClose();
  };
  const handleClickOpenColor = () => {
    setOpenColor(true);
  };

  const handleCloseColor = (value: any) => {
    setOpenColor(false);

    if (value.id === -1) {
      return;
    }
    handleClose();
    setSelectedColor(value);
  };

  const handleChangeOutcome = (e: any) => {
    setOutComeValueInput(e.target.value);
    debounceFn(e.target.value);
  };
  const addOutCome = async (values: string) => {
    setShowLoader(true);
    var result: any = selectedColor;
    if (props.outComeValue?.uid && selectedColor.length > 0) {
      result = selectedColor?.map((color: any, index: number) => ({
        text: props?.textInput[index]?.text,
        colorCode: color.colorCode,
        answerId: color?.answerId,
        score: color?.score,
      }));
    } else {
      if (selectedColor?.color) {
        result = selectedColor?.color?.map((color: any, index: number) => ({
          text: props?.textInput[index]?.text,
          colorCode: color.colorCode ? color.colorCode : color,
          answerId:
            props?.outComeValue && props?.outComeValue?.options[index] &&
            props?.outComeValue?.options[index]?.answerId,
          score: color?.score,
        }));
      } else {
        result = selectedColor?.map((color: any, index: number) => ({
          text: props?.textInput[index]?.text,
          colorCode: color.colorCode ? color.colorCode : color,
          answerId:
            props?.outComeValue &&
            props?.outComeValue?.options[index]?.answerId,
          score: color?.score,
        }));
      }
    }
    result = result.filter((it) => it);
    let outcomeRes;
    if (props.outComeValue?.uid) {
      outcomeRes = {
        name: values,
        description: outcomeDesc,
        options: result ? result : selectedColor,
        icon: selectedIcon,
        outcomeId: props.outComeValue.outcomeId,
        absenceToggle: absenceToggle,
        skipMetric: skipMetric,
      };
    } else {
      outcomeRes = {
        name: values,
        description: outcomeDesc,
        options: result ? result : selectedColor,
        icon: selectedIcon,
        categoryId: props?.categoryId,
        mixpanel_type: "INSIDE_TRACKING",
        absenceToggle: absenceToggle,
        skipMetric: skipMetric,
      };
    }
    // EDIT COUTCOME
    if (props.outComeValue?.uid) {
      outcomeService
        .EditOutcome(outcomeRes, outcomeState.token)
        .then((response: any) => {
          setShowLoader(false);
          props?.setShowSnakbar(false);
          props?.setSnakbarMsg(response.message);
          props?.setMsgType("success");
          if (IUserTypeState.isPersonal) {
            const journeyList = [...outcomeState?.journeyCategoryList];
            const foudnInJourney = journeyList.findIndex((it) =>
              it.outcomeIds.includes(props.outComeValue.outcomeId)
            );
            if (foudnInJourney > -1) {
              const foundInOutcomes = journeyList[
                foudnInJourney
              ].alls.findIndex(
                (it) => it.outcomeId === props.outComeValue.outcomeId
              );
              if (foundInOutcomes > -1) {
                journeyList[foudnInJourney].alls[foundInOutcomes] = {
                  ...journeyList[foudnInJourney].alls[foundInOutcomes],
                  ...outcomeRes,
                };
                userTypeDispatch(addCategoryJourneyAction(journeyList));
              }
            } else {
              const foudnOtherJourney = journeyList.findIndex(
                (it) => it.name === "Other"
              );
              if (foudnOtherJourney > -1) {
                const foundInOutcomes = journeyList[
                  foudnOtherJourney
                ].outcomes.findIndex(
                  (it) => it.outcomeId === props.outComeValue.outcomeId
                );
                if (foundInOutcomes > -1) {
                  journeyList[foudnOtherJourney].outcomes[foundInOutcomes] = {
                    ...journeyList[foudnOtherJourney].outcomes[foundInOutcomes],
                    ...outcomeRes,
                  };
                  userTypeDispatch(addCategoryJourneyAction(journeyList));
                }
              }
            }
          } else {
            const allOutcomes = [...outcomeState?.outcomeList];
            const foundOutcomeIndex = allOutcomes.findIndex(
              (it) => it.outcomeId === props.outComeValue.outcomeId
            );
            if (foundOutcomeIndex > -1) {
              allOutcomes[foundOutcomeIndex] = {
                ...allOutcomes[foundOutcomeIndex],
                ...outcomeRes,
              };
              userTypeDispatch(addOutcomeAction(allOutcomes));
            }
          }
          if (props?.isMobile) {
            props?.handleCloseStartModal();
          }
          props.setShowOutcome(false);
          setOutComeValueInput("");
          props.setOutcomeValue("");
          setOutcomeDesc("");
          props?.setTextInput(NativeOutcomeStatus);
        })
        .catch((error) => {
          setShowLoader(false);
          props?.setShowSnakbar(true);
          props?.setSnakbarMsg(
            error?.response?.data?.description
              ? error?.response?.data?.description
              : error.message
          );
          props?.setMsgType("error");
        });
    } else {
      // ADD OUTCOME
      outcomeService
        .createOutcome(outcomeRes, outcomeState.token)
        .then((response: any) => {
          outcomeState.outcomeList.push(response?.data);
          const bodyData = {
            from: moment(startDay).format("MM-DD-YYYY"),
            to: moment(endDay).format("MM-DD-YYYY"),
          };
          trackingService
            .getPersonalJourneyList(outcomeState.token, bodyData)
            .then((data) => {
              userTypeDispatch(addCategoryJourneyAction(data?.data));
              userTypeDispatch(addOutcomeAction(outcomeState.outcomeList));
              // props.setAddOutcomeTracking(false);
              // let days = 0;
              // const createDate = outcomeState.userDetails.createdAt;
              // const diff = moment().diff(moment(createDate), "days");
              // if (diff !== 0 && diff < 30) {
              //   days = 30 - diff;
              // } else if (diff === 0) {
              //   days = 30 - diff;
              // }
              // if (
              //   days > 0 &&
              //   outcomeState.outcomeList &&
              //   outcomeState.outcomeList.length >= 5 &&
              //   outcomeState.userDetails.level === "LOW"
              // ) {
              //   props?.setLimitReachPopupVisible(true);
              //   props?.setLimitReachPopuptype("outcome");
              // }
              setOutComeValueInput("");
              props.setOutcomeValue("");
              setOutcomeDesc("");
              props?.setTextInput(NativeOutcomeStatus);
              setShowLoader(false);
              props?.setShowSnakbar(true);
              props?.setSnakbarMsg(response.message);
              props?.setMsgType("success");
            });
        })
        .catch((error) => {
          setShowLoader(false);
          props?.setShowSnakbar(true);
          props?.setSnakbarMsg(
            error?.response?.data?.description
              ? error?.response?.data?.description
              : error.message
          );
          props?.setMsgType("error");
        });
    }
  };
  const iconisDefault = selectedIcon === fixOutcomeIcon[0].path
  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const ITEM_HEIGHT = 48;

  const onArchiveOutcome = () => {
    const token = localStorage.getItem("userDetails");
    userService
      ?.archiveOutComeData(
        {
          archive: true,
          outcomeId: props?.outComeValue?.outcomeId,
        },
        token
      )
      .then((res) => {
        props?.setShowSnakbar(true);
        props?.setSnakbarMsg(res.message);
        props?.setMsgType("success");
        // setShowOutcome(false);
        // setAddOutcomeTracking(true);
        window.setTimeout(() => {
          props.handleCloseStartModal();
          props.getOutcomeList();
        }, 1000);
      })
      .catch((error) => {
        props?.setShowSnakbar(true);
        props?.setSnakbarMsg(
          error?.response?.data?.description
            ? error?.response?.data?.description
            : error.message
        );
        props?.setMsgType("error");
      });
  };
  const setToogleSwitchAction = (checked) => {
    // if (checked) {
    //   props?.setTextInput(
    //     props.outComeValue?.options?.length > 0
    //       ? props.outComeValue?.options?.length > 5 ? [...props.outComeValue?.options] : [{
    //         text: "Absence"
    //       }, ...props.outComeValue?.options]
    //       : NativeOutcomeStatusAbsense
    //   );
    // } else {
    //   props?.setTextInput(
    //     props.outComeValue?.options?.length > 0
    //       ? [...props.outComeValue?.options]
    //       : NativeOutcomeStatus
    //   );
    // }
  };
  const width = useWidth();
  const open = Boolean(anchorEl);
  const renderIcon = (icon, onClick = () => { }) => (
    <div className={classes.iconRound} onClick={onClick}>
      {icon}
    </div>
  );
  const iconsFiltered = iconPack.filter((icon) => {
    if (searchText && icon && icon.iconName) {
      return (
        icon.iconName.includes(searchText.toLowerCase()) &&
        !["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(
          icon.iconName
        )
      );
    }
    return !["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(
      icon.iconName
    );
  });
  let foundIcon = null;
  if (selectedIcon.length < 50) {
    const found = iconsFiltered.find((it) => it.iconName === selectedIcon);
    if (found) {
      foundIcon = found;
    }
  }
  console.log(
    "outcomeValueInput ==  || outcomeValueInput == undefined",
    outcomeValueInput
  );
  return (
    <Grid
      container
      columns={16}
      sx={{ backgroundColor: palette.secondary.light }}
    >
      <Grid
        sx={{ backgroundColor: palette.common.white }}
        item
        xs={16}
        className="leftside-menu outcome-screen "
      >
        {!props?.isMobile && (
          <div className={classes.drawerBody}>
            <div className={classes.headerTitle}>
              <Typography
                sx={{
                  color: "#121213",
                  fontSize: "28px!important",
                  fontWeight: 700,
                }}
              >
                {props.outComeValue?.uid ? "Edit outcome" : "Add new outcome"}
              </Typography>
              {!openRightSideSuggestion && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "6px",
                    cursor: "pointer",
                  }}
                >
                  {props.outComeValue?.uid ? null : (
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      onClick={() => {
                        setOpenRightSideSuggestion(true);
                        props.setNeedIdeasOpen && props.setNeedIdeasOpen(true);
                      }}
                    >
                      <GetIdeasIcon />
                      <Typography
                        sx={{
                          color: "var(--color-new-text)",
                          fontSize: "16px!important",
                          fontWeight: 500,
                        }}
                      >
                        Need some ideas?
                      </Typography>
                    </div>
                  )}

                  {props.outComeValue?.uid && (
                    <div>
                      <IconButton
                        ref={spanRefMenu}
                        onClick={() => {
                          setopenWellbeing(true);
                        }}
                      >
                        <ViewNote />
                      </IconButton>
                      <IconButton
                        onClick={() => setArchiveConfirmationModal(true)}
                      >
                        <HTMLTooltip
                          customIcon={renderIcon(<ArchiveIcon />, () => { })}
                          title="Archive"
                          fontProps={{
                            fontSize: `${pxToRem(12)} !important`,
                          }}
                          sx={{
                            "& .MuiTooltip-tooltip": {
                              maxWidth: "300px",
                            },
                          }}
                        />
                      </IconButton>
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={
                          viewIconPopoverMenu ? "long-menu" : undefined
                        }
                        aria-expanded={viewIconPopoverMenu ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleClickMenu}
                      >
                        <Donts />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          "aria-labelledby": "long-button",
                        }}
                        anchorEl={anchorElMenu}
                        open={viewIconPopoverMenu}
                        onClose={handleCloseMenu}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: "20ch",
                          },
                        }}
                      >
                        <MenuItem
                          onClick={() => setopenSwitchInfluenceModal(true)}
                        >
                          Transform to influence
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            navigate(
                              `/version?id=${props?.outComeValue?.outcomeParentId ??
                              props?.outComeValue?.influenceParentId
                              }`
                            )
                          }
                        >
                          Version history
                        </MenuItem>
                      </Menu>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        <RootStyle
          style={{
            width: `${openRightSideSuggestion ? "calc(100% - 280px)" : "100%"}`,
          }}
        >
          <Grid
            display={"flex"}
            flexDirection={"column"}
            gap={"16px"}
            paddingBottom={0}
            alignItems={"flex-start"}
            width={"100%"}
            sx={{
              borderBottom: "1px solid #F3F3F3",
              // position: "relative"
            }}
          >
            <Typography
              variant="caption"
              component="div"
              sx={{
                color: palette.common.black,
                fontSize: "16px!important",
                fontWight: 500,
                width: "100%",
                lineHeight: props?.isMobile ? "135%" : "100%",
                "&.MuiTypography-root": {
                  "@media  (max-width: 786px) and (min-width: 100px)": {
                    fontSize: `${pxToRem(16)} !important`,
                    lineHeight: "135% !important",
                  },
                },
              }}
            >
              Which outcomes do you care about and want to understand better?
            </Typography>
            {props?.isMobile && props.outComeValue?.uid ? (
              <IconButton
                style={{
                  position: "absolute",
                  top: "8px",
                  right: "30px",
                  padding: "0px",
                }}
                onClick={() => setArchiveConfirmationModal(true)}
              >
                <HTMLTooltip
                  customIcon={renderIcon(<ArchiveIcon />, () => { })}
                  title="Archive"
                  fontProps={{
                    fontSize: `${pxToRem(12)} !important`,
                  }}
                  sx={{
                    "& .MuiTooltip-tooltip": {
                      maxWidth: "300px",
                    },
                  }}
                />
              </IconButton>
            ) : null}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                width: "100%",
                paddingBottom: "8px",
              }}
            >
              <TextField
                sx={{
                  "& .MuiTextField-root": {
                    marginBottom: "24px !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "12px",
                    border: "1px solid #EDEDED",
                    background: "#FFF",
                    "& .MuiOutlinedInput-input": props?.isMobile ? {
                      color: "#000!important",
                      border: "1px solid var(--color-svg)",
                      borderRadius: "8px",
                      "&::placeholder": {
                        fontSize: `${pxToRem(14)}`,
                        // color: "#B7BDB9"
                      }
                    } : {
                      color: "#000!important",
                    },
                  },
                }}
                placeholder="The outcome you care about"
                variant="outlined"
                fullWidth
                name="outcome"
                value={outcomeValueInput || ""}
                onChange={(e) => handleChangeOutcome(e)}
                inputProps={ariaLabel}
              />
              <div
                style={{
                  backgroundColor: props?.isMobile ? palette.common.white : "aliceblue",
                  borderRadius: props?.isMobile && outcomeValueInput?.length !== 0 && suggestions && suggestions.length > 0 ? "0px 0px 8px 8px" : "8px",
                  border: props?.isMobile && outcomeValueInput?.length !== 0 && suggestions && suggestions.length > 0 && "1px solid #DCE5E0",
                  fontSize: "16px",
                  maxHeight: "120px",
                  overflow: "auto",
                  position: "absolute",
                  width: "100%",
                  top: "55px",
                  zIndex: 10,
                  color: "black",
                }}
                className={props?.isMobile ? "hide-scrollbar" : ""}
              >
                {outcomeValueInput?.length !== 0 && suggestions && suggestions.length > 0 &&
                  suggestions?.map((suggestion) => {
                    let name = suggestion.name
                    if (props?.isMobile && outcomeValueInput?.length > 0 && name.includes(outcomeValueInput)) {
                      name = name.replace(outcomeValueInput, `<span class="suggested-text">${outcomeValueInput}</span>`)
                    }
                    if (props?.isMobile && outcomeValueInput?.length > 0 && name.toLowerCase().includes(outcomeValueInput.toLowerCase())) {
                      name = name.replace(outcomeValueInput.toLowerCase(), `<span class="suggested-text">${outcomeValueInput.toLowerCase()}</span>`)
                    }
                    delete suggestion._id
                    return (
                      <div
                        style={props?.isMobile ? {
                          height: "48px",
                          opacity: "0px",
                          display: "flex",
                          alignItems: "center",
                          padding: "8px",
                          border: "1px solid #F3F3F3",
                        } : { padding: "8px", cursor: "pointer" }}
                        onClick={() => {
                          props.setOutcomeValue(suggestion);
                          setSuggestions([]);
                        }}
                      >
                        <div dangerouslySetInnerHTML={{
                          __html: name
                        }} />
                      </div>
                    );
                  })}
              </div>
            </div>
          </Grid>
          <Grid
            display="flex"
            // alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            sx={{
              paddingTop: "24px",
              mb: "16px",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {width > 768 ? (
              <Typography variant="caption" component="div">
                Please, set colour pallet and icon
              </Typography>
            ) : (
              <div
                style={{
                  fontSize: "16px!important",
                  fontWeight: "500",
                  lineHeight: "20px",
                  color: "black",
                }}
              >
                Which outcomes do you care about and want to understand better?
              </div>
            )}
            {!props.showOutcome && (
              <Grid
                display="flex"
                alignItems="center"
                sx={{
                  cursor: "pointer",
                  marginTop: width > 768 ? "auto" : "10px",
                }}
                onClick={() => {
                  showDrawer();
                  setShowIdeasMobile(true);
                }}
              >
                <GetIdeasIcon />
                <span
                  className={classes.needSomeIdeas}
                  style={{ marginTop: width > 768 ? "10px" : "0px" }}
                >
                  Need some ideas?
                </span>
              </Grid>
            )}

            {/* RIGHT SIDE DRAWER */}
            {(!props.showOutcome || width > 768) && (
              <IdeasDrawer
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
                outcomeIdeas={props.outcomeIdeas}
                outcomeData={props.outcomeData}
                setOutcomeValue={props.setOutcomeValue}
              />
            )}
            {showIdeasMobile && width < 769 && (
              <Dialog
                fullScreen
                disableEscapeKeyDown
                onClose={handleCloseStartModal}
                open={showIdeasMobile}
                style={{
                  height: "60%",
                  bottom: 0,
                  top: "auto",
                  borderRadius: "35px 35px 0px 0px",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "#11734A",
                    padding: "20px",
                    paddingTop: "60px",
                    color: "#fff",
                    overflow: "auto",
                  }}
                >
                  <Grid
                    container
                    sx={{ backgroundColor: palette.primary.main }}
                  // className={classes.drawerWrapper}
                  >
                    <Grid>
                      <Typography
                        variant="h2"
                        component="h2"
                        sx={{
                          color: palette.common.white,
                          fontWeight: 500,
                          lineHeight: "35px!important",
                        }}
                      >
                        Our ideas of outcomes
                      </Typography>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{
                          color: palette.common.white,
                          fontWeight: 400,
                          lineHeight: "35px!important",
                        }}
                      >
                        Choose one of our recommended outcomes, and personalize
                        it
                      </Typography>
                    </Grid>
                    <Grid
                      // className={classes.ideasList}
                      sx={{ width: "100%", mt: "40px" }}
                    >
                      {props?.outcomeIdeas?.map((ideas: any) => {
                        return (
                          <div
                            className={classes.ideasListItem}
                            key={ideas._id}
                            onClick={() => {
                              props.setOutcomeValue(ideas);
                              setShowIdeasMobile(false);
                            }}
                          >
                            {ideas.name}
                          </div>
                        );
                      })}
                    </Grid>
                  </Grid>
                </div>
              </Dialog>
            )}
          </Grid>

          <Grid
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="nowrap"
            width={"100%"}
            gap={"12px"}
            sx={props?.isMobile ? {} : {
              paddingBottom: "24px",
              borderBottom: "1px solid #F3F3F3",
            }}
          >
            {
              <div
                className={clsx(
                  classes.colorBlock,
                  classes.spaceBetween,
                  classes.widthFull
                )}
                style={props?.isMobile ? {
                  border: "1px solid var(--color-svg)",
                } : {}}
                onClick={handleClick}
              >
                <p className={classes.colorBlockText}>{props?.isMobile ? "Color pallet" : "Set colour pallet"}</p>

                <span
                  className={classes.colorCode}
                  ref={spanRef}
                  style={{
                    backgroundColor:
                      props.outComeValue?.uid && selectedColor.length === 0
                        ? props.outComeValue?.options[0]?.colorCode
                        : selectedColor.length > 0
                          ? selectedColor[0]?.colorCode
                          : selectedColor?.color[0],
                  }}
                ></span>
              </div>
            }
            {/* CHOOSE COLOR PALETTSE */}
            {true && (
              <>
                <ColorPickerDialog
                  openColor={openColor}
                  onCloseColor={handleCloseColor}
                />
                <div
                  className={clsx(classes.colorBlock, classes.widthFull)}
                  onClick={handleIconClick}
                  style={props?.isMobile ? {
                    border: "1px solid var(--color-svg)",
                  } : {}}
                >
                  <p className={classes.colorBlockText}>{props?.isMobile ? "Icon" : "Select an icon"}</p>
                  <span className={classes.colorCode} ref={spanIconRef}>
                    {(selectedIcon && selectedIcon.length > 50) ||
                      !foundIcon ? (
                      <SvgIcon color="action" fontSize="small" sx={iconisDefault ? { verticalAlign: "middle", mt: 0.3 } : {}}>
                        <path d={selectedIcon} fill={iconisDefault ? "var(--color-new-main)" : ""} />
                      </SvgIcon>
                    ) : (
                      foundIcon && (
                        <FontAwesomeIcon size="2xs" icon={foundIcon} />
                      )
                    )}

                    {/* <FontAwesomeIcon size="2xs" icon={{
                      iconName: () => undefined,
                     }} path={selectedIcon} /> */}
                  </span>
                </div>
                {/* CHOOSE ICON PALETTE */}
                {/* <IconPickerDialog
                  openIcon={openIcon}
                  onClose={handleCloseIcon}
                /> */}
              </>
            )}
          </Grid>
          <Grid
            sx={props?.isMobile ? {
              width: "100%",
              padding: "24px 0",
            } : {
              width: "100%",
              borderBottom: "1px solid #F3F3F3",
              padding: "24px 0",
            }}
          >
            {width > 768 ? (
              <Typography
                variant="caption"
                component="div"
                className={classes.outcomeTitle}
                sx={{
                  marginTop: "0 !important",
                }}
              >
                Define your Scale for evaluating your progress
              </Typography>
            ) : (
              <Typography
                component="h2"
                variant="h4"
                // className={classes.outcomeTitle}
                style={{ marginTop: "20px" }}
                sx={{
                  "&.MuiTypography-root": {
                    "@media  (max-width: 786px) and (min-width: 100px)": {
                      fontSize: `${pxToRem(16)} !important`,
                      lineHeight: "100% !important",
                      fontWeight: 500,
                      color: palette.common.black
                    },
                  },
                }}
              >
                Define the scale points
              </Typography>
            )}

            {/* SCALBAR */}
            <ProgressBarStepperNew
              selectedColor={selectedColor}
              setTextInput={props?.setTextInput}
              textInput={props?.textInput}
              setShowInput={setShowInput}
              setEditStatus={setEditStatus}
              showInput={showInput}
              editStatus={editStatus}
              width={width}
              absenceToggle={absenceToggle}
            />
          </Grid>
          <br />
          <FormControlLabel
            value={absenceToggle}
            // disabled={
            //   props.influenceData?.uid &&
            //   props.influenceData?.type === 'TYPE1'
            // }
            control={
              <Checkbox
                sx={props.isMobile ? {
                  "& svg": {
                    "& path": {
                      stroke: "#DCE5E0"
                    }
                  }
                } : {}}
                // disabled={props.influenceData?.uid}
                checked={[true, "true"].includes(absenceToggle)}
              />
            }
            sx={{
              "& .MuiFormControlLabel-label": {
                color: "#3E4240",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "100%" /* 16px */,
              },
              "& .Mui-checked": {
                color: "var(--color-new-main) !important",
              },
              "& .MuiCheckbox-root": {
                padding: "9px !important",
              },
              "&.MuiFormControlLabel-root": props?.isMobile
                ? {
                  marginTop: "10px !important",
                }
                : {},
            }}
            label="Include absence in the scale"
            onChange={(e: any) => {
              console.log("selectedColor", e, e.target.checked, e.target.value);
              setAbsenceToggle(e.target.checked);
              setToogleSwitchAction(e.target.checked);
            }}
          />
          <FormControlLabel
            value={absenceToggle}
            // disabled={
            //   props.influenceData?.uid &&
            //   props.influenceData?.type === 'TYPE1'
            // }
            control={
              <Checkbox
                sx={props.isMobile ? {
                  "& svg": {
                    "& path": {
                      stroke: "#DCE5E0"
                    }
                  }
                } : {}}
                // disabled={props.influenceData?.uid}
                checked={[true, "true"].includes(skipMetric)}
              />
            }
            sx={{
              "& .MuiFormControlLabel-label": {
                color: "#3E4240",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "100%" /* 16px */,
              },
              "& .Mui-checked": {
                color: "var(--color-new-main) !important",
              },
              "& .MuiCheckbox-root": {
                padding: "9px !important",
              },
              "&.MuiFormControlLabel-root": props?.isMobile
                ? {
                  marginTop: "10px !important",
                }
                : {},
            }}
            label={
              <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                <span>Skip metric</span>{" "}
                {width < 786 ? (
                  <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
                    <IconButton
                      style={{
                        padding: "0px",
                        height: "20px",
                        width: "20px"
                      }}

                      onClick={() => setOpenTooltip(!openTooltip)}
                    >
                      <HTMLTooltip customIcon={<div>
                        <InfoIcon color={"var(--color-new-main)"} size={"14"} />
                      </div>} title={"Activate to automatically move to the next metric when you press enter"} fontProps={{
                        fontSize: `${pxToRem(12)} !important`,
                      }} sx={{
                        "& .MuiTooltip-tooltip": {
                          maxWidth: "300px",
                        },
                      }} customProps={{
                        placement: "bottom-end",
                        open: openTooltip
                      }} />
                    </IconButton>
                  </ClickAwayListener>
                ) : <IconButton
                  style={{
                    padding: "0px",
                    height: "20px",
                    width: "20px",
                  }}
                //   onClick={() => setArchiveConfirmationModal(true)}
                >
                  <HTMLTooltip
                    customIcon={
                      <div>
                        <InfoIcon color={"var(--color-new-main)"} size={"14"} />
                      </div>
                    }
                    title="Activate to automatically move to the next metric when you press enter"
                    fontProps={{
                      fontSize: `${pxToRem(12)} !important`,
                    }}
                    sx={{
                      "& .MuiTooltip-tooltip": {
                        maxWidth: "300px",
                      },
                    }}
                  />
                </IconButton>}


                {/*  */}
              </Box>
            }
            onChange={(e: any) => {
              setSkipMetric(e.target.checked);
              setToogleSwitchAction(e.target.checked);
            }}
          />
          <div style={props.isMobile ? { marginTop: "0", marginBottom: "40px", width: "100%" } : { marginTop: "0", width: "100%" }}>
            <Typography
              variant="caption"
              component="p"
              className={classes.pageTitle}
              sx={{
                "&.MuiTypography-root": {
                  "@media  (max-width: 786px) and (min-width: 100px)": {
                    fontSize: `${pxToRem(16)} !important`,
                    lineHeight: "100% !important",
                    fontWeight: 500,
                    color: palette.common.black
                  },
                },
              }}
            >
              {props.isMobile ? "Description" : "Please, describe the outcome"}
            </Typography>
            <TextareaAutosize
              // variant="outlined"
              className={"textarea " + classes.textarea}
              // fullWidth
              placeholder="Add a description..."
              // multiline
              minRows={4}
              // sx={{
              //   width: "100%"
              // }}

              onChange={(e) => setOutcomeDesc(e.target.value)}
              value={outcomeDesc}
            />
          </div>
          {!props.isMobile ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                padding: props?.isMobile
                  ? "32px 32px 0px 0px"
                  : "32px 32px 32px 0px",
                alignItems: "flex-end",
                gap: "12px",
              }}
            >
              {!props.showOutcome && (
                <Button
                  className="no-after"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1, color: palette.primary.main, p: 0 }}
                  style={{ display: width > 768 ? "none" : "flex" }}
                >
                  {activeStep === 0 ? (
                    <StepperBackArrowDisable />
                  ) : (
                    <StepperBackArrow />
                  )}
                </Button>
              )}
              <NewButton
                type="submit"
                isDisabled={
                  outcomeValueInput == "" || outcomeValueInput == undefined
                }
                buttonText={props.outComeValue?.uid ? "Update" : "Add"}
                onClick={() => addOutCome(outcomeValueInput)}
                isLoadong={showLoader}
              />
              {!showLoader ? (
                <NewButton
                  buttonText={"Cancel"}
                  variant="text"
                  onClick={() => props.handleCloseStartModal()}
                />
              ) : null}
              {!props.showOutcome && (
                <Button
                  className="no-after"
                  onClick={handleNext}
                  sx={{ color: palette.primary.main, p: 0, cursor: "pointer" }}
                  style={{ display: width > 768 ? "none" : "flex" }}
                  disabled={
                    activeStep === userType.length - 1 ||
                    (true
                      ? IUserTypeState?.outcomeList?.length == 0
                      : false
                        ? IUserTypeState?.influenceList?.length == 0
                        : false
                          ? IUserTypeState?.reflectiveList?.length == 0
                          : false)
                  }
                >
                  {activeStep === userType.length - 1 ||
                    (true
                      ? IUserTypeState?.outcomeList?.length == 0
                      : false
                        ? IUserTypeState?.influenceList?.length == 0
                        : false
                          ? IUserTypeState?.reflectiveList?.length == 0
                          : false) ? (
                    <StepperNextArrowDisable />
                  ) : (
                    <StepperNextArrow />
                  )}
                </Button>
              )}
            </div>
          ) : (
            <div style={{
              display: "flex",
              position: "fixed",
              bottom: 0,
              left: 0,
              padding: "10px 20px 20px",
              width: "100%",
              background: palette.common.white,
              zIndex: 1
            }}>
              <NewButton
                type="submit"
                isDisabled={
                  outcomeValueInput == "" || outcomeValueInput == undefined
                }
                boxSx={{
                  display: 'flex',
                  alignSelf: "stretch",
                  width: "100%",
                  justifyContent: "center",
                }}
                extraSx={{
                  display: 'flex',
                  alignSelf: "stretch",
                  width: "100%",
                }}
                typographySx={{
                  "&.MuiTypography-root": {
                    "@media  (max-width: 786px) and (min-width: 100px)": {
                      fontSize: `${pxToRem(18)} !important`,
                      lineHeight: "100% !important",
                      fontWeight: 500,
                      color: palette.common.black
                    },
                  },
                }}
                buttonText={(props.outComeValue?.uid ? "Update" : "Add") + " Outcome"}
                onClick={() => addOutCome(outcomeValueInput)}
                fontSize="18px"
                isLoadong={showLoader}
              />
            </div>
          )}

          <Popover
            id={"color-pallete"}
            open={viewPopover}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              "& .MuiPaper-root": {
                display: "flex",
                alignItems: "flex-start",
                gap: "12px",
                flexDirection: "column",
                padding: "20px",
                borderRadius: "12px",
                border: "1px solid #F3F3F3",
              },
            }}
          >
            <div className={classes.popoverTitleGroup}>
              <Typography
                sx={{
                  fontSize: "0.875rem !important",
                  lineHeight: "100%",
                  color: palette.common.black,
                  fontWeight: 500,
                }}
              >
                Select colour pallet
              </Typography>
              <div onClick={handleClose} style={{ cursor: "pointer" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M12 12L8.00001 8.00001M8.00001 8.00001L4 4M8.00001 8.00001L12 4M8.00001 8.00001L4 12"
                    stroke="black"
                    strokeWidth="1.33333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <List
              sx={{
                pt: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "12px",
              }}
            >
              {fixOutcomeColorPalette.map(
                (palette: OutComeColorModal, index: number) => (
                  <ListItem
                    button
                    sx={{
                      display: "flex",
                      padding: "16px 20px",
                      alignItems: "flex-start",
                      gap: "12px",
                      borderRadius: "12px",
                      border:
                        palette === selectedColor
                          ? "1px solid #11734A"
                          : "1px solid #F3F3F3",
                      background: "#FFF",
                    }}
                    onClick={() => handleCloseColor(palette)}
                    key={index}
                  >
                    {/* <ListItemText
                        primary={palette.id}
                        style={{ marginRight: "0" }}
                      /> */}

                    {palette.color.map((pal) => {
                      return (
                        <div
                          style={{
                            background: pal,
                            width: "32px",
                            height: "32px",
                            borderRadius: "12px",
                          }}
                          key={`${pal}_${palette.id}`}
                        ></div>
                      );
                    })}
                  </ListItem>
                )
              )}
            </List>
          </Popover>
          <Popover
            id={"color-pallete"}
            open={viewIconPopover}
            anchorEl={anchorIconEl}
            onClose={handleIconClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              "& .MuiPaper-root": {
                display: "flex",
                alignItems: "flex-start",
                gap: "12px",
                flexDirection: "column",
                padding: "20px",
                borderRadius: "12px",
                border: "1px solid #F3F3F3",
                maxWidth: "400px",
              },
            }}
          >
            <div className={classes.popoverTitleGroup}>
              <Typography
                sx={{
                  fontSize: "0.875rem !important",
                  lineHeight: "100%",
                  color: palette.common.black,
                  fontWeight: 500,
                }}
              >
                Select an icon
              </Typography>
              <div onClick={handleIconClose} style={{ cursor: "pointer" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M12 12L8.00001 8.00001M8.00001 8.00001L4 4M8.00001 8.00001L12 4M8.00001 8.00001L4 12"
                    stroke="black"
                    strokeWidth="1.33333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="iconPicker__popoverHeader"
              style={{
                width: "100%",
              }}
            >
              <TextField
                fullWidth
                placeholder="Search"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  border: `1px solid ${palette.primary.main}`,
                  borderRadius: "20px",
                  "& .MuiInputBase-root": {
                    borderRadius: "20px !important",
                  },
                }}
                size="small"
                variant="outlined"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
            <Divider />
            {/* <Grid container display={"flex"} flexDirection={"row"} gap={"16px"} paddingBottom={0} alignItems={"flex-start"} flexWrap={"wrap"} width={"100%"}>
          {fixOutcomeIcon?.map((icon: OutComeIconModal, index: number) => {
            return (
              <Grid
                item
                sx={{
                  padding: '16px 20px',
                  alignItems: 'center',
                  gap: '12px',
                  borderRadius: '12px',
                  border: '1px solid #11734A',
                  background: '#FFF',
                  flexBasis: "10%",
                }}
                // onClick={() => handleListItemClick(icon)}
                key={index}
              >
                <div style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "12px",
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: "center"
                }}>
                  <SvgIcon fontSize="large">
                    <path d={icon.path} />
                  </SvgIcon>
                </div>
              </Grid>
            );
          })}
        </Grid> */}

            <List
              sx={{
                pt: 0,
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                gap: "12px",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              {iconsFiltered.length > 0 &&
                iconsFiltered?.map((icon, index: number) => {
                  return (
                    icon.icon &&
                    icon.icon.length > 0 && (
                      <ListItem
                        button
                        sx={{
                          display: "flex",
                          padding: "10px 0",
                          alignItems: "center",
                          gap: "0",
                          justifyContent: "center",
                          borderRadius: "12px",
                          border:
                            icon.icon[icon.icon.length - 1] === selectedIcon
                              ? "1px solid #11734A"
                              : `1px solid #F3F3F3`,
                          background: "#FFF",
                          flexBasis: "22%",
                        }}
                        onClick={() => handleCloseIcon(icon)}
                        key={index}
                      >
                        <div
                          style={{
                            borderRadius: "12px",
                            display: "flex",
                            flexDirection: "row",
                            height: 20,
                            width: 20,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {/* <SvgIcon fontSize="large">
                        <path d={icon.path} />
                      </SvgIcon> */}
                          <FontAwesomeIcon size="2xs" icon={icon} />
                        </div>
                      </ListItem>
                    )
                  );
                })}
            </List>
          </Popover>
          <Wellbeing
            open={openWellbeing}
            journeyData={outcomeState?.notesList?.outcome_data}
            setopenWellbeing={setopenWellbeing}
            outcomeValueInput={outcomeValueInput}
          />
          {/* BOTTOM STEPPER */}
          {!props.showOutcome && (
            <BottomStepper
              isOutcome={true}
              isInfluence={false}
              isRefQues={false}
              isTracking={false}
            />
          )}
          <SwitchInfluenceModal
            open={openSwitchInfluenceModal}
            setopenSwitchInfluenceModal={setopenSwitchInfluenceModal}
            setTransformToInfluenceTracking={
              props?.setTransformToInfluenceTracking
            }
            setAddOutcomeTracking={props?.setAddOutcomeTracking}
            setShowOutcome={props?.setShowOutcome}
            outcomeValueInput={outcomeValueInput}
          />
          <ArchiveModal
            open={archiveConfirmationModal}
            handleClose={() => setArchiveConfirmationModal(false)}
            handleSubmit={() => {
              onArchiveOutcome();
              setArchiveConfirmationModal(false);
            }}
            outcomeValueInput={outcomeValueInput}
          />
        </RootStyle>
      </Grid>
      {!props?.isMobile && (
        <Drawer
          anchor="right"
          variant="persistent"
          sx={{
            position: "relative",
            // marginLeft: "auto",
            // width: 200,
            zIndex: 1300,
            "& .MuiBackdrop-root": {
              display: "none",
            },
            "& .MuiPaper-root": {
              width: "300px !important",
              position: "absolute",
              height: "100%",
              transition: "none !important",
              display: "flex",
              padding: "24px",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "20px",
              alignSelf: "stretch",
              background: "#FAFAFA",
              borderLeft: "1.5px dashed var(--color-new-text)",
            },
          }}
          open={openRightSideSuggestion}
          onClose={() => {
            setOpenRightSideSuggestion(false);
            props.setNeedIdeasOpen && props.setNeedIdeasOpen(false);
          }}
        >
          <RightSideOutcomeTrackingNew
            outcomeIdeas={props.outcomeIdeas}
            outcomeData={props.outcomeData}
            setOutcomeValue={props.setOutcomeValue}
            setOpenRightSideSuggestion={(val) => {
              setOpenRightSideSuggestion(val);
              props.setNeedIdeasOpen && props.setNeedIdeasOpen(false);
            }}
          />
        </Drawer>
      )}
    </Grid>
  );
};
export default LeftSideOutComeNew;
