/*eslint @typescript-eslint/no-unused-vars: "off"*/
import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  TextareaAutosize,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import ModalCloseIcon from "../../assets/images/icon/modalCloseIconBlack";
import palette from "../../theme/palette";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { UserTypeContext } from "../../context/userStatus";
import { trackingService } from "../../services/tracking";
import { ButtonLoader } from "../../components/buttonLoader";
import InfluenceclosePopup from "./InfluenceclosePopup";
import DeleteIcon from "../../assets/images/icon/DeleteIcon";
import { addCategoryJourneyAction, addJourneyAction } from "../../store/actions/userTypeAction";
import { AddUpdateEventModal } from "./addTrackingEventInfluenceModal";
import typography, { pxToRem } from "../../theme/typography";
import RightIconSwitch from "../../assets/images/icon/RightIconSwitch";

const useStyles = makeStyles((theme) => ({
  registrationHeading: {
    color: `${palette.primary.light}!important`,
    marginBottom: "20px!important",
  },
  pageTitle: {
    marginBottom: "0!important",
  },
  dialogTitle: {
    color: `${palette.primary.light}!important`,
    fontSize: "26px!important",
    lineHeight: "26px!important",
    letterSpacing: "0.02em",
    marginBottom: "30px!important",
    padding: "0!important",
  },
  bottomButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    "& button:nth-child(2)": {
      // marginLeft: "24px",
      marginBottom: '0px !important'
    },
  },
  textarea: {
    width: "100%",
    padding: "20px",
    fontFamily: typography.fontFamily,
    fontSize: "16px",
    fontWeight: 400,
    resize: "vertical",
    "&:focus-visible": {
      outline: "red"
    }
  }
}));

interface AddHours {
  setOpenYesNoModal: (openYesNoModal: boolean) => void;
  item: any;
  isEdit: Boolean;
  setIsEdit: (isEdit: boolean) => void;
  journeyData: any;
  headerDate: any;
  setShowSnakbar: (showSnakbar: boolean) => void;
  setSnakbarMsg: (snakbarMsg: string) => void;
  setMsgType: (msgType: string) => void;
  callService: any;
  setInfluenceId: (influenceId: string) => void;
  setOpenTimeOfDayModal?: any;
  callToGetData?: boolean
  isMobile?: boolean
  renderToNextInput?: () => void
}
const AddYesNoModal = (props: AddHours) => {
  const classes = useStyles();
  const { state: outcomeState, dispatch: UserTypeDispatch } = useContext(UserTypeContext);
  const [openStartModal, setStartModal] = useState(true);
  const [showLoadingBtn, setShowLoadingBtn] = useState(false);
  const [inputLevel, setInputLevel] = useState("");
  const [notes, setNotes] = useState<any>("");
  const [textAreaFocused, setTextAreaFocused] = useState<boolean>(false);
  const [editData, setEditData] = useState<any>();
  const [item, setItem] = useState<any>();
  const [openInfluenceclosePopup, setopenInfluenceclosePopup] = useState(false);
  const [openInfluenceDeletePopup, setopenInfluenceDeletePopup] = useState(false);
  const { isMobile = false } = props
  const [isBackButtonClicked, setBackbuttonPress] = useState(false)
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputLevel, notes, isBackButtonClicked]);
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!isBackButtonClicked) {
      setBackbuttonPress(true)
      if(editData?.influenceId && editData?.levelReach === inputLevel && (notes === editData?.note[0] || (!notes && !editData?.note[0]))) {
        handleCloseStartModal()
      } else if(inputLevel !== "" || notes !== "") {
        setopenInfluenceclosePopup(true)
      } else {
        handleCloseStartModal()
      }
    }
  }
  useEffect(() => {
    const outcomeGetData = {
      influenceId: props.item?.influenceId,
      date: props?.headerDate,
    };
    trackingService
      .getInfluenceRes(outcomeState.token, outcomeGetData)
      .then((data) => {
        setEditData(data?.data);
        if (data?.data === null) {
          props?.setIsEdit(false);
          setInputLevel("");
        } else {
          props?.setIsEdit(true);
          setNotes(data?.data?.note[0] ?? "");
          setInputLevel(data?.data?.levelReach ?? "");
          setItem(data?.data);
        }
      })
      .catch((error) => {
        console.log("outcome response get error");
      });
    
  }, [props?.item]);

  useEffect(() => {
    const handleKeyListener = (e) => {
      let level = null
      switch (e.key?.toLowerCase()) {
        case 'y':
          level = "Yes"
          break;
        case 'n':
          level = "No"
          break;
        default:
          break;
      }
      if (level && !textAreaFocused) {
        setInputLevel(level)
        setShowLoadingBtn(true);
        const outcomeCreateData = {
          influenceId: props.item?.influenceId,
          levelReach: level,
          note: notes === undefined || notes === "" ? [] : [notes],
          date: props?.headerDate,
          flag: props?.item?.flag,
          type: props?.item?.type,
          inverse: props?.item?.inverse,
          colorCode: (props?.item?.type === "TYPE3" &&
            level === "No") ||
            level === "No"
            ? props?.item?.inverse === "true"
              ? palette.primary.main
              : "#F17F79"
            : props?.item?.inverse === "true"
              ? "#F17F79"
              : palette.primary.main
        };

        trackingService
          .influenceCreate(outcomeState.token, outcomeCreateData)
          .then((data) => {
            if (!outcomeState?.isPersonal) {
              const foundOutcome: any = (outcomeState?.influenceList || []).find(it => it.influenceId === outcomeCreateData.influenceId);
              if (foundOutcome) {
                const journyList = { ...(outcomeState?.journeyList || {}) } as any
                const foundDateItemIcon = journyList.influence_data.findIndex(it => it.influenceId === outcomeCreateData.influenceId && it?.date === outcomeCreateData.date)
                if (foundDateItemIcon > -1) {
                  delete foundOutcome.colorCode
                  journyList.influence_data[foundDateItemIcon] = {
                    ...foundOutcome,
                    ...outcomeCreateData,
                  }
                } else {
                  delete foundOutcome.colorCode
                  journyList.influence_data.push({
                    ...foundOutcome,
                    ...outcomeCreateData,
                  } as any)
                }
                UserTypeDispatch(addJourneyAction(journyList as any))
                if (props.callToGetData) {
                  props?.callService(false);
                }
              }
            } else {
              const journeyCategoryList = [...(outcomeState?.journeyCategoryList || [])]
              const foundOutcome = (journeyCategoryList
                || []).findIndex(it => it.influenceIds && it.influenceIds.includes(outcomeCreateData.influenceId));
              if (foundOutcome > -1) {
                if (journeyCategoryList[foundOutcome].alls && journeyCategoryList[foundOutcome].alls.length) {
                  const foundIndex = journeyCategoryList[foundOutcome].alls.findIndex(it => it.influenceId === outcomeCreateData.influenceId)
                  if (foundIndex > -1) {
                    if (journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses && journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.length) {
                      const foundDateValue = journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.findIndex(it => it.date === outcomeCreateData.date)
                      if (foundDateValue > -1) {
                        journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses[foundDateValue] = {
                          ...outcomeCreateData
                        }
                      } else {
                        journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.push(outcomeCreateData)
                      }
                    } else {
                      journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses = [outcomeCreateData]
                    }
                    UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
                    if (props.callToGetData) {
                      props?.callService(false);
                    }
                  }
                }
              } else {
                const foundOther = (journeyCategoryList || []).findIndex(it => it.name === "Other")
                if (foundOther > -1) {
                  const outcomeFound = journeyCategoryList[foundOther].influences.findIndex(it => it.influenceId === outcomeCreateData.influenceId);
                  if (outcomeFound > -1) {
                    if (journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses && journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.length) {
                      const foundDateValue = journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.findIndex(it => it.date === outcomeCreateData.date)
                      if (foundDateValue > -1) {
                        journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses[foundDateValue] = {
                          ...outcomeCreateData
                        }
                      } else {
                        journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.push(outcomeCreateData)
                      }
                    } else {
                      journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses = [outcomeCreateData]
                    }
                    UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
                    if (props.callToGetData) {
                      props?.callService(false);
                    }
                  }
                }
              }
            }
            props.renderToNextInput && !isMobile && props.renderToNextInput()
            setShowLoadingBtn(false);
            props.setShowSnakbar(false);
            props.setSnakbarMsg(data?.message);
            props.setOpenYesNoModal(false);
            // props?.callService(false);
            props.setMsgType("success");
            level = null
          })
          .catch((error) => {
            setShowLoadingBtn(false);
            props.setShowSnakbar(true);
            props.setSnakbarMsg(
              error?.response?.data?.description
                ? error?.response?.data?.description
                : error?.message
            );
            level = null
            props.setMsgType("error");
          });
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    document.addEventListener('keydown', handleKeyListener)
    return () => document.removeEventListener('keydown', handleKeyListener)
  }, [textAreaFocused])

  const createInfluenceLevel = () => {
    let outcomeCreateData;
    if (inputLevel) {
      setShowLoadingBtn(true);
      outcomeCreateData = {
        influenceId: props.item?.influenceId,
        levelReach: inputLevel,
        note: notes === undefined || notes === "" ? [] : [notes],
        date: props?.headerDate,
        flag: props?.item?.flag,
        type: props?.item?.type,
        inverse: props?.item?.inverse,
        colorCode: (props?.item?.type === "TYPE3" &&
          inputLevel === "No") ||
          inputLevel === "No"
          ? props?.item?.inverse === "true"
            ? palette.primary.newmain
            : "#F17F79"
          : props?.item?.inverse === "true"
            ? "#F17F79"
            : palette.primary.newmain
      };

      trackingService
        .influenceCreate(outcomeState.token, outcomeCreateData)
        .then((data) => {
          if (!outcomeState?.isPersonal) {
            const foundOutcome: any = (outcomeState?.influenceList || []).find(it => it.influenceId === outcomeCreateData.influenceId);
            if (foundOutcome) {
              delete foundOutcome.colorCode
              const journyList = { ...(outcomeState?.journeyList || {}) } as any
              const foundDateItemIcon = journyList.influence_data.findIndex(it => it.influenceId === outcomeCreateData.influenceId && it?.date === outcomeCreateData.date)
              if (foundDateItemIcon > -1) {
                journyList.influence_data[foundDateItemIcon] = {
                  ...foundOutcome,
                  ...outcomeCreateData,
                }
              } else {
                journyList.influence_data.push({
                  ...foundOutcome,
                  ...outcomeCreateData,
                } as any)
              }
              UserTypeDispatch(addJourneyAction(journyList as any))
              if (props.callToGetData) {
                props?.callService(false);
              }
            }
          } else {
            const journeyCategoryList = [...(outcomeState?.journeyCategoryList || [])]
            const foundOutcome = (journeyCategoryList
              || []).findIndex(it => it.influenceIds && it.influenceIds.includes(outcomeCreateData.influenceId));
            if (foundOutcome > -1) {
              if (journeyCategoryList[foundOutcome].alls && journeyCategoryList[foundOutcome].alls.length) {
                const foundIndex = journeyCategoryList[foundOutcome].alls.findIndex(it => it.influenceId === outcomeCreateData.influenceId)
                if (foundIndex > -1) {
                  if (journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses && journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.length) {
                    const foundDateValue = journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.findIndex(it => it.date === outcomeCreateData.date)
                    if (foundDateValue > -1) {
                      journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses[foundDateValue] = {
                        ...outcomeCreateData
                      }
                    } else {
                      journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.push(outcomeCreateData)
                    }
                  } else {
                    journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses = [outcomeCreateData]
                  }
                  UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
                  if (props.callToGetData) {
                    props?.callService(false);
                  }
                }
              }
            } else {
              const foundOther = (journeyCategoryList || []).findIndex(it => it.name === "Other")
              if (foundOther > -1) {
                const outcomeFound = journeyCategoryList[foundOther].influences.findIndex(it => it.influenceId === outcomeCreateData.influenceId);
                if (outcomeFound > -1) {
                  if (journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses && journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.length) {
                    const foundDateValue = journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.findIndex(it => it.date === outcomeCreateData.date)
                    if (foundDateValue > -1) {
                      journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses[foundDateValue] = {
                        ...outcomeCreateData
                      }
                    } else {
                      journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.push(outcomeCreateData)
                    }
                  } else {
                    journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses = [outcomeCreateData]
                  }
                  UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
                  if (props.callToGetData) {
                    props?.callService(false);
                  }
                }
              }
            }
          }
          props.renderToNextInput && !isMobile && props.renderToNextInput()
          setShowLoadingBtn(false);
          // props.setShowSnakbar(true);
          props.setSnakbarMsg(data?.message);
          props.setOpenYesNoModal(false);
          // props?.callService(false);
          props.setMsgType("success");
        })
        .catch((error) => {
          setShowLoadingBtn(false);
          props.setShowSnakbar(true);
          props.setSnakbarMsg(
            error?.response?.data?.description
              ? error?.response?.data?.description
              : error?.message
          );
          props.setMsgType("error");
        });
    }

  };

  const handleDeleteEntry = () => {
    let payload = {
      influenceId: editData?.influenceId,
      date: props?.headerDate,
    };
    trackingService
      .deleteInfluenceRes(outcomeState.token, payload)
      .then((data) => {
        if (!outcomeState?.isPersonal) {
          const foundOutcome = (outcomeState?.influenceList || []).find(it => it.influenceId === payload.influenceId);
          if (foundOutcome) {
            const journyList = { ...(outcomeState?.journeyList || {}) } as any
            const foundDateItemIcon = journyList.influence_data.findIndex(it => it.influenceId === payload.influenceId && it?.date === payload.date)
            if (foundDateItemIcon > -1) {
              journyList.influence_data.splice(foundDateItemIcon, 1)
            }
            UserTypeDispatch(addJourneyAction(journyList as any))
          }
        } else {
          const journeyCategoryList = [...(outcomeState?.journeyCategoryList || [])]
          const foundOutcome = (journeyCategoryList
            || []).findIndex(it => it.influenceIds && it.influenceIds.includes(payload.influenceId));
          if (foundOutcome > -1) {
            if (journeyCategoryList[foundOutcome].alls && journeyCategoryList[foundOutcome].alls.length) {
              const foundIndex = journeyCategoryList[foundOutcome].alls.findIndex(it => it.influenceId === payload.influenceId)
              if (foundIndex > -1) {
                if (journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses && journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.length) {
                  const foundDateValue = journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.findIndex(it => it.date === payload.date)
                  if (foundDateValue > -1) {
                    journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.splice(foundDateValue, 1)
                  }
                }
                UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
              }
            }
          } else {
            const foundOther = (journeyCategoryList || []).findIndex(it => it.name === "Other")
            if (foundOther > -1) {
              const outcomeFound = journeyCategoryList[foundOther].influences.findIndex(it => it.influenceId === payload.influenceId);
              if (outcomeFound > -1) {
                if (journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses && journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.length) {
                  const foundDateValue = journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.findIndex(it => it.date === payload.date)
                  if (foundDateValue > -1) {
                    journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.splice(foundDateValue, 1)
                  }
                }
                UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
              }
            }
          }
        }
        setShowLoadingBtn(false);
        props.setShowSnakbar(true);
        props.setSnakbarMsg(data?.message);
        props.setOpenYesNoModal(false);
        isMobile && props?.callService(false);
        props.setMsgType("success");
      })
      .catch((error) => {
        setShowLoadingBtn(false);
        props.setShowSnakbar(true);
        props.setSnakbarMsg(
          error?.response?.data?.description
            ? error?.response?.data?.description
            : error?.message
        );
        props.setMsgType("error");
      });
  };

  const handleCloseStartModal = async () => {
    setStartModal(false);
    await props.setOpenYesNoModal(false);
    props.setInfluenceId("");
  };
  const onKeyDownHandler = (e) => {
    let keyPress = e.ctrlKey || e.altKey
    if (e.keyCode === 13  || (keyPress && ([65, 85].includes(e.keyCode)))) {
      if(!textAreaFocused) {
        createInfluenceLevel()
      }
    }
  }
  return (
    <Grid>
      <form onKeyDown={onKeyDownHandler}>
        <AddUpdateEventModal
          onClose={
            editData?.influenceId && editData?.levelReach === inputLevel && (notes === editData?.note[0] || (!notes && !editData?.note[0])) ? handleCloseStartModal :
              inputLevel !== "" || notes !== ""
                ? () => {
                  setopenInfluenceclosePopup(true);
                }
                : handleCloseStartModal}
          onSave={createInfluenceLevel}
          isMobile={isMobile}
          paperProps={isMobile ? {
            height: "calc(100vh - 50%)"
          }: {}}
          showTitle={!isMobile}
          title={<>
            {props?.isEdit ? "Update Tracking Event" : "Add Tracking Event"}
            {editData?.influenceId &&
              <IconButton
                onClick={() => {
                  setopenInfluenceDeletePopup(true);
                }}
              >
                <DeleteIcon />
              </IconButton>}
          </>}
          showLoadingBtn={showLoadingBtn}
          visible={openStartModal}
          buttonText={props?.isEdit ? <span><span style={{ textDecoration: "underline"}}>U</span>pdate</span> : <span><span style={{ textDecoration: "underline"}}>A</span>dd</span>}
          saveDisabled={!(inputLevel !== "" && Boolean(inputLevel))}
        >
          {isMobile && editData?.influenceId && <div
            style={{
              position: "absolute",
              right: 0,
              top: 0
            }}
          >
            <IconButton
              onClick={() => {
                setopenInfluenceDeletePopup(true);
              }}
            >
              <DeleteIcon />
            </IconButton></div>}
          <div
            style={{
              display: 'flex',
              padding: isMobile ? "0px" : '24px 32px 0px 32px',
              flexDirection: 'column',
              alignItems: isMobile ? "flex-start" : 'center',
              gap: isMobile ? '24px' : '16px',
              justifyContent: "center",
              width: "100%",
              alignSelf: 'stretch',

            }}>
            <Typography
              variant="body1"
              component="div"
              sx={isMobile ? {
                color: palette.primary.light,
                fontSize: `${pxToRem(20)} !important`,
                lineHeight: "125% !important",
                // textAlign: "center !important",
                fontWeight: `500 !important`,
                "&.MuiTypography-root": {
                  "@media  (max-width: 600px) and (min-width: 100px)": {
                    fontSize: `${pxToRem(20)} !important`,
                    lineHeight: "125% !important"
                  },
                }
              } : { fontWeight: 600, fontSize: `${pxToRem(24)} !important`, lineHeight: "135%" }}
              className={classes.registrationHeading}
            >
              {props?.item?.name || "Name"}
            </Typography>
            <div style={{
              display: 'flex',
              padding: isMobile ? '0px 0px 0px 0px' : '32px 32px 0',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '24px',
              alignSelf: 'stretch',
            }}>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '16px',
                alignSelf: 'stretch',
              }}>
                {!isMobile && <Typography
                  variant="caption"
                  component="p"
                  className={classes.pageTitle}
                >
                  Select
                </Typography>}
                <FormControl sx={isMobile ? {
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  alignSelf: 'stretch',
                  justifyContent: "center"
                } : {
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: '8px',
                  alignSelf: 'stretch',
                }}>
                  {isMobile ? <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: "center",
                    gap: '8px',
                    flexDirection: "row",
                    alignSelf: 'stretch',
                    padding: " 0 10%",
                    // width: "100%",
                    marginBottom: 0
                  }}>
                    <Button variant={inputLevel === "Yes" ? "contained" : "outlined"} sx={{
                      padding: '16px 20px',
                      borderRadius: "8px",
                      width: "100%",
                      marginBottom: `0 !important`

                    }} onClick={() => setInputLevel("Yes")}><><CheckIcon sx={{
                      marginRight: "16px"
                    }} /><Typography sx={{
                      fontSize: "20px",
                      fontWeight: 500,
                      lineHeight: "100%",
                      color: inputLevel === "Yes" ? "#FFF" : "#3E4240"
                    }}>Yes!</Typography></></Button>
                    <Button variant={inputLevel === "No" ? "contained" : "outlined"} sx={{
                      padding: '16px 20px',
                      borderRadius: "8px",
                      width: "100%",
                      marginBottom: `0 !important`

                    }} onClick={() => setInputLevel("No")}><><CloseIcon sx={{
                      marginRight: "16px"
                    }} /><Typography sx={{
                      fontSize: "20px",
                      fontWeight: 500,
                      lineHeight: "100%",
                      color: inputLevel === "No" ? "#FFF" : "#3E4240"
                    }}>No..</Typography></></Button>
                  </Box> : <RadioGroup
                    className="radio-wrapper"
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={(e) => setInputLevel(e.target.value)}
                    value={inputLevel || ""}
                    sx={isMobile ? {
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: '8px',
                      flexDirection: "row",
                      alignSelf: 'stretch',
                      // width: "100%",
                      marginBottom: 0
                    } : {
                      width: "100%",
                      marginBottom: 0
                    }}
                  >
                    <FormControlLabel value="Yes" control={isMobile ? <Button /> : <Radio sx={{
                      "&.MuiRadio-root.Mui-checked": {
                        color: "var(--color-new-main)"
                      }
                    }}/>} label={<><span style={{ textDecoration: "underline" }}>Y</span>es</>} sx={isMobile ? {
                      display: 'flex',
                      padding: '16px',
                      alignItems: 'center',
                      gap: '12px',
                      flex: '1 0 0',
                      borderRadius: '12px',
                      border: `1px solid ${inputLevel === "Yes" ? "var(--color-new-main)" : '#EDEDED'}`,
                      background: '#FFF',
                      marginRight: 0,
                      marginLeft: 0
                    } : {
                      display: 'flex',
                      padding: '16px',
                      alignItems: 'center',
                      gap: '12px',
                      flex: '1 0 0',
                      borderRadius: '12px',
                      border: `1px solid ${inputLevel === "Yes" ? "var(--color-new-main)" : '#EDEDED'}`,
                      background: '#FFF',


                    }} />
                    <FormControlLabel value="No" control={isMobile ? <Button /> : <Radio sx={{
                      "&.MuiRadio-root.Mui-checked": {
                        color: "var(--color-new-main)"
                      }
                    }}/>} label={<><span style={{ textDecoration: "underline" }}>N</span>o</>} sx={isMobile ? {
                      display: 'flex',
                      padding: '16px',
                      alignItems: 'center',
                      gap: '12px',
                      flex: '1 0 0',
                      borderRadius: '12px',
                      border: `1px solid ${inputLevel === "No" ? "var(--color-new-main)" : '#EDEDED'}`,
                      background: '#FFF',
                      marginRight: 0,
                      marginLeft: 0

                    } : {
                      display: 'flex',
                      padding: '16px',
                      alignItems: 'center',
                      gap: '12px',
                      flex: '1 0 0',
                      borderRadius: '12px',
                      border: `1px solid ${inputLevel === "No" ? "var(--color-new-main)" : '#EDEDED'}`,
                      background: '#FFF',
                    }} />
                  </RadioGroup>}
                </FormControl>
                <div style={{
                  width: "100%", display: 'flex',
                  alignItems: 'flex-start',
                  gap: isMobile ? "16px" : '8px',
                  marginTop: "32px",
                  flexDirection: "column",
                  alignSelf: 'stretch',
                }}>
                  <Typography
                    variant="caption"
                    component="p"
                    className={classes.pageTitle}
                    onFocus={() => console.log("focused")}
                    sx={isMobile ? {
                      color: palette.primary.light,
                      fontSize: `${pxToRem(16)} !important`,
                      lineHeight: "100% !important",
                      // textAlign: "center !important",
                      fontWeight: `500 !important`,
                      "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                          fontSize: `${pxToRem(16)} !important`,
                          lineHeight: "100% !important"
                        },
                      }
                    } : {}}
                  >
                    {isMobile ? "Note for today’s entry" : "Add note"}
                  </Typography>
                  <TextareaAutosize
                    // variant="outlined"
                    className={"textarea "+ classes.textarea}
                    // sx={{
                    //   backgroundColor: `#fff`,
                    //   border: `1px solid #e3e3e3 !important`,
                    //   borderRadius: `10px`,
                    //   marginBottom: "40px !important"
                    // }}
                    onFocus={() => {
                      setTextAreaFocused(true)
                    }}
                    onBlur={() => {
                      setTextAreaFocused(false)
                    }}
                    // fullWidth
                    placeholder={/* isMobile ? "What’s worth remembering today?": */"Add a note..."}
                    // multiline
                    minRows={1}
                    onChange={(e) => setNotes(e.target.value)}
                    value={notes || ""}
                  />
                </div>
              </div>
            </div>
          </div>
          <InfluenceclosePopup
            open={openInfluenceDeletePopup}
            isDelete={openInfluenceDeletePopup}
            setopenInfluenceclosePopup={setopenInfluenceDeletePopup}
            handleCloseStartModal={handleDeleteEntry}
            setBackbuttonPress={setBackbuttonPress}
          />
          <InfluenceclosePopup
            open={openInfluenceclosePopup}
            setopenInfluenceclosePopup={setopenInfluenceclosePopup}
            handleCloseStartModal={handleCloseStartModal}
            setBackbuttonPress={setBackbuttonPress}
          />
        </AddUpdateEventModal>
      </form>
      <Dialog
        onClose={
          inputLevel !== "" || notes !== ""
            ? () => {
              setopenInfluenceclosePopup(true);
            }
            : handleCloseStartModal
        }
        open={false}
        className="dialogWrapper dialogWrapper-mobileScreen"
        fullWidth
      >
        <DialogTitle className={classes.dialogTitle} sx={{ fontWeight: 700 }}>
          Add Tracking Event
          {editData?.influenceId &&
            <IconButton
              onClick={() => {
                setopenInfluenceDeletePopup(true);
              }}
            >
              <DeleteIcon />
            </IconButton>}
          <IconButton
            sx={{ position: "absolute", top: 0, right: 0 }}
            // onClick={handleCloseStartModal}
            onClick={
              inputLevel !== "" || notes !== ""
                ? () => {
                  setopenInfluenceclosePopup(true);
                }
                : handleCloseStartModal
            }
          >
            <ModalCloseIcon />
          </IconButton>
        </DialogTitle>
        <div
          style={{ fontSize: "20px", fontWeight: 600, marginBottom: "30px" }}
        >
          {props?.item?.name}
        </div>
        {/* <div style={{ fontSize: "16px", fontWeight: 500 }}>
          Did you hit your goal today?
        </div> */}
        <DialogContent>
          <form>

            <div
              style={{
                fontSize: "16px",
                fontWeight: 500,
                marginBottom: "10px",
              }}
            >
              Add a note for today’s entry
            </div>
            <TextField
              variant="outlined"
              className="textarea"
              fullWidth
              placeholder="Add a note..."
              multiline
              minRows={4}
              onChange={(e) => setNotes(e.target.value)}
              value={notes || ""}
            />
            <Box className={classes.bottomButton}>
              {!showLoadingBtn ? (
                <Button
                  autoFocus
                  type="submit"
                  onClick={createInfluenceLevel}
                  variant="contained"
                  disabled={inputLevel === ""}
                  className="button-after tracking-button"
                >
                 {props?.isEdit ? <span><span style={{ textDecoration: "underline"}}>U</span>pdate</span> : <span><span style={{ textDecoration: "underline"}}>A</span>dd</span>}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className="button-after tracking-button"
                >
                  <ButtonLoader />
                </Button>
              )}
              <Button
                variant="outlined"
                className="button-after tracking-button"
                // onClick={handleCloseStartModal}
                onClick={
                  inputLevel !== "" || notes !== ""
                    ? () => {
                      setopenInfluenceclosePopup(true);
                    }
                    : handleCloseStartModal
                }
                sx={{ color: palette.primary.light }}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </DialogContent>

      </Dialog>
    </Grid>
  );
};
export default AddYesNoModal;
