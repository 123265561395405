import { Box, Dialog } from "@mui/material"
import React from "react"
import CommonTypo from "../../../components/CommonTypo"
import NewButton from "../../../components/NewButton"
import { INTEGRATINOS_TYPES } from "../integrationsConstants"

const SuccessDialogue = ({
    openModal,
    modalType,
    isMobile,
    onClose,
    setModalType,
    isEditMode,
    isDelete = false,
    deleteAll = false
}) => {
    let paperStyles: any = {
        display: 'flex !important',
        padding: '16px 24px 32px',
        flexDirection: 'column !important',
        alignItems: 'flex-start !important',
        gap: '32px !important',
        minWidth: "360px",
        maxWidth: "max-content",
        borderRadius: "12px",
        overflowY: "auto"
    }
    let paperStylesMobile: any = {
        display: 'flex !important',
        padding: '20px',
        flexDirection: 'column !important',
        alignItems: 'flex-start !important',
        gap: '16px !important',
        minWidth: "90%",
        borderRadius: "20px"
    }
    const closemodal = () => {
        onClose()
        setModalType(null)
    }
    return <>
        <Dialog
            onClose={closemodal}
            open={openModal}
            className={!isMobile && "dialogWrapper"}
            disableEscapeKeyDown
            sx={{
                "& .MuiDialog-paper": isMobile ? paperStylesMobile : paperStyles
            }}
        >
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} alignSelf={"stretch"}>
                <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M42 7.875C35.2507 7.875 28.653 9.8764 23.0412 13.6261C17.4294 17.3758 13.0555 22.7054 10.4726 28.9409C7.88979 35.1765 7.214 42.0379 8.53072 48.6575C9.84744 55.2771 13.0975 61.3576 17.87 66.13C22.6425 70.9025 28.723 74.1526 35.3426 75.4693C41.9622 76.786 48.8236 76.1102 55.0591 73.5274C61.2946 70.9445 66.6242 66.5707 70.3739 60.9588C74.1236 55.347 76.125 48.7493 76.125 42C76.1155 32.9524 72.5171 24.2781 66.1195 17.8805C59.7219 11.4829 51.0476 7.88455 42 7.875ZM56.9822 35.9822L38.6072 54.3572C38.3634 54.6013 38.0739 54.7949 37.7552 54.927C37.4366 55.0591 37.095 55.1271 36.75 55.1271C36.405 55.1271 36.0635 55.0591 35.7448 54.927C35.4261 54.7949 35.1366 54.6013 34.8928 54.3572L27.0178 46.4822C26.5253 45.9896 26.2486 45.3216 26.2486 44.625C26.2486 43.9284 26.5253 43.2604 27.0178 42.7678C27.5104 42.2753 28.1784 41.9985 28.875 41.9985C29.5716 41.9985 30.2396 42.2753 30.7322 42.7678L36.75 48.7889L53.2678 32.2678C53.5117 32.0239 53.8013 31.8305 54.1199 31.6985C54.4386 31.5665 54.7801 31.4985 55.125 31.4985C55.4699 31.4985 55.8115 31.5665 56.1301 31.6985C56.4488 31.8305 56.7383 32.0239 56.9822 32.2678C57.2261 32.5117 57.4196 32.8012 57.5515 33.1199C57.6835 33.4386 57.7515 33.7801 57.7515 34.125C57.7515 34.4699 57.6835 34.8114 57.5515 35.1301C57.4196 35.4488 57.2261 35.7383 56.9822 35.9822Z" fill="#ADE888" />
                </svg>
            </Box>
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} alignSelf={"stretch"} flexDirection={"column"} gap={"8px"} width={"312px"}>
                <CommonTypo size={24} lineHeight={28.8} fontWeight={700} color="var(--color-text-primary)">Success</CommonTypo>
                {isDelete ? (
                    <CommonTypo size={16} lineHeight={19.2} fontWeight={400} color="var(--color-text-secondary)" extraSx={{
                        textAlign: "center"
                    }}>We’ve successfully stopped syncing with {modalType === INTEGRATINOS_TYPES.RESCUE_TIME ? "Rescue Time" : modalType === INTEGRATINOS_TYPES.TOGGL ? "Toggl" : modalType === INTEGRATINOS_TYPES.OURA ? "Oura" : ""}. {deleteAll ? "All previous synced data is erased." :"All previous synced data is still accessible."}</CommonTypo>
                ) : (
                    <CommonTypo size={16} lineHeight={19.2} fontWeight={400} color="var(--color-text-secondary)" extraSx={{
                        textAlign: "center"
                    }}>We’ve successfully {isEditMode ? "updated" : "linked"} your {modalType === INTEGRATINOS_TYPES.RESCUE_TIME ? "Rescue Time" : modalType === INTEGRATINOS_TYPES.TOGGL ? "Toggl" : modalType === INTEGRATINOS_TYPES.OURA ? "Oura" : ""} account. You can find new imported metrics on the tracking & progress screen.</CommonTypo>
                )}

            </Box>
            <Box display={"flex"} flex={1} width={"100%"} alignSelf={"stretch"} alignItems={"center"} gap={"8px"} flexWrap={"wrap"}>
                <Box display={"flex"} flex={1} alignSelf={"stretch"} alignItems={"center"}>
                    <NewButton
                        buttonText="Explore"
                        onClick={closemodal}
                        extraSx={{
                            background: "var(--color-new-main)",
                            border: "none",
                            width: "102px",
                            height: "43px",
                            flex: 1,
                            marginBottom: "0px !important",
                            "&:hover": {
                                background: "var(--color-new-main)"
                            },
                            "&.MuiButton-outlined": {
                                border: `none`,
                            },
                            "&.MuiButton-contained": {
                                border: `none`,
                            },
                        }} fontSize={`16px`} fontWeight={600} typographySx={{
                            fontSize: "16px !important",
                            lineHeight: "16px !important",
                            fontWeight: `600 !important`,
                            letterSpacing: "2% important"
                        }}
                    />
                </Box>


            </Box>
        </Dialog>
    </>
}

export default SuccessDialogue

