import { Badge, Button, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
// import classes from "./Header.module.css";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
import { UserNotificationContext } from "../../context/userNotification";
import { notificationService } from "../../services/notifications";
import { UserTypeContext } from "../../context/userStatus";
import { unreadSpecificaNotifications, updateUnreadNotificationData } from "../../store/actions/notificationActions";
import typography from "../../theme/typography";

const useStyles = makeStyles((theme) => {
    return {
    popoverContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        // padding: '16px',
        gap: '16px',

        width: '100%',
        height: '100%',
        /* Main/White */

        background: '#ffffff',
        // boxShadow: '0px 0px 34px rgba(25, 13, 26, 0.1)',
        borderRadius: '12px',
    },
    notificationItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: '0px',
        gap: '12px',
        cursor: 'pointer',
        alignSelf: 'stretch'
    },

    popovertitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0px',
        width: '100%',
        justifyContent: 'space-between',
    },
    popovertitle1: {
        fontFamily: typography.fontFamily,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '110%',

        letterSpacing: '-0.01em',

        color: '#190d1a',
    },
    popoverbutton: {
        fontFamily: typography.fontFamily,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px !important',
        lineHeight: '100% !important',
        /* identical to box height, or 12px */

        textAlign: 'right',
        letterSpacing: '-0.01em',

        /* Main/Purple */

        color: '#9747ff',
    },
    popoverBody: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0px',
        gap: '12px',
        height: 'calc(100% - 50px)',
        width: '100%',
    },

    popoverTabs: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: '2px',
        isolation: 'isolate',

        width: '107px',
        height: '32px',

        /* Main/Background */

        background: '#f9f9f9',
        borderRadius: '10px',
    },

    popovertab: {
        display: 'flex !important',
        flexDirection: 'row',
        alignItems: 'flex-start !important',
        padding: '8px 12px !important',

        /* width: 65px,
        height: 28px, */

        /* Main/Background */

        background: '#f9f9f9 !important',
        borderRadius: '8px !important',
    },

    popoverselectedtab: {
        display: 'flex !important',
        flexDirection: 'row',
        alignItems: 'flex-start !important',
        padding: '8px 12px !important',
        /* 
        width: 38px,
        height: 28px, */

        /* Main/White */

        background: '#ffffff',
        boxShadow: '0px 0px 72px rgba(25, 13, 26, 0.12) !important',
        borderRadius: '8px !important',
    },

    popovertabText: {
        /* width: 41px;
        height: 12px; */

        /* Desktop/Button/Primary XS • 400 • 12 */

        fontFamily: typography.fontFamily,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '100%',
        /* identical to box height, or 12px */

        letterSpacing: '-0.01em',

        /* Main/Black */

        color: '#190d1a',
    },

    popoverselectedtabText: {
        fontFamily: typography.fontFamily,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '100%',
        /* identical to box height, or 12px */

        letterSpacing: '-0.01em',

        /* Main/Purple */

        color: `#11734A !important`,
    },
    notificationLists: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '10px 0',
        gap: '20px',
        marginTop: '10px',
        overflowY: 'auto',
        height: '100%',
        width: '100%',
        
    },


    notificationIcon: {
        position: 'relative',
        boxSizing: 'border-box',

        /* Auto layout */

        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
        isolation: 'isolate',

        width: '40px',
        height: '40px',

        /* Main/Purple-Tertiary */

        background: '#f7f2fe',
        /* Main/White */

        border: '1px solid #ffffff',
        borderRadius: '100px',
    },
    badgeIcon: {
        position: 'absolute',

        right: 0,
        top: 0,

        /* Main/Purple */

        background: '#9747ff',
    },

    notificationIconText: {
        fontFamily: typography.fontFamily,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '100%',
        /* or 14px */

        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        letterSpacing: '-0.01em',

        /* Purple / Primary */

        color: '#9747ff',

        height: '40px',
        width: '40px',
        borderRadius: '100%',
    },

    notificationContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0px',
        gap: '12px',
        width: '100%'
    },
    notificationContentData: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0px',
        gap: '2px',
    },

    notificationContentDataTitle: {
        fontFamily: typography.fontFamily,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px !important',
        lineHeight: '135% !important',
        /* or 16px */

        display: 'flex !important',
        alignItems: 'center !important',
        letterSpacing: '-0.01em !important',

        /* Main/Black */

        color: '#190D1A !important',

    },

    notificationContentDataDate: {
        fontFamily: typography.fontFamily,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '10px !important',
        lineHeight: '120% !important',
        /* identical to box height, or 12px */

        display: 'flex !important',
        alignItems: 'center !important',
        letterSpacing: '-0.01em !important',

        /* Main/Dark Gray */

        color: '#434243 !important',
    },

    notificationContentButton: {
        display: 'flex !important',
        flexDirection: 'row',
        alignItems: 'center !important',
        padding: '8px 12px !important',
        gap: '4px !important',


        /* Main/Purple-Tertiary */

        background: '#d6ffee !important',
        borderRadius: '16px !important',
        
    },

    notificationContentButtonLabel: {
        fontFamily: typography.fontFamily,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px !important',
        lineHeight: '100% !important',
        /* identical to box height, or 12px */

        display: 'flex !important',
        alignItems: 'center !important',
        letterSpacing: '-0.01em !important',

        /* Main/Purple */

        color: '#11734A !important',
    }
}
});


const NotificationPopoverMobile = ({
    notifications, setNotifications,
    unreadNotifications, setUnreadNotifications
}) => {
    const classes = useStyles()
    const [selectedTab, handleChangeSelectedTab] = useState("All")
    const { state: outcomeState, } = useContext(UserTypeContext);
    const handleReadNotification = (type, ids = []) => {
        let allIds = [...ids]
        if (type === "ALL") {
            allIds = unreadNotifications.map(it => it._id)
        }
        notificationService.readNotification({
            "Ids": allIds,
            "type": type
        }, outcomeState.token).then(res => {
            if (type === "ALL") {
                handleUnread(allIds)
                setUnreadNotifications([])
                // notificationDispatch(unreadSpecificaNotifications(allIds))
                // notificationDispatch(updateUnreadNotificationData([]));
            } else {
                handleUnread(allIds)
                // notificationDispatch(unreadSpecificaNotifications(allIds))
            }
        }).catch(err => {

        }).finally(() => {

        })
    }
    const handleUnread = (ids) => {
        if (ids && ids.length) {
            let newState = [...unreadNotifications]
            let newNOts = [...notifications]
            newState = [...newState].filter(it => !ids.includes(it._id))
            newNOts = newNOts.map(it => {
                if (ids.includes(it._id)) {
                    it.read = true
                }
                return it
            })
            setNotifications(newNOts)
            setUnreadNotifications(newState)
            
        } 
    }
    return (
        <div className={classes.popoverContainer}>
            <div className={classes.popovertitle}>
                <Typography className={classes.popovertitle1} variant="body2" color="#190D1A">
                    Notifications
                </Typography>
                <Button className={classes.popoverbutton} disabled={!unreadNotifications.length} onClick={() => {
                    handleReadNotification("ALL")
                }}>
                    Mark all as read
                </Button>
            </div>
            <div className={classes.popoverBody}>
                <div className={classes.popoverTabs}>
                    {["All", "Unread"].map(it => (
                        <div className={selectedTab === it ? classes.popoverselectedtab : classes.popovertab} onClick={() => handleChangeSelectedTab(it)}>
                            <Typography className={selectedTab === it ? classes.popoverselectedtabText : classes.popovertabText} variant="body2" color="#190D1A">
                                {it}
                            </Typography>
                        </div>

                    ))}
                </div>
                <div className={classes.notificationLists}>
                    {selectedTab === "All" ? (
                        notifications.map(it => (
                            <NotificationData key={it?.uid} item={it} handleReadNotification={handleReadNotification} />
                        ))
                    ) : null}
                    {selectedTab === "Unread" ? (
                        unreadNotifications.map(it => (
                            <NotificationData key={it?.uid} item={it} handleReadNotification={handleReadNotification} />
                        ))
                    ) : null}

                </div>
            </div>
        </div>
    )

}

export default NotificationPopoverMobile

const NotificationData = ({ item, handleReadNotification }) => {
    const classes = useStyles()
    return (
        <div className={classes.notificationItem} onClick={() => !item.read && handleReadNotification("SPECIFIC", [item._id])}>
            <div className={classes.notificationIcon}>
                <Badge color="primary" variant="dot" className={classes.badgeIcon} sx={{
                    position: "absolute"
                }} invisible={item.read}>

                </Badge>
                {/* <Typography className={classes.notificationIconText} variant="body2" color="#190D1A">
                    {"SSD"}
                </Typography> */}
                <img src={require('./../../assets/images/192.png')} alt="notification" className={classes.notificationIconText} />
            </div>
            <div className={classes.notificationContent}>
                <div className={classes.notificationContentData}>
                    <Typography className={classes.notificationContentDataTitle} variant="body2" color="#190D1A">
                        {item.title}
                    </Typography>
                    <Typography className={classes.notificationContentDataDate} variant="body2" color="#190D1A">
                        {moment(item.createdAt).fromNow()}
                    </Typography>
                </div>
                <Button
                    onClick={() => window.open(item.onClick, "_self")}
                    className={classes.notificationContentButton}
                    sx={{
                        '::after': {
                            height: 0,
                            width: 0
                        }
                    }}
                //   buttonText={[
                //     "Unlock with 1",
                //      <img
                //       style={{ marginLeft: "4px", width: "12px", height: " 12px" }}
                //       src={DiamondCoin}
                //     />,
                //   ]}
                >
                    <Typography className={classes.notificationContentButtonLabel} variant="body2" color="#190D1A">
                        {item.buttonText}
                    </Typography>
                    {/* <img
                        style={{ width: "12px", height: " 12px" }}
                        src={DiamondCoin}
                        alt="icon"
                    /> */}

                </Button>
            </div>
        </div>
    )
}