import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Button, Checkbox, CircularProgress, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@material-ui/core";
import DatePicker from "../../Date/DatePicker";
import palette from "../../../../theme/palette";
import FilterButton from "../../../../assets/images/icon/FilterButton"
import EnergyIcon from "../../../../assets/images/icon/EnergyIcon"
import BlueSky from "../../../../assets/images/icon/BlueSky";
import ActivitySleepQuality from "../../Scaler/ScalerWithSlider";
import DatePickerDetails from "../../Date/DatePickerDetails";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { userService } from "../../../../services/auth";
import LeftGreenArrow from "../../../../assets/images/icon/LeftGreenArrow";
import RightGreenArrow from "../../../../assets/images/icon/RightGreenArrow";
import ReminderContent from "../../ReminderContent/ReminderContent";
import moment from "moment";
import SnackBarComp from "../../../../components/snackBar";
import Info from "../../../../assets/images/icon/Info";
import typography from "../../../../theme/typography";


const useStyles = makeStyles((theme) => ({
  CenterContentOne: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    width: '700px',

    // [theme.breakpoints.up("sm")]: {
    //   width: "290px",
    // },
  },

  subHeadingCenterContent: {
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
    alignItems: "baseline",
    gap: "5px",
  },

  [theme.breakpoints.down("sm")]: {
    activity: {
      marginLeft: "0px",
    },
    impactContainer: {
      flexDirection: "column",
      gap: "0px!important",
    },
    mainProgressBarBad: {
      marginLeft: "0px",
    },
  },
  [theme.breakpoints.down("md")]: {
    impactContainer: {
      gap: "50px",
    },
    mainProgressBarBad: {
      marginLeft: "0px",
    },
    progressBarGood: {
      display: "flex",
      flexDirection: "column",
    },
  },

  mainProgressBarBad: {
    display: "flex",
    marginLeft: "43px",
  },
  progressBarBad: {
    display: "flex",
    flexDirection: "column",
  },
  progressBarNormal: {
    display: "flex",
    flexDirection: "column",
  },
  progressBarGood: {
    display: "flex",
    flexDirection: "column",
  },
  energyContent: {
    marginTop: "16px",
    display: "flex",
    gap: "9px",
  },
  impactContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "24.7vw",
  },
  filterBox: {
    marginTop: "15px",
  },
  weekDayBox: {
    "& p": {
      fontSize: "18px",
      lineHeight: "36px",
      color: "#B7BDB9",
      background: "#EBF2F1",
      border: "1px solid #DCE6E0",
      width: "36px",
      height: "36px",
      textAlign: "center",
      cursor: "pointer",
      "&.active": {
        backgroundColor: "#11734A",
        color: "#F7F9FA",
        border: "1px solid #11734A",
      },
    },
  },
}));

export default function SleepQualityTwo({ isMenuSelected, setIsMenuSelected }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentDateDetails, setCurrentDateDetails] = useState(new Date());
  const [targetValue, setTargetValue] = useState(isMenuSelected?.getInfluenceData?.target);
  const [weaklyReport, setWeaklyReport] = useState(isMenuSelected?.getInfluenceData?.weeklyReport === "YES" ? true : false);
  const [spacificDay, setSpacificDay] = useState(isMenuSelected?.getInfluenceData?.targetDays?.length !== 0);
  const [dayCount, setDayCount] = useState(isMenuSelected?.getInfluenceData?.targetDaysCount || 1)
  const [targetDays, setTargetDays] = useState([]);
  //scaler Value
  const [scalerValue, setScalerValue] = useState([isMenuSelected?.getInfluenceData?.upRange ? isMenuSelected?.getInfluenceData?.upRange * 10 : 10, isMenuSelected?.getInfluenceData?.downRange ? isMenuSelected?.getInfluenceData?.downRange * 10 : 50]);

  //for loading
  const [isLoading, setIsLoading] = useState(false)
  const [isAPiCall, setIsAPiCall] = useState(false)

  // SnackBar
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");

  const [reminderState, setReminderState] = useState({
    reminder: false,
    dayCount: 1,
    every: "WEEK",
    days: ["MONDAY"]
  });

  const [handleOpenSuggestion, setHandleOpenSuggestion] = useState(false)

  // const open = Boolean(anchorEl);
  // const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setHandleOpenSuggestion(true)
  };

  const CompletionSuggestionbox = styled("div")(({ theme }) => ({
    width: "304px",
    background: "#EBF2F1",
    padding: "16px",
  }));


  const MainContent = styled(Box)(({ theme }) => ({
    margin: "40px 0 0 36px",
    display: "flex",
    flexDirection: "column",
    // height: "75vh",
    position: "relative",
    flex: 1,
    // overflow: "auto",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "0px",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: "0px",
      alignItems: "center",
    },
  }));

  const CenterContent = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: "40px",
    marginBottom: "40px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: "0px",
      alignItems: "center",
    },
  }));

  const TypographyHeading = styled(Typography)(({ theme }) => ({
    color: "#3E4240",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      position: "relative",
    },
  }));

  const CalenderButton = styled(Button)(({ theme }) => ({
    color: "#3E4240",
    fontFamily: typography.fontFamily,
    fontWeight: "400",
    fontSize: "16px",
    backgroung: "#DCE5E0",
    borderRadius: " 8px",
    border: "1px solid #DCE6E0",
    textTransform: "none",
    height: "40px",
    padding: "12px",
    width: '200px'
  }));

  //center content 1
  const AvgCounterMainContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      flexWrap: "wrap"
    },
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
  }));
  const RangeBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      gap: "25px",
      alignItems: "center",
    },
  }));

  //center content 2
  const ProgressBarContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    gap: "44px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  }));
  const MainProgressBarBad = styled(Box)(({ theme }) => ({
    display: "flex",
    width: '65%'
  }));

  const BorderLinearProgressBad = styled(LinearProgress)(({ theme }) => ({
    height: 21,
    // width: "10vw",
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: "#F17F79",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  }));
  const BorderLinearProgressNormal = styled(LinearProgress)(({ theme }) => ({
    height: 21,
    // width: "15vw",
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: "#F5DB87",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  }));
  const BorderLinearProgressGood = styled(LinearProgress)(({ theme }) => ({
    height: 21,
    // width: "24vw",
    [`& .${linearProgressClasses.bar}`]: {
      background: "#68D7A8",
    },
    [theme.breakpoints.down("md")]: {
      background: "#68D7A8",
      width: "100%",
    },
  }));

  //center content 3
  const ThirdBarEnergyContainer = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {},
  }));
  const ThirdBarBlueSkyContainer = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {},
  }));

  const AvgCounterContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    marginTop: "15px",
    flexDirection: "column",
  }));

  const SetGoalMainContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "43px",

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: "0px",
      alignItems: "center",
    },
  }));
  const SetAvgGoalMainContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    marginTop: "24px",
  }));

  const ReminderMainContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    marginTop: "18px",
  }));
  const WeeklyReportMainContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    marginTop: "25px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    },
  }));
  const LastMonthModal = styled(Modal)(({ theme }) => ({
    position: "absolute",
    top: "230px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down("md")]: {
      position: "absolute",
      top: "0px",
      background: "rgba(0,0,0, 0.4)",
    },
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      top: "120px",
    },
  }));

  const CustomCheckBox = styled(Checkbox)(({ theme }) => ({
    color: "#DCE6E0",
    borderRadius: "4px",
    paddingLeft: '0px',
    "&.Mui-checked": {
      color: "#11734A",
      borderRadius: "4px",
    },
  }));

  const WeekContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    marginTop: "40px",
    alignItems: "center",
    gap: "100px",
    width: "100%",
  }));
  const WeekContainerOne = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
  }));
  const WeekContainertwo = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    height: "112px"

  }));

  const TimingContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    marginTop: "34px",
    gap: "74px",

    [theme.breakpoints.down("md")]: {
      gap: "12px",
    },
    [theme.breakpoints.down("sm")]: {
      gap: "12px",
      flexWrap: "wrap",
      justifyContent: "center",
    },
  }));
  const TimeBoxOne = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {},
  }));
  const TimeBoxTwo = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down("md")]: {},
  }));
  const TimeBoxThree = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down("md")]: {},
  }));
  const TimeBoxFour = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down("md")]: {},
  }));
  const TimeBoxFive = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down("md")]: {},
  }));
  const SaveGoalBtn = styled(Button)(({ theme }) => ({
    [theme.breakpoints.down("xl")]: {
      marginBottom: "0px!important",
    },
    [theme.breakpoints.up("xl")]: {
      marginBottom: "0px!important",
    },
  }));

  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };

  const [openPopover, setOpenPopover] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const [xposition, setXposition] = useState(0);
  const [yposition, setYposition] = useState(0);

  const popRef = useRef()

  const handleClickPopover = (event) => {
    setXposition(popRef.current.getBoundingClientRect().left)
    setYposition(popRef.current.getBoundingClientRect().top + 40)
    setAnchorEl(event.currentTarget);
    setOpenPopover(true)
  };

  const handlePopOverClose = () => {
    setAnchorEl(null);
    setOpenPopover(false)
  }


  const onCreateInfluence = (e) => {
    const token = localStorage.getItem("userDetails")
    let body;
    if (reminderState.reminder) {
      body = {
        target: parseInt(targetValue),
        weeklyReport: weaklyReport ? "YES" : "NO",
        influenceId: isMenuSelected?.influenceId,
        upRange: (scalerValue[1]) / 10,
        downRange: (scalerValue[0]) / 10,
        type: isMenuSelected?.selectedInfluenceType,
        targetDaysCount: dayCount,
        targetDays: targetDays,
        reminder: [
          {
            every: reminderState?.every,
            count: parseInt(reminderState?.dayCount),
            days: reminderState?.days
          }
        ]
      }
    } else {
      body = {
        target: parseInt(targetValue),
        weeklyReport: weaklyReport ? "YES" : "NO",
        influenceId: isMenuSelected?.influenceId,
        upRange: (scalerValue[1]) / 10,
        downRange: (scalerValue[0]) / 10,
        type: isMenuSelected?.selectedInfluenceType,
        targetDaysCount: dayCount,
        targetDays: targetDays,
      }
    }
    if (isMenuSelected?.influenceData?.type === "TYPE1" || isMenuSelected?.influenceData?.type === "TYPE3") {
      delete body?.upRange
      delete body?.downRange
    }
    if (isMenuSelected?.getInfluenceData?.length === 0 || isMenuSelected?.getInfluenceData === undefined) {
      userService
        .influenceGoalsCreate(body, token)
        .then((data) => {
          setShowSnakbar(true);
          setMsgType("success");
          setSnakbarMsg(data?.message);
          setTimeout(() => {
            setIsMenuSelected({
              ...isMenuSelected,
              getInfluenceData: data?.data
            })
          }, 3000);

        })
        .catch((error) => {
          setShowSnakbar(true);
          setMsgType("error");
          setSnakbarMsg(error.response.data.description);
        });
    } else {
      body = {
        ...body,
        influenceGoalId: isMenuSelected?.getInfluenceData?.influenceGoalId
      }
      delete body.influenceId
      userService
        .updateInfluenceData(body, token)
        .then((data) => {
          setIsAPiCall(true)
          setShowSnakbar(true);
          setMsgType("success");
          setSnakbarMsg(data?.message);
        })
        .catch((error) => {
          setShowSnakbar(true);
          setMsgType("error");
          setSnakbarMsg(error.response.data.description);
        });
    }
  }

  useEffect(() => {
    if ((!isMenuSelected?.influenceData && isMenuSelected.influence) || isAPiCall) {
      const token = localStorage.getItem("userDetails")
      let body;
      body = {
        from: moment().subtract(1, 'months').startOf('month').format('MM-DD-YYYY'),
        to: moment().subtract(1, 'months').endOf('month').format('MM-DD-YYYY'),
        influenceId: isMenuSelected?.influenceId,
        // type: "TYPE1"
        type: isMenuSelected?.selectedInfluenceType
      }
      setIsLoading(true)
      userService
        .influenceGoal(body, token)
        .then((data) => {
          setIsMenuSelected({
            ...isMenuSelected,
            influenceData: data?.influence,
            getInfluenceData: data?.data[0],
            selectedInfluenceAvgData: data?.average,
            selectedInfluancepPercentData: data?.percent
          })
          setShowSnakbar(true);
          setMsgType("success");
          setSnakbarMsg("Successful");
        })
        .catch((error) => {
          setShowSnakbar(true);
          setMsgType("error");
          setSnakbarMsg("Something Went wrong");
        }).finally(() => {
          setIsLoading(false)
        })
    }
  }, [isMenuSelected?.influenceId, isAPiCall])

  useEffect(() => {
    if (!!isMenuSelected?.getInfluenceData?.reminder?.length) {
      setReminderState({
        ...reminderState,
        reminder: isMenuSelected?.getOutComeData?.reminder?.length !== 0,
        dayCount: isMenuSelected?.getOutComeData?.reminder?.length !== 0 ? isMenuSelected?.getInfluenceData?.reminder[0]?.count : 1,
        every: isMenuSelected?.getOutComeData?.reminder?.length !== 0 ? isMenuSelected?.getInfluenceData?.reminder[0]?.every : "WEEK",
        days: isMenuSelected?.getOutComeData?.reminder?.length !== 0 ? isMenuSelected?.getInfluenceData?.reminder[0]?.days : ["MONDAY"]
      })
    }
    if (isMenuSelected?.getInfluenceData?.targetDays) {
      setTargetDays(isMenuSelected?.getInfluenceData?.targetDays)
    }
  }, [isMenuSelected])

  const validationOnTarget = (value) => {
    if (isMenuSelected?.selectedInfluenceType == "TYPE2" && (Number(value) > 5 || Number(value) < 0))
      return
    else if ((isMenuSelected?.selectedInfluenceType == "TYPE1" || isMenuSelected?.selectedInfluenceType == "TYPE3") && (Number(value) > 7 || Number(value) < 0))
      return
    else if ((isMenuSelected?.selectedInfluenceType == "TYPE12") && (Number(value) < 0))
      return
    else
      setTargetValue(value);
  }

  const placeholderOnTarget = () => {
    if (isMenuSelected?.selectedInfluenceType == "TYPE2") {
      return '1-5'
    } else if ((isMenuSelected?.selectedInfluenceType == "TYPE1" || isMenuSelected?.selectedInfluenceType == "TYPE3")) {
      return '1-7'
    }
    else
      return 'add value'
  }

  const selectDays = (index) => {
    if (index === 0) {
      if (targetDays?.includes("MONDAY")) {
        const updatedArray = targetDays.filter((ele) => {
          return ele !== "MONDAY"
        })
        setTargetDays(updatedArray)
      } else {
        setTargetDays([...targetDays, "MONDAY"])
      }
    } else if (index === 1) {
      if (targetDays?.includes("TUESDAY")) {
        const updatedArray = targetDays.filter((ele) => {
          return ele !== "TUESDAY"
        })
        setTargetDays(updatedArray)
      } else {
        setTargetDays([...targetDays, "TUESDAY"])
      }
    } else if (index === 2) {
      if (targetDays?.includes("WEDNESDAY")) {
        const updatedArray = targetDays.filter((ele) => {
          return ele !== "WEDNESDAY"
        })
        setTargetDays(updatedArray)
      } else {
        setTargetDays([...targetDays, "WEDNESDAY"])
      }
    } else if (index === 3) {
      if (targetDays?.includes("THURSDAY")) {
        const updatedArray = targetDays.filter((ele) => {
          return ele !== "THURSDAY"
        })
        setTargetDays(updatedArray)
      } else {
        setTargetDays([...targetDays, "THURSDAY"])
      }
    } else if (index === 4) {
      if (targetDays?.includes("FRIDAY")) {
        const updatedArray = targetDays.filter((ele) => {
          return ele !== "FRIDAY"
        })
        setTargetDays(updatedArray)
      } else {
        setTargetDays([...targetDays, "FRIDAY"])
      }
    } else if (index === 5) {
      if (targetDays?.includes("SATURDAY")) {
        const updatedArray = targetDays.filter((ele) => {
          return ele !== "SATURDAY"
        })
        setTargetDays(updatedArray)
      } else {
        setTargetDays([...targetDays, "SATURDAY"])
      }
    } else if (index === 6) {
      if (targetDays?.includes("SUNDAY")) {
        const updatedArray = targetDays.filter((ele) => {
          return ele !== "SUNDAY"
        })
        setTargetDays(updatedArray)
      } else {
        setTargetDays([...targetDays, "SUNDAY"])
      }
    }
  }

  const applayClassName = (index) => {
    if (index === 0) {
      if (targetDays.includes("MONDAY")) {
        return "active"
      } else {
        return ""
      }
    } else if (index === 1) {
      if (targetDays.includes("TUESDAY")) {
        return "active"
      } else {
        return ""
      }
    } else if (index === 2) {
      if (targetDays.includes("WEDNESDAY")) {
        return "active"
      } else {
        return ""
      }
    } else if (index === 3) {
      if (targetDays.includes("THURSDAY")) {
        return "active"
      } else {
        return ""
      }
    } else if (index === 4) {
      if (targetDays.includes("FRIDAY")) {
        return "active"
      } else {
        return ""
      }
    } else if (index === 5) {
      if (targetDays.includes("SATURDAY")) {
        return "active"
      } else {
        return ""
      }
    } else if (index === 6) {
      if (targetDays.includes("SUNDAY")) {
        return "active"
      } else {
        return ""
      }
    }
  }
  return (
    <>
      {
        isLoading ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "calc(100vw - 30vw)", height: '100%' }}>
          <CircularProgress color="success" />
        </div> :
          <MainContent>
            <TypographyHeading variant="h3">
              Set a Goal: {isMenuSelected?.influenceData?.name}
            </TypographyHeading>
            <SnackBarComp
              showSnakbar={showSnakbar}
              autoHideDuration={6000}
              handleCloseSnakbar={handleCloseSnakbar}
              snakbarMsg={snakbarMsg}
              type={msgType ? msgType : "info"}
            />
            <CenterContent>
              <div className={classes.CenterContentOne}>
                <Typography variant="body1" sx={{ color: palette.primary.light }}>
                  Current state
                </Typography>
                <CalenderButton onClick={handleClickPopover} ref={popRef}>
                  <Typography
                    variant="caption"
                    sx={{ color: palette.primary.light }}
                  >
                    {localStorage.getItem("selectedRadio") !== null ? localStorage.getItem("selectedRadio") === "customRange" ? "Custom Range" : `Last ${localStorage.getItem("selectedRadio")}` : "Last week"}
                  </Typography>
                  <KeyboardArrowDownIcon sx={{ color: "#11734A" }} />
                </CalenderButton>

                <Popover
                  sx={{ maxHeight: "calc(100vh - 190px)" }}
                  open={openPopover}
                  onClose={handlePopOverClose}
                  anchorEl={anchorEl}

                  anchorReference="anchorPosition"
                  anchorPosition={{ top: yposition, left: xposition }}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <DatePicker
                    outcomeId={isMenuSelected?.outcomeId}
                    isMenuSelected={isMenuSelected}
                    setIsMenuSelected={setIsMenuSelected}
                  />
                </Popover>
              </div>
              {
                isMenuSelected?.influenceData?.type === "TYPE1" || isMenuSelected?.influenceData?.type === "TYPE3" ? (
                  <>
                    <TimingContainer>
                      <TimeBoxOne>
                        <Typography
                          sx={{ display: "flex", gap: "4px" }}
                          variant="heading"
                        >
                          {isMenuSelected?.selectedInfluenceAvgData?.length > 0 ? `${isMenuSelected?.selectedInfluenceAvgData[0]?.avg?.toFixed(1)}%` : "N/A"}
                          {/* <Typography sx={{ marginTop: "6px" }} variant="subtitle1">
                            %
                          </Typography> */}
                        </Typography>
                        <Typography sx={{ marginTop: "15px" }} variant="h5">
                          Current target
                        </Typography>
                      </TimeBoxOne>
                      <TimeBoxTwo>
                        <Typography
                          sx={{ display: "flex", gap: "4px" }}
                          variant="heading"
                        >
                          {isMenuSelected?.selectedInfluancepPercentData?.completePercentage === null ? "N/A" : isMenuSelected?.selectedInfluancepPercentData?.completePercentage}
                          <Typography sx={{ marginTop: "6px" }} variant="subtitle1">
                            {isMenuSelected?.selectedInfluancepPercentData?.completePercentage !== null && "%"}
                          </Typography>
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "15px",
                            gap: "4px",
                          }}
                          variant="h5"
                        >
                          Completion
                          <div className="popOverBox">
                            <Typography onClick={handleClick}> <Info /></Typography>
                            <Popover
                              className="test"
                              open={handleOpenSuggestion}
                              anchorEl={anchorEl}
                              onClose={() => { setHandleOpenSuggestion(false) }}
                              anchorReference="anchorPosition"
                              anchorPosition={{ top: 160, left: 530 }}
                              anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'left',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                            >
                              <CompletionSuggestionbox>
                                <Typography variant="heading2" sx={{ color: "black" }}>this is completion rate that would be success for you. It's better not to aim for 100% consistency from the beginning because it is difficult to achieve and prone to backfire by creating unrealistic expectations and demotivating you.</Typography>
                              </CompletionSuggestionbox>
                            </Popover>
                          </div>
                        </Typography>
                      </TimeBoxTwo>
                      <TimeBoxThree>
                        <Typography
                          sx={{ display: "flex", gap: "4px" }}
                          variant="heading"
                        >
                          {isMenuSelected?.selectedInfluancepPercentData?.totalDays === null ? "N/A" : isMenuSelected?.selectedInfluancepPercentData?.totalDays}
                          <Typography sx={{ marginTop: "6px" }} variant="subtitle1">
                            {isMenuSelected?.selectedInfluancepPercentData?.totalDays !== null && "days"}
                          </Typography>
                        </Typography>
                        <Typography sx={{ marginTop: "15px" }} variant="h5">
                          Total days
                        </Typography>
                      </TimeBoxThree>
                      <TimeBoxFour>
                        <Typography
                          sx={{ display: "flex", gap: "4px" }}
                          variant="heading"
                        >
                          {isMenuSelected?.selectedInfluancepPercentData?.weeks} out of {isMenuSelected?.selectedInfluancepPercentData?.totalWeek} weeks
                        </Typography>
                        <Typography sx={{ marginTop: "15px" }} variant="h5">
                          Weeks completed
                        </Typography>
                      </TimeBoxFour>
                      <TimeBoxFive>
                        <Typography
                          sx={{ display: "flex", gap: "4px" }}
                          variant="heading"
                        >
                          {isMenuSelected?.selectedInfluancepPercentData?.perWeekAvg === null ? "N/A" : isMenuSelected?.selectedInfluancepPercentData?.perWeekAvg}
                          <Typography sx={{ marginTop: "6px" }} variant="subtitle1">
                            {isMenuSelected?.selectedInfluancepPercentData?.perWeekAvg !== null && "days/week"}
                          </Typography>
                        </Typography>
                        <Typography sx={{ marginTop: "15px" }} variant="h5">
                          Avg. completion per week
                        </Typography>
                      </TimeBoxFive>
                    </TimingContainer>
                  </>
                ) : (
                  <>
                    <AvgCounterMainContainer>
                      <RangeBox>
                        <AvgCounterContainer>
                          <Typography
                            variant="h3"
                            sx={{ fontWeight: "600", color: palette.primary.light }}
                          >
                            {isMenuSelected?.selectedInfluenceAvgData?.length > 0 ? isMenuSelected?.selectedInfluenceAvgData[0]?.avg?.toFixed(1) : "N/A"}
                          </Typography>

                          <Typography
                            variant="h5"
                            sx={{ color: palette.secondary.contrast, lineHeight: "115%", margin: "15px 0 0 0", width: "108px!important", }}
                          >
                            Your avg.in last month{" "}
                          </Typography>
                        </AvgCounterContainer>

                        <div className={classes.filterBox}>
                          <FilterButton />
                          <Typography variant="h5">Success range</Typography>
                        </div>
                      </RangeBox>
                      <div>
                        <ActivitySleepQuality isMenuSelected={isMenuSelected} setIsMenuSelected={setIsMenuSelected} scalerValue={scalerValue} setScalerValue={setScalerValue} />
                      </div>
                    </AvgCounterMainContainer>

                    <ProgressBarContainer>
                      <div>
                        <div style={{ width: '180px' }}>
                          <Typography variant="subtitle2">{
                            isMenuSelected?.getInfluenceData?.targetDays?.map((day,index)=>{
                              const str = day?.charAt(0)?.toUpperCase() + day?.substring(1, 3)?.toLowerCase()
                              return index < 3 && `${str}${index === isMenuSelected?.getInfluenceData?.targetDays?.length - 1  ? "" : "/"}`
                            })
                          }</Typography>
                          <Typography variant="subtitle2" sx={{ marginTop: '10px' }}>{
                            isMenuSelected?.getInfluenceData?.targetDays?.map((day,index)=>{
                              const str = day?.charAt(0)?.toUpperCase() + day?.substring(1, 3)?.toLowerCase()
                              return index >= 3 && `${str}${index === isMenuSelected?.getInfluenceData?.targetDays?.length - 1  ? "" : "/"}`
                            })
                          }</Typography>
                        </div>
                        <Typography
                          sx={{ marginTop: "8px", width: "106px", height: "36px" }}
                          variant="h5"
                        >
                          Specific days tracked
                        </Typography>
                      </div>

                      {/* progrees bar */}
                      <MainProgressBarBad>
                        <div style={{ display: 'flex', flexDirection: "column", width: '100%' }}>
                          <div style={{ display: 'flex', gap: '10px', alignItems: "center", justifyContent: "space-between" }}>
                            <div className={classes.progressBarBad}>
                              <Typography variant="subtitle2">{isMenuSelected?.selectedInfluancepPercentData?.downPercentage === null ? 0 : isMenuSelected?.selectedInfluancepPercentData?.downPercentage?.toFixed(1)}%</Typography>
                              <div style={{ display: "flex", gap: "8px", marginTop: "4px" }}>
                                <Typography variant="h5" sx={{ color: "#F17F79" }}>
                                  Bad
                                </Typography>
                                <Typography variant="h5"> {isMenuSelected?.selectedInfluancepPercentData?.downDays?.length} days</Typography>
                              </div>

                              {/* <BorderLinearProgressBad  sx={{ width: '100px' }} variant="determinate" value={100} /> */}
                            </div>

                            <div className={classes.progressBarNormal}>
                              <Typography variant="subtitle2"> {isMenuSelected?.selectedInfluancepPercentData?.midPercentage === null ? 0 : isMenuSelected?.selectedInfluancepPercentData?.midPercentage?.toFixed(1)}% </Typography>
                              <div style={{ display: "flex", gap: "8px", marginTop: "4px" }}>
                                <Typography variant="h5" sx={{ color: "#F5B62F" }}>
                                  Normal
                                </Typography>
                                <Typography variant="h5"> {isMenuSelected?.selectedInfluancepPercentData?.midDays?.length} days</Typography>
                              </div>
                              {/* <div>
                            <BorderLinearProgressNormal
                              variant="determinate"
                              value={100}
                              sx={{ width: '100px' }}
                            />
                          </div> */}
                            </div>
                            <div className={classes.progressBarGood}>
                              <div
                                style={{ display: "flex", }}
                              >
                                <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                                  <div style={{ display: "flex", gap: "8px" }}>
                                    <Typography variant="subtitle2"> {isMenuSelected?.selectedInfluancepPercentData?.upPercentage === null ? 0 : isMenuSelected?.selectedInfluancepPercentData?.upPercentage?.toFixed(1)}%</Typography>
                                    <Typography variant="subtitle2">Completion</Typography>
                                  </div>
                                  <div
                                    style={{ display: "flex", gap: "8px", marginTop: "4px" }}
                                  >
                                    <Typography variant="h5" sx={{ color: "#169C63" }}>
                                      Good
                                    </Typography>
                                    <Typography variant="h5"> {isMenuSelected?.selectedInfluancepPercentData?.upDays?.length} days</Typography>
                                  </div>
                                </div>
                                <div style={{ marginLeft: '20px' }}>
                                  <PopupState variant="popover" popupId="demo-popup-popover">
                                    {(popupState) => (
                                      <div>
                                        <CalenderButton {...bindTrigger(popupState)}>
                                          <Typography
                                            variant="caption"
                                            sx={{ color: palette.primary.light }}
                                          >
                                            Details
                                          </Typography>
                                          <KeyboardArrowDownIcon sx={{ color: "#11734A" }} />
                                        </CalenderButton>
                                        <Popover
                                          {...bindPopover(popupState)}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                          }}
                                          transformOrigin={{
                                            vertical: "top",
                                            horizontal: "left",
                                          }}
                                        >
                                          <DatePickerDetails
                                            currentDate={currentDate}
                                            setCurrentDate={setCurrentDate}
                                          />
                                        </Popover>
                                      </div>
                                    )}
                                  </PopupState>
                                </div>

                                <LastMonthModal
                                  open={open}
                                  onClose={handleClose}
                                  stylelose={handleClose}
                                  ariaLabelledby="modal-modal-title"
                                  aria-describedby="modal-modal-description"
                                >
                                  <DatePickerDetails
                                    currentDate={currentDateDetails}
                                    setCurrentDate={setCurrentDateDetails}
                                  />
                                </LastMonthModal>
                              </div>
                              {/* <div>
                            <BorderLinearProgressGood  sx={{ width: '100px' }} variant="determinate" value={100} />
                          </div> */}
                            </div>
                          </div>
                          <div style={{ display: 'flex', width: '100%' }}>
                            <BorderLinearProgressBad sx={{ width: `${isMenuSelected?.selectedInfluancepPercentData?.downPercentage === null ? 0 : isMenuSelected?.selectedInfluancepPercentData?.downPercentage}%` }} variant="determinate" value={100} />
                            <BorderLinearProgressNormal
                              variant="determinate"
                              value={100}
                              sx={{ width: `${isMenuSelected?.selectedInfluancepPercentData?.midPercentage === null ? 0 : isMenuSelected?.selectedInfluancepPercentData?.midPercentage}%` }}
                            />
                            <BorderLinearProgressGood sx={{ width: `${isMenuSelected?.selectedInfluancepPercentData?.upPercentage === null ? 0 : isMenuSelected?.selectedInfluancepPercentData?.upPercentage}%` }} variant="determinate" value={100} />
                          </div>
                        </div>
                      </MainProgressBarBad>
                    </ProgressBarContainer>
                  </>
                )
              }
              <div style={{ marginTop: "26px" }}>
                <Typography variant="subtitle1">Also impacts on...</Typography>
              </div>
              <div className={classes.impactContainer}>
                <ThirdBarEnergyContainer>
                  <div className={classes.energyContent}>
                    <EnergyIcon />
                    <Typography variant="subtitle2">Energy</Typography>
                  </div>
                  <div
                    style={{ display: "flex", gap: "8px", margin: "8px 0 0 28px" }}
                  >
                    <Typography variant="h5">Regression </Typography>
                    <Typography variant="caption">0.6 </Typography>
                  </div>
                </ThirdBarEnergyContainer>

                <ThirdBarBlueSkyContainer>
                  <div className={classes.energyContent}>
                    <BlueSky />
                    <Typography variant="subtitle2">Happiness</Typography>
                  </div>
                  <div
                    style={{ display: "flex", gap: "8px", margin: "8px 0 0 28px" }}
                  >
                    <Typography variant="h5">Correlation </Typography>
                    <Typography variant="caption">.05 </Typography>
                  </div>
                </ThirdBarBlueSkyContainer>
              </div>

              <SetGoalMainContainer>
                <Typography variant="body1" sx={{ color: palette.primary.light }}>
                  Set a goal
                </Typography>

                <SetAvgGoalMainContainer>
                  <Typography
                    variant="caption"
                    sx={{ color: palette.primary.light }}
                  >
                    Set your target avg
                  </Typography>
                  <TextField
                    id="one"
                    sx={{ width: "95px !important" }}
                    className="fillTextInput"
                    value={targetValue}
                    name="dayCount"
                    placeholder={placeholderOnTarget()}
                    type="number"
                    variant="outlined"
                    inputProps={{ inputProps: { min: 1, max: 5 } }}
                    onChange={(e) => validationOnTarget(e.target.value)}
                  // onChange={(e) => setTargetValue(e.target.value)}
                  />
                </SetAvgGoalMainContainer>

                <WeekContainer>
                  <WeekContainerOne>
                    <Typography variant="caption">
                      Target number of day of hitting this goal
                    </Typography>
                    <div style={{ display: "flex", gap: "11.66px" }}>
                      <Typography
                        variant="subtitle2"
                        sx={{ color: "#11734A", marginTop: "20px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "12px",
                          }}
                        >
                          <div onClick={(e) => {
                            if (dayCount !== 1) {
                              setDayCount(prev => prev - 1)
                            }
                          }
                          }
                          >
                            <LeftGreenArrow />
                          </div>
                          {dayCount}
                          <div onClick={(e) => {
                            if (dayCount !== 7) {
                              setDayCount(prev => prev + 1)
                            }
                          }
                          }>
                            <RightGreenArrow />
                          </div>
                        </div>
                      </Typography>
                      <Typography sx={{ marginTop: "20px" }} variant="h5">
                        days of a week
                      </Typography>
                    </div>
                  </WeekContainerOne>
                  <WeekContainertwo>
                    <div>
                      <CustomCheckBox checked={spacificDay} onChange={(e) => { setSpacificDay(e.target.checked) }} />
                      <Typography variant="caption">Only specific days</Typography>
                    </div>
                    {
                      spacificDay && <Box
                        display="flex"
                        alignItems="center"
                        className={classes.weekDayBox}
                      >
                        {
                          ['M', 'T', 'W', 'T', 'F', 'S', 'S'].map((day, index) => {
                            return <p
                              className={applayClassName(index)}
                              key={index}
                              onClick={() => selectDays(index)}
                            >
                              {day}
                            </p>
                          })
                        }
                      </Box>
                    }

                  </WeekContainertwo>
                </WeekContainer>

                <ReminderMainContainer>
                  <CustomCheckBox checked={reminderState?.reminder}
                    onChange={e => {
                      setReminderState({
                        ...reminderState,
                        reminder: e.target.checked
                      });
                    }}
                  />
                  <Typography
                    variant="caption"
                    sx={{ color: palette.primary.light }}
                    code
                  >
                    Reminder
                  </Typography>
                </ReminderMainContainer>

                {
                  reminderState?.reminder &&
                  <ReminderContent reminderState={reminderState} setReminderState={setReminderState} />
                }

                <WeeklyReportMainContainer>
                  <CustomCheckBox checked={weaklyReport}
                    onChange={e => setWeaklyReport(e.target.checked)} />
                  <Typography
                    variant="caption"
                    sx={{ color: palette.primary.light }}
                  >
                    Included in weekly report
                  </Typography>
                </WeeklyReportMainContainer>
              </SetGoalMainContainer>

              <SaveGoalBtn
                type="submit"
                variant="contained"
                className="button-after outcomeButton"
                sx={{ mt: "39px", width: "240px !important" }}
                disabled={!targetValue}
                onClick={onCreateInfluence}
              >
                Save goal
              </SaveGoalBtn>
            </CenterContent>
          </MainContent>
      }
    </>
  );
}
