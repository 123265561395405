import React, { useContext, useState } from "react";
import { Box, Grid, styled } from "@mui/material";
import useStyles from "../trackerNewV2/TrackingClasses";
import { setSidebarExpand } from "../../store/actions/trackerAction";
import SideBar from "../../components/SideBar";
import TrackHeader from "../tracker/TrackHeader";
import { TrackArchiveTitle } from "../tracker/TrackTitle";
import ArchiveItemTable from "../tracker/ArchiveItemTable";
import { TrackingContext } from "../../context/userTracking";
const RootStyle = styled("div")(({ theme }) => ({
  padding: "0px!important",
  overflowX: "hidden",
  overflowY: "auto",
  [theme.breakpoints.down("lg")]: {
    padding: "40px 20px 0px",
  },
  [theme.breakpoints.down(1001)]: {
    padding: "20px",
  },
}));
const ArchiveView = () => {
  const classes = useStyles();
  const { state: ITrackerState, dispatch: trackingDispatch } =
    useContext(TrackingContext);
  const { isExpandable, isArchiveMode } = ITrackerState;

  return (
    <Grid
      container
      columns={16}
      height={"100vh"}
      className="tracking_container"
    >
      {/* START LEFT SIDEBAR */}
      <Grid
        item
        width={isExpandable ? "90px" : "220px"}
        sx={{ borderRight: "1px solid #DCE6E0", height: "100vh", position: "relative" }}
      >
        <SideBar
          isExpandMode={isExpandable}
          setIsExpandMode={(val) => {
            trackingDispatch(setSidebarExpand(val));
            // userTypeDispatch(isExpandMode(val))
          }}
        />
      </Grid>
      {/* END SIDEBAR */}

      {/* START CONTENT SECTION */}
      <Grid
        item
        // xs={isExpandable ? 15.27 : 13.96}
        sx={{
          flexDirection: "column",
          background: "transparent !important",
          height: "100vh",
          width: isExpandable ?  "calc(100vw - 90px)"  :  "calc(100vw - 220px)"
        }}
      >
        <RootStyle>
          <TrackHeader setIsArchiveMode={true} />

          <Box
            sx={{
              borderRadius: "20px",
              background: "#FFF",
              margin: "32px 20px 20px 24px",
              height: "auto",
              // minHeight: "calc(100vh - 170px)",
              // overflowY: 'auto'
            }}
          >
            <TrackArchiveTitle
              setIsArchiveMode={true}
              isArchiveMode={isArchiveMode}
            />

            {/* CALENDER VIEW */}

            <ArchiveItemTable />
          </Box>
        </RootStyle>

        {/* PAGE HEADING */}
      </Grid>
      {/* END CONTENT SECTION */}
    </Grid>
  );
};

export default ArchiveView;
