import { Autocomplete, Box, MenuItem, Paper, TextField } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { baseEndpoints } from "../../services/config";
import NewButton from "../../components/NewButton";
import { userService } from "../../services/auth";
import CommonTypo from "../../components/CommonTypo";
import { COUNTRIES } from "../../utils/countryList";
import useStyles from "./settingclasses";
import { UserTypeContext } from "../../context/userStatus";
import moment from "moment";
import { addOutcomePopup } from "../../store/actions/userTypeAction";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'; // Custom icon
const DummyUser = require("../../assets/images/userDummy.png");

const days = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']
const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
const startYear = 1950;
const currentYear = new Date().getFullYear();
const years = Array.from({ length: currentYear - startYear + 1 }, (_, i) => startYear + i);

const ProfileSetting = ({
    setShowSnakbar,
    setSnakbarMsg,
    setMsgType
}) => {
    const classes = useStyles()
    const { state: outcomeState, dispatch: userTypeDispatch } =
        useContext(UserTypeContext);
    let userValues = JSON.parse(localStorage.getItem("userValues"));
    const [FirstName, setFirstName] = useState("");
    const [LastName, setLastName] = useState("");
    const [Gender, setGender] = useState("Not Updated");
    const [Country, setCountry] = useState(null);
    const [day, setDay] = useState(null);
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);
    const [DOB, setDOB] = useState(null);
    const [Email, setEmail] = useState("");
    const [ImageFile, setImageFile] = useState(null);
    const [Uploaded, setUploaded] = useState(false);
    const hiddenFileInput = React.useRef(null);
    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };
    useEffect(() => {
        getUserDetails()
    }, [])
    const getUserDetails = () => {
        const userId = {
            uid: outcomeState?.userId
                ? outcomeState?.userId
                : userValues?.uid
                    ? userValues?.uid
                    : localStorage.getItem("userId"),
        };
        userService
            .getUserById(userId)
            .then((data: any) => {
                setFirstName(data?.data.first_name);
                setLastName(data?.data.last_name);
                setEmail(data?.data.user_email);
                setGender(data?.data?.gender)
                setDOB(data?.data?.dob);
                if (data?.data?.dob) {
                    setDay(moment(data?.data?.dob).date())
                    setMonth(moment(data?.data?.dob).month() + 1)
                    setYear(moment(data?.data?.dob).year())
                }
                if (data?.data?.country && data?.data?.country !== "Not Updated") {
                    setCountry(data?.data?.country);
                }
                data?.data?.imageUrl !== "Not Updated"
                    ? setImageFile(data?.data?.imageUrl)
                    : setImageFile(null);
                localStorage.setItem("userValues", JSON.stringify(data?.data));
            })
            .catch((error) => {
                console.log("User profile Error");
            });
    };
    const setUserImage = () => {
        const token = localStorage.getItem("userDetails");
        var bodyFormData = new FormData();
        bodyFormData.append("photo", ImageFile);
        userService
            .setUserImage(bodyFormData, token)
            .then((data: any) => {
                setMsgType("success");
                setShowSnakbar(true);
                setSnakbarMsg(data.description);
            })
            .catch((error) => {
                console.log("User profile Error");
                setMsgType("error");
                setShowSnakbar(true);
                setSnakbarMsg(error);
            });
    };
    const deleteProfileImage = () => {
        userService
            .deleteProfileImage()
            .then((data: any) => {
                setMsgType("success");
                setShowSnakbar(true);
                setSnakbarMsg(data.description);
                setImageFile(null);
            })
            .catch((error) => {
                setMsgType("error");
                setShowSnakbar(true);
                setSnakbarMsg(error);
            });
    };
    const setUserInfo = () => {
        const token = localStorage.getItem("userDetails");

        let body = {
            first_name: FirstName,
            last_name: LastName,
            country: Country,
            gender: Gender,
            dob: day && month && year ? moment().set({ date: Number(day), month: Number(month) - 1, year: Number(year) }).format("MM-DD-YYYY") : null,
        };

        userService
            .setUserInfo(body, token)
            .then((data: any) => {
                setMsgType("success");
                setShowSnakbar(true);
                setSnakbarMsg(data.description);
            })
            .catch((error) => {
                console.log("User profile Error");
                setMsgType("error");
                setShowSnakbar(true);
                setSnakbarMsg(error);
            });
    };
    return <Box display={"flex"} alignItems={"flex-start"} gap={"32px"}>
        <Box display={"flex"} alignItems={"center"} flexDirection={"column"} gap={"16px"}>
            <img
                alt="user"
                src={
                    ImageFile
                        ? Uploaded
                            ? URL.createObjectURL(ImageFile)
                            : `${baseEndpoints.getImage}${ImageFile}`
                        : DummyUser
                }
                style={{
                    height: 88,
                    width: 88,
                    borderRadius: "999px",
                }}
            />
            <input
                type="file"
                ref={hiddenFileInput}
                style={{ display: "none" }}
                onChange={(event) => {
                    setImageFile(event.target.files[0]);
                    setUploaded(true);
                }}
            />
            <NewButton buttonText="Upload" borderRadius={"20px !important"} padding="16px" extraSx={{
                background: "var(--color-border)",
                border: "none",
                width: "102px",
                height: "43px",
                marginBottom: "0px !important",
                "&:hover": {
                    background: "var(--color-border)"
                },
                "&.MuiButton-outlined": {
                    border: `none`,
                },
                "&.MuiButton-contained": {
                    border: `none`,
                },
            }} boxSx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }} fontSize={`16px`} fontWeight={600} typographySx={{
                fontSize: "16px !important",
                lineHeight: "16px !important",
                fontWeight: `600 !important`,
                letterSpacing: "2% important"
            }} onClick={handleClick} />
            {ImageFile ? (
                <NewButton buttonText="Remove" borderRadius={"20px !important"} padding="16px" extraSx={{
                    background: "var(--color-border)",
                    border: "none",
                    width: "102px",
                    height: "43px",
                    marginBottom: "0px !important",
                    "&:hover": {
                        background: "var(--color-border)"
                    },
                    "&.MuiButton-outlined": {
                        border: `none`,
                    },
                    "&.MuiButton-contained": {
                        border: `none`,
                    },
                }} boxSx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }} fontSize={`16px`} fontWeight={600} typographySx={{
                    fontSize: "16px !important",
                    lineHeight: "16px !important",
                    fontWeight: `600 !important`,
                    letterSpacing: "2% important"
                }} onClick={deleteProfileImage} />
            ) : null}
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={"16px"} width={"100%"}>
            <Box display={"flex"} gap={"8px"}>
                <Box display={"flex"} flexDirection={"column"} gap={"8px"} flex={"calc(100% / 3)"}>
                    <CommonTypo size={16} fontWeight={600} color="var(--color-text-primary)">First name</CommonTypo>
                    <TextField placeholder="First Name" sx={{
                        border: "1px solid var(--color-border)",
                        alignSelf: "stretch",
                        borderRadius: "12px",
                        height: "50px",
                        marginBottom: "0px !important",
                        "& .MuiInputBase-root": {
                            borderRadius: "12px !important",
                            height: "50px",
                            "& .MuiInputBase-input": {
                                borderRadius: "12px",
                                height: "50px",
                                padding: "16px !important",
                                "&::placeholder": {
                                    color: "var(--color-text-primary)",
                                    opacity: 1,
                                    fontSize: "16px"
                                }
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                display: "none"
                            }
                        }
                    }} value={FirstName}
                        onChange={(e) => setFirstName(e.target.value)} />
                </Box>
                <Box display={"flex"} flexDirection={"column"} gap={"8px"} flex={"calc(100% / 3)"}>
                    <CommonTypo size={16} fontWeight={600} color="var(--color-text-primary)">Last name</CommonTypo>
                    <TextField placeholder="Last Name" sx={{
                        border: "1px solid var(--color-border)",
                        alignSelf: "stretch",
                        borderRadius: "12px",
                        height: "50px",
                        marginBottom: "0px !important",
                        "& .MuiInputBase-root": {
                            borderRadius: "12px !important",
                            height: "50px",
                            "& .MuiInputBase-input": {
                                borderRadius: "12px",
                                height: "50px",
                                padding: "16px !important",
                                "&::placeholder": {
                                    color: "var(--color-text-primary)",
                                    opacity: 1,
                                    fontSize: "16px"
                                }
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                display: "none"
                            }
                        }
                    }} value={LastName}
                        onChange={(e) => setLastName(e.target.value)} />
                </Box>
                <Box display={"flex"} flexDirection={"column"} gap={"8px"} flex={"calc(100% / 3)"}>
                    <CommonTypo size={16} fontWeight={600} color="var(--color-text-primary)">Gender</CommonTypo>
                    <TextField
                        sx={{
                            border: "1px solid var(--color-border)",
                            alignSelf: "stretch",
                            borderRadius: "12px",
                            marginBottom: "0px !important",
                            height: "50px",
                            "& .MuiInputBase-root": {
                                borderRadius: "12px !important",
                                height: "48px",
                                "& .MuiInputBase-input": {
                                    borderRadius: "12px",
                                    padding: "16px !important",
                                    "&::placeholder": {
                                        color: "var(--color-text-primary)",
                                        opacity: 1,
                                        fontSize: "16px"
                                    }
                                },
                                "& .MuiSelect-select": {
                                    alignItems: "center",
                                    display: "inline-flex"
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    display: "none"
                                }
                            }
                        }}
                        fullWidth
                        select
                        value={Gender || ""}
                        onChange={(e) => {
                            setGender(e.target.value);
                        }}
                        SelectProps={{
                            IconComponent: KeyboardArrowDownIcon, // Replace with your custom icon
                            displayEmpty: true
                        }}
                    >
                        <MenuItem key={"Not Updated"} value={"Not Updated"}>
                            {"Gender"}
                        </MenuItem>
                        {[
                            {
                                label: "Male",
                                value: "male"
                            },
                            {
                                label: "Female",
                                value: "female"
                            }
                        ]?.map((option: any) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
            </Box>
            <Box display={"flex"} gap={"8px"}>
                <Box display={"flex"} flexDirection={"column"} gap={"8px"} flex={"calc(100%)"}>
                    <CommonTypo size={16} fontWeight={600} color="var(--color-text-primary)">E-mail</CommonTypo>
                    <TextField placeholder="E-mail" sx={{
                        border: "1px solid var(--color-border)",
                        alignSelf: "stretch",
                        borderRadius: "12px",
                        height: "50px",
                        marginBottom: "0px !important",
                        "& .MuiInputBase-root": {
                            borderRadius: "12px !important",
                            height: "50px",
                            "& .MuiInputBase-input": {
                                borderRadius: "12px",
                                height: "50px",
                                padding: "16px !important",
                                "&::placeholder": {
                                    color: "var(--color-text-primary)",
                                    opacity: 1,
                                    fontSize: "16px"
                                }
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                display: "none"
                            }
                        }
                    }} onChange={e => {
                        setEmail(e.target.value)
                    }} value={Email} />
                </Box>
            </Box>
            <Box display={"flex"} gap={"8px"}>
                <Box display={"flex"} flexDirection={"column"} gap={"8px"} flex={"calc(100% / 3)"}>
                    <CommonTypo size={16} fontWeight={600} color="var(--color-text-primary)">Date of birth</CommonTypo>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={days.map((it) => ({
                            // ...it,
                            label: it,
                        }))}
                        value={day}
                        onChange={(e: any) =>
                            setDay(e.target.textContent)
                        }
                        sx={{
                            fontSize: "16px"
                        }}

                        className={classes.countryCodeBox}
                        PaperComponent={({ children }) => (
                            <Paper
                                style={{ background: "#FFF" }}
                                sx={{
                                    ".MuiAutocomplete-option": {
                                        color: "rgb(0, 0, 0)",
                                        fontSize: "14px",
                                    },
                                }}
                            >
                                {children}
                            </Paper>
                        )}
                        disableClearable
                        placeholder="Select Country"
                        popupIcon={<KeyboardArrowDownIcon />}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                sx={{
                                    alignSelf: "stretch",
                                    borderRadius: "12px",
                                    height: "50px",
                                    marginBottom: "0px !important",
                                    "& .MuiInputBase-root": {
                                        borderRadius: "12px !important",
                                        height: "48px",
                                        "& .MuiInputBase-input": {
                                            borderRadius: "12px",
                                            padding: "8px 8px !important",
                                            "&::placeholder": {
                                                color: "var(--color-text-primary)",
                                                opacity: 1,
                                                fontSize: "16px"
                                            }
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            display: "none"
                                        }
                                    }
                                }}

                            />
                        )}
                    />
                </Box>
                <Box display={"flex"} flexDirection={"column"} justifyContent={"flex-end"} gap={"8px"} flex={"calc(100% / 3)"}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={months.map((it) => ({
                            // ...it,
                            label: it,
                        }))}
                        value={month}
                        onChange={(e: any) =>
                            setMonth(e.target.textContent)
                        }
                        sx={{
                            fontSize: "16px"
                        }}
                        popupIcon={<KeyboardArrowDownIcon />}
                        className={classes.countryCodeBox}
                        PaperComponent={({ children }) => (
                            <Paper
                                style={{ background: "#FFF" }}
                                sx={{
                                    ".MuiAutocomplete-option": {
                                        color: "rgb(0, 0, 0)",
                                        fontSize: "14px",
                                    },
                                }}
                            >
                                {children}
                            </Paper>
                        )}
                        disableClearable
                        placeholder="Select Country"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                sx={{
                                    alignSelf: "stretch",
                                    borderRadius: "12px",
                                    height: "50px",
                                    marginBottom: "0px !important",
                                    "& .MuiInputBase-root": {
                                        borderRadius: "12px !important",
                                        height: "48px",
                                        "& .MuiInputBase-input": {
                                            borderRadius: "12px",
                                            padding: "8px 8px !important",
                                            "&::placeholder": {
                                                color: "var(--color-text-primary)",
                                                opacity: 1,
                                                fontSize: "16px"
                                            }
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            display: "none"
                                        }
                                    }
                                }}
                            />
                        )}
                    />
                </Box>
                <Box display={"flex"} flexDirection={"column"} justifyContent={"flex-end"} gap={"8px"} flex={"calc(100% / 3)"}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={years.map((it) => ({
                            // ...it,
                            label: it,
                        }))}
                        value={year}
                        onChange={(e: any) =>
                            setYear(e.target.textContent)
                        }
                        sx={{
                            fontSize: "16px"
                        }}
                        popupIcon={<KeyboardArrowDownIcon />}
                        className={classes.countryCodeBox}
                        PaperComponent={({ children }) => (
                            <Paper
                                style={{ background: "#FFF" }}
                                sx={{
                                    ".MuiAutocomplete-option": {
                                        color: "rgb(0, 0, 0)",
                                        fontSize: "14px",
                                    },
                                }}
                            >
                                {children}
                            </Paper>
                        )}
                        disableClearable
                        placeholder="Select Country"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                sx={{
                                    alignSelf: "stretch",
                                    borderRadius: "12px",
                                    height: "50px",
                                    marginBottom: "0px !important",
                                    "& .MuiInputBase-root": {
                                        borderRadius: "12px !important",
                                        height: "48px",
                                        "& .MuiInputBase-input": {
                                            borderRadius: "12px",
                                            padding: "8px 8px !important",
                                            "&::placeholder": {
                                                color: "var(--color-text-primary)",
                                                opacity: 1,
                                                fontSize: "16px"
                                            }
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            display: "none"
                                        }
                                    }
                                }}
                            />
                        )}
                    />
                </Box>
            </Box>
            <Box display={"flex"} gap={"8px"}>
                <Box display={"flex"} flexDirection={"column"} gap={"8px"} flex={"calc(100%)"}>
                    <CommonTypo size={16} fontWeight={600} color="var(--color-text-primary)">Conutry</CommonTypo>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={COUNTRIES.sort(function (a, b) {
                            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                                return -1;
                            }
                            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                                return 1;
                            }
                            return 0;
                        }).map((it) => ({
                            // ...it,
                            label: it.name,
                        }))}
                        value={Country}
                        onChange={(e: any) =>
                            setCountry(e.target.textContent)
                        }
                        sx={{
                            fontSize: "16px"
                        }}
                        className={classes.countryCodeBox}
                        PaperComponent={({ children }) => (
                            <Paper
                                style={{ background: "#FFF" }}
                                sx={{
                                    ".MuiAutocomplete-option": {
                                        color: "rgb(0, 0, 0)",
                                        fontSize: "14px",
                                    },
                                }}
                            >
                                {children}
                            </Paper>
                        )}
                        popupIcon={<KeyboardArrowDownIcon />}
                        disableClearable
                        placeholder="Select Country"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                sx={{
                                    alignSelf: "stretch",
                                    borderRadius: "12px",
                                    height: "50px",
                                    marginBottom: "0px !important",
                                    "& .MuiInputBase-root": {
                                        borderRadius: "12px !important",
                                        height: "48px",
                                        "& .MuiInputBase-input": {
                                            borderRadius: "12px",
                                            padding: "8px 8px !important",
                                            "&::placeholder": {
                                                color: "var(--color-text-primary)",
                                                opacity: 1,
                                                fontSize: "16px"
                                            }
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            display: "none"
                                        }
                                    }
                                }}
                            />
                        )}
                    />
                </Box>
            </Box>
            <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
                <NewButton
                    buttonText={"Save"}
                    variant="contained"
                    onClick={() => {
                        // giveFeedback({
                        //     ...feedbackState,
                        // })
                        setUserInfo();
                        Uploaded && setUserImage();
                        userTypeDispatch(
                            addOutcomePopup({
                                first_name: FirstName,
                                last_name: LastName,
                            })
                        );
                        Uploaded &&
                            userTypeDispatch(
                                addOutcomePopup({
                                    image: ImageFile,
                                    isUploaded: true,
                                    first_name: FirstName,
                                    last_name: LastName,
                                })
                            );
                    }}
                    boxSx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "40px",
                        padding: "16px"
                    }}
                    typographySx={{
                        fontSize: "16px !important",
                        color: "var(--color-text-primary)",
                        fontWeight: `600 !important`
                    }}
                    isDisabled={FirstName.length === 0 || LastName.length === 0}
                // isLoadong={loading}
                // isDisabled={
                //     Boolean(feedbackErrorState.user_email) ||
                //     Boolean(feedbackErrorState.description) ||
                //     Boolean(feedbackErrorState.theme) ||
                //     !Boolean(feedbackState.user_email) ||
                //     !Boolean(feedbackState.description) ||
                //     !Boolean(feedbackState.theme)
                // }
                />
            </Box>
        </Box>
    </Box>
}

export default ProfileSetting