import {
  DialogContent,
  Button,
  Dialog,
  DialogActions,
  Typography,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@material-ui/core";
import typography, { pxToRem } from "../../theme/typography";
import NewButton from "../../components/NewButton";

interface IInfluenceclosePopup {
  open: boolean;
  setopenInfluenceclosePopup: any;
  setTransformToInfluenceTracking?: any;
  setAddOutcomeTracking?: any;
  setShowOutcome?: any;
  outcomeValueInput?: any;
  SelectRow?: any;
  handleCloseStartModal?: any;
  isDelete?: boolean;
}

const useStyles = makeStyles((theme) => ({
  categoryDialog: {
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: "20px",
        padding: "48px 40px",
        width: "682px",

        "& .MuiDialogContent-root": {
          padding: "0",
          overflowY: "unset",
        },

        "&. MuiDialogActions-root": {},
      },
    },
  },
  categoryDialogAction: {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "end !important",
  },
}));
const InfluenceclosePopup = (props: IInfluenceclosePopup) => {
  const classes = useStyles();

  return (
    <Dialog
      open={props.open}
      onClose={() => props?.setopenInfluenceclosePopup(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.categoryDialog}
    >
      <DialogContent id="alert-dialog-description">
        <Typography
          variant="h3"
          component="h3"
          lineHeight="120% !important"
          sx={{
            fontSize: `${pxToRem(26)} !important`,
            lineHeight: "120% !important",
            fontWeight: `700 !important`,
            fontFamily: `${typography.fontFamily} !important`,
          }}
        >
          Are you sure you want to delete your account ? You will loose the data
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{ mt: "60px" }}
        className={classes.categoryDialogAction}
      >
        <NewButton
          type="submit"
          variant="contained"
          onClick={() => props?.setopenInfluenceclosePopup(false)}
          buttonText="No, take me back"
        />
        <NewButton
          variant="outlined"
          onClick={props?.handleCloseStartModal}
          buttonText="Yes"
        />
      </DialogActions>
    </Dialog>
  );
};

export default InfluenceclosePopup;
